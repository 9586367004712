import React from "react";
import { MdAddCircle } from "react-icons/md";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
	addComision,
	selectComision
} from "../../../../actions/dashboard/sections/settings/comisionesActions";

import { comisionesColumns } from "../../../../settings/tablesSettings";
import { CustomTable } from "../../ui/CustomTable";

export const ComisionesTable = () => {
	const { edicion } = useSelector((state) => state.auth);

	const dispatch = useDispatch();

	const { comisiones, comisionSelected } = useSelector((state) => state.comisiones);

	const selectRow = (row) => {
		dispatch(selectComision(row));
	};

	const addNewComision = () => {
		dispatch(addComision());
	};

	return (
		<div className="collectionTable">
			<CustomTable
				title="Comisiones"
				columns={comisionesColumns}
				data={comisiones}
				selectRowId={comisionSelected ? comisionSelected.id : ""}
				onRowClickedAction={selectRow}
			/>

			{edicion && (
				<div className="collectionTable__addButton" onClick={addNewComision}>
					<MdAddCircle />
				</div>
			)}
		</div>
	);
};
