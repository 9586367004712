import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import validator from "validator";
import { startRegisterWithEmailPasswordName } from "../../../../actions/auth/authActions";

import { uiRemoveError, uiSetError } from "../../../../actions/uiActions";
import { useForm } from "../../../../hooks/useForm";

export const RegisterForm = () => {
	const { msgError, loading } = useSelector((state) => state.ui);

	const dispatch = useDispatch();

	const [values, handleInputChange] = useForm({
		nombre: "",
		email: "",
		password: "",
		password2: ""
	});
	const { nombre, email, password, password2 } = values;

	const handleRegister = (e) => {
		e.preventDefault();

		if (isFormValid()) {
			dispatch(startRegisterWithEmailPasswordName(email, password, nombre));
		}
	};

	const isFormValid = () => {
		if (nombre.trim().length === 0) {
			dispatch(uiSetError("Ingrese nombre de usuario."));
			return false;
		} else if (!validator.isEmail(email)) {
			dispatch(uiSetError("El correo no es válido."));
			return false;
		} else if (password.length < 6) {
			dispatch(uiSetError("La contraseña debe contener por lo menos 6 caracteres."));
			return false;
		} else if (password !== password2) {
			dispatch(uiSetError("Ambas contraseñas deben ser iguales."));
			return false;
		}

		dispatch(uiRemoveError());
		return true;
	};

	return (
		<div className="authForm">
			<h2 className="authForm__heading text-center">Registro</h2>

			<form className="form">
				<div className="form__inputGroup">
					<label htmlFor="nombre" className="form__label">
						Nombre:
					</label>
					<input
						type="text"
						className="form__input"
						placeholder="Tu nombre"
						autoComplete="off"
						name="nombre"
						value={nombre}
						onChange={handleInputChange}
					/>
				</div>

				<div className="form__inputGroup">
					<label htmlFor="email" className="form__label">
						Email:
					</label>
					<input
						id="email"
						type="email"
						className="form__input"
						placeholder="Tu email"
						autoComplete="off"
						name="email"
						value={email}
						onChange={handleInputChange}
					/>
				</div>

				<div className="form__inputGroup">
					<label htmlFor="password" className="form__label">
						Contraseña:
					</label>
					<input
						id="password"
						type="password"
						className="form__input"
						placeholder="Tu contraseña"
						autoComplete="off"
						name="password"
						value={password}
						onChange={handleInputChange}
					/>
				</div>

				<div className="form__inputGroup">
					<label htmlFor="password2" className="form__label">
						Confirmar Contraseña:
					</label>
					<input
						id="password2"
						type="password"
						className="form__input"
						placeholder="Tu contraseña de nuevo"
						autoComplete="off"
						name="password2"
						value={password2}
						onChange={handleInputChange}
					/>
				</div>

				{msgError && <div className="form__error">{msgError}</div>}

				<button className="form__button" value="Buscar" onClick={handleRegister}>
					Crear cuenta {loading && <div className="form__spinner"></div>}
				</button>
			</form>

			<p className="authForm__footer text-center">
				Ya tienes cuenta?{" "}
				<Link to="/auth/login" className="authForm__link">
					Iniciar sesión
				</Link>
			</p>
		</div>
	);
};
