export const getAccountStatementWaterData = (state, entitie) => {
	// Asignación
	const asignacion = state.asignacion.data.find((modulo) => modulo.id === entitie);

	//Distribución
	const distribucion = state.distribucion.data.find((modulo) => modulo.id === entitie);

	//Ofertas
	const ofertas = state.ofertas.ofertas.filter((oferta) => oferta.entidad === entitie);

	//Compras
	const compras = state.compras.compras.filter((compra) => compra.entidad === entitie);

	//Ingresos
	const ingresos = state.ingresos.ingresos.filter((ingreso) => ingreso.entidad === entitie);

	//Ingresos
	const pagos = state.pagos.pagos.filter((pago) => pago.entidad === entitie);

	//Ingresos
	const cargos = state.cargos.cargos.filter((cargo) => cargo.entidad === entitie);

	//Traspasos
	const traspasosEmitidos = state.traspasos.traspasos.filter(
		(traspaso) => traspaso.entidadEmisora === entitie
	);
	const traspasosRecibidos = state.traspasos.traspasos.filter(
		(traspaso) => traspaso.entidadReceptora === entitie
	);

	//Transferencias
	const transferSalientes = state.transferencias.volumenTransferenciasSalientes.find(
		(transfer) => transfer.id === entitie
	);
	const transferEntrantes = state.transferencias.volumenTransferenciasEntrantes.find(
		(transfer) => transfer.id === entitie
	);

	return {
		asignacion,
		distribucion,
		ofertas,
		compras,
		ingresos,
		pagos,
		cargos,
		traspasosEmitidos,
		traspasosRecibidos,
		transferEntrantes,
		transferSalientes,
	};
};
