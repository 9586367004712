import { types } from "../../../../types/types";

const initialState = {
	entidades: [],
	entidadSelected: null,
	isOpenContactForm: false
};

export const entidadesReducer = (state = { ...initialState }, action) => {
	switch (action.type) {
		case types.setEntidades:
			return { ...state, entidades: action.payload };

		case types.selectEntidad:
			return { ...state, entidadSelected: action.payload };

		case types.addEntidad:
			return {
				...state,
				entidadSelected: {
					logo: "./img/user.jpg",
					id: "",
					nombre: "",
					saldoInicial: 0,
					isModulo: true,
					supFisica: 0,
					supDerecho: 0
				}
			};

		case types.closeEntidadForm:
			return { ...state, entidadSelected: null };

		case types.saveEntidad:
			return {
				...state,
				entidades: [
					action.payload,
					...state.entidades.filter((entidad) => entidad.id !== action.payload.id)
				],
				entidadSelected: action.payload
			};

		case types.deleteEntidad:
			return {
				...state,
				entidades: state.entidades.filter((entidad) => entidad.id !== action.payload),
				entidadSelected: null
			};

		case types.changeContactFormState:
			return { ...state, isOpenContactForm: !state.isOpenContactForm };

		default:
			return state;
	}
};
