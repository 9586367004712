import { types } from "../../types/types";
import {
	firebaseLogout,
	firebaseRegister,
	firebaseUpdateUser
} from "../../firebase/firebaseConfig";
import { uiFinishLoading, uiRemoveError, uiSetError, uiStartLoading } from "../uiActions";
import { saveFilesOnFirebase } from "../../firebase/crudFirestoreActions/saveFilesOnFirebase";
import { saveDocOnFirestore } from "../../firebase/crudFirestoreActions/saveDocOnFirestore";
import { firebaseLoginWithEmailPassword } from "../../firebase/crudFirestoreActions/firebaseLoginWithEmailPassword";
import { getDocFromFirestore } from "../../firebase/crudFirestoreActions/getDocFromFirestore";
import { deleteFilesOnFirebase } from "../../firebase/crudFirestoreActions/deleteFilesOnFirebase";
import { updateFirebasePassword } from "../../firebase/crudFirestoreActions/updateFirebasePassword";

export const startRegisterWithEmailPasswordName = (email, password, name) => {
	return async (dispatch) => {
		dispatch(uiStartLoading());
		firebaseRegister(email, password)
			.then(async (userCredential) => {
				await firebaseUpdateUser({ displayName: name });
				const { uid, displayName, photoURL } = userCredential.user;
				const img = photoURL ? photoURL : "./img/user.jpg";

				const isSave = await saveDocOnFirestore(
					"users",
					{ displayName, email, consulta: false, edicion: false },
					uid
				);

				const user = {
					uid,
					displayName,
					userImage: img,
					consulta: false,
					edicion: false
				};

				if (isSave) {
					dispatch(login(user));
					dispatch(uiFinishLoading());
					dispatch(uiRemoveError());
				}
			})
			.catch((error) => {
				console.log("Error code: ", error.code);
				console.log("Error message: ", error.message);
				if (error.code === "auth/email-already-in-use")
					dispatch(uiSetError("El email ya está registrado."));
				dispatch(uiFinishLoading());
			});
	};
};

export const startLoginWithEmailPassword = (email, password) => {
	return async (dispatch) => {
		dispatch(uiStartLoading());
		const response = await firebaseLoginWithEmailPassword(email, password);

		if (response.user) {
			const { uid, displayName, photoURL } = response.user;
			// const userProfile = await getDocFromFirestore("users", uid);
			const img = photoURL ? photoURL : "./img/user.jpg";
			dispatch(login({ uid, displayName, img }));
			dispatch(uiFinishLoading());
			dispatch(uiRemoveError());
		} else {
			if (response.code === "auth/invalid-email") dispatch(uiSetError("El email no es válido."));
			if (response.code === "auth/user-not-found") dispatch(uiSetError("El usuario no existe."));
			if (response.code === "auth/wrong-password")
				dispatch(uiSetError("La contraseña es incorrecta."));
			dispatch(uiFinishLoading());
		}
	};
};

export const login = (user) => ({
	type: types.login,
	payload: user
});

export const startLogout = () => {
	return (dispatch) => {
		firebaseLogout()
			.then(() => {
				dispatch(logout());
			})
			.catch((error) => {
				console.log("Error code: ", error.code);
				console.log("Error message: ", error.message);
			});
	};
};

export const logout = () => ({
	type: types.logout
});

export const startSetUserImage = (uid, files) => {
	return async (dispatch) => {
		const userProfile = await getDocFromFirestore("users", uid);

		if (userProfile.userImageName) {
			await deleteFilesOnFirebase("usersImages", [{ name: userProfile.userImageName }]);
		}

		const urls = await saveFilesOnFirebase(uid, files, [], "usersImages");
		const isSave = await saveDocOnFirestore(
			"users",
			{ ...userProfile, userImageName: urls[0].name, userImageURL: urls[0].url },
			uid
		);

		if (urls && isSave) {
			await firebaseUpdateUser({ photoURL: urls[0].url });
			dispatch(setUserImage(urls[0].url));
		}
	};
};

export const setUserImage = (url) => ({
	type: types.setUserImage,
	payload: url
});

export const handleSetUserName = (userName) => {
	return async (dispatch) => {
		await firebaseUpdateUser({ displayName: userName });
		dispatch(setUserName(userName));
	};
};

export const setUserName = (userName) => ({
	type: types.setUserName,
	payload: userName
});

export const startChangePassword = (email, password, newPassword) => {
	return async (dispatch) => {
		dispatch(uiStartLoading());
		const credentials = await firebaseLoginWithEmailPassword(email, password);

		if (credentials.user) {
			await updateFirebasePassword(newPassword);

			dispatch(uiFinishLoading());
			dispatch(uiRemoveError());
		} else {
			if (credentials.code === "auth/invalid-email") dispatch(uiSetError("El email no es válido."));
			if (credentials.code === "auth/user-not-found") dispatch(uiSetError("El usuario no existe."));
			if (credentials.code === "auth/wrong-password")
				dispatch(uiSetError("La contraseña es incorrecta."));
			dispatch(uiFinishLoading());
		}
	};
};
