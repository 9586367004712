import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";

export const getDocFromFirestore = async (collectionName, id) => {
	try {
		const docRef = doc(db, collectionName, id);
		const docSnap = await getDoc(docRef);

		if (docSnap.exists()) {
			return docSnap.data();
		} else return false;
	} catch (error) {
		console.log("Error code: ", error.code);
		console.log("Error message: ", error.message);
	}
};
