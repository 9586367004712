import { getMoneyData } from "./getMoneyData";
import { getWaterData } from "./getWaterData";

export const getAccountStatementItems = (data, corte = "SEPTIEMBRE", cuotas, initialBalance) => {
	const waterData = getWaterData(data, corte);
	const moneyData = getMoneyData(
		data.compras,
		data.ingresos,
		data.pagos,
		data.cargos,
		corte,
		cuotas,
		initialBalance
	);

	return [
		{ type: "subtitle", subtitle: "VOLUMEN EN MM3" },

		{
			type: "table",
			data: waterData,
			patterns: [
				{
					id: "concepto",
					header: "CONCEPTO",
					type: "text",
					width: "auto",
					textAlign: "start",
					volumen: false,
				},
				{
					id: "OCTUBRE-DICIEMBRE",
					header: "OCTUBRE-DICIEMBRE",
					type: "number",
					width: "auto",
					textAlign: "right",
					volumen: true,
				},
				{
					id: "ENERO-JULIO",
					header: "ENERO-JULIO",
					type: "number",
					width: "auto",
					textAlign: "right",
					volumen: true,
				},
				{
					id: "AGOSTO",
					header: "AGOSTO",
					type: "number",
					width: "auto",
					textAlign: "right",
					volumen: true,
				},
				{
					id: "SEPTIEMBRE",
					header: "SEPTIEMBRE",
					type: "number",
					width: "auto",
					textAlign: "right",
					volumen: true,
				},
				{
					id: "ENERO-SEPTIEMBRE",
					header: "ENERO-SEPTIEMBRE",
					type: "number",
					width: "auto",
					textAlign: "right",
					volumen: true,
				},
				{
					id: "total",
					header: "TOTAL",
					type: "number",
					width: "auto",
					textAlign: "right",
					volumen: true,
				},
			],
		},

		{ type: "subtitle", subtitle: "ESTADO FINANCIERO" },
		{
			type: "table",
			data: moneyData,
			patterns: [
				{
					id: "concepto",
					header: "CONCEPTO",
					type: "text",
					width: "auto",
					textAlign: "start",
					volumen: false,
				},

				{
					id: "fecha",
					header: "FECHA",
					type: "text",
					width: "auto",
					textAlign: "right",
					volumen: false,
				},
				{
					id: "volumen",
					header: "VOLUMEN (MM3)",
					type: "number",
					width: "auto",
					textAlign: "right",
					volumen: true,
				},
				{
					id: "cuota",
					header: "CUOTA (MN)",
					type: "number",
					width: "auto",
					textAlign: "right",
					volumen: false,
				},
				{
					id: "importe",
					header: "IMPORTE (MN)",
					type: "number",
					width: "auto",
					textAlign: "right",
					volumen: false,
				},
			],
		},
	];
};
