import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdClose, MdSave, MdLogout, MdDelete, MdPhotoCamera, MdUpload } from "react-icons/md";

import { useFormToUpper } from "../../../../hooks/UseFormToUpper";
import { objectsDeepEqual } from "../../../../helpers/objectsDeepEqual";
import { uiRemoveError, uiSetError } from "../../../../actions/uiActions";
import {
	closeTraspasoForm,
	startDeleteTraspaso,
	startDeleteSingleTraspasoFile,
	startSaveTraspaso,
} from "../../../../actions/dashboard/sections/transacciones/traspasosActions";

export const TraspasoForm = ({ traspaso }) => {
	const { edicion } = useSelector((state) => state.auth);

	const { msgError, loading } = useSelector((state) => state.ui);
	const { entidades } = useSelector((state) => state.entidades);
	const { currentCycle } = useSelector((state) => state.dashboard);
	const { isMobile } = useSelector((state) => state.ui);
	const { cuotas } = useSelector((state) => state.cuotas);

	const dispatch = useDispatch();

	const [values, handleInputChange] = useFormToUpper(traspaso);
	const { fecha, entidadEmisora, entidadReceptora, volumen, cuota, filesToUpload } = values;
	const thereAreChanges = !objectsDeepEqual(traspaso, values);
	// console.log({ thereAreChanges, traspaso, values });
	// const gmt = "T00:00:00";

	const handleSaveTraspaso = (e) => {
		e.preventDefault();
		if (isFormValid()) {
			dispatch(startSaveTraspaso(values, currentCycle));
		}
	};

	const handleDeleteTraspaso = (e) => {
		e.preventDefault();
		dispatch(startDeleteTraspaso(traspaso, currentCycle));
	};

	const closeForm = (e) => {
		e.preventDefault();

		dispatch(closeTraspasoForm());
		dispatch(uiRemoveError());
	};

	const handleDeleteSingleFile = (traspaso, file) => {
		dispatch(startDeleteSingleTraspasoFile(currentCycle, traspaso, file));
	};

	const isFormValid = () => {
		if (fecha === "") {
			dispatch(uiSetError("Indique la fecha."));
			return false;
		}

		if (entidadEmisora.trim().length === 0) {
			dispatch(uiSetError("Seleccione la entidad emisora."));
			return false;
		}

		if (volumen === "") {
			dispatch(uiSetError("Indique el volumen a traspasar."));
			return false;
		}

		if (volumen === 0) {
			dispatch(uiSetError("Indique el volumen a traspasar."));
			return false;
		}

		if (volumen < 0) {
			dispatch(uiSetError("El volumen no puede ser negativo."));
			return false;
		}

		if (entidadReceptora.trim().length > 0 && cuota === "") {
			dispatch(uiSetError("Indique la cuota."));
			return false;
		}

		dispatch(uiRemoveError());
		return true;
	};

	return (
		<form className="detailForm">
			{traspaso.id.length === 0 ? (
				<div className="detailForm__title">Nuevo traspaso</div>
			) : (
				<div className="detailForm__title">Traspaso: {traspaso.id}</div>
			)}

			<div className="form">
				<div className="form__inputGroup">
					<label htmlFor="fecha" className="form__label">
						Fecha:
					</label>
					<input
						id="fecha"
						type="date"
						className="form__input"
						name="fecha"
						value={fecha}
						onChange={handleInputChange}
					/>
				</div>

				<div className="form__inputGroup">
					<label htmlFor="entidadEmisora" className="form__label">
						Entidad Emisora:
					</label>
					<select
						id="entidadEmisora"
						name="entidadEmisora"
						type="text"
						value={entidadEmisora}
						onChange={handleInputChange}
						className="form__input form__input--select"
					>
						<option value="" disabled>
							Entidad
						</option>

						{entidades.map((entidad) => (
							<option key={`em-${entidad.id}`} value={entidad.id}>
								{entidad.id}
							</option>
						))}
					</select>
				</div>

				<div className="form__inputGroup">
					<label htmlFor="entidadReceptora" className="form__label">
						Entidad Receptora:
					</label>
					<select
						id="entidadReceptora"
						name="entidadReceptora"
						type="text"
						value={entidadReceptora}
						onChange={handleInputChange}
						className="form__input form__input--select"
					>
						<option value="" disabled>
							Entidad
						</option>

						{entidades.map((entidad) => (
							<option key={`re-${entidad.id}`} value={entidad.id}>
								{entidad.id}
							</option>
						))}
					</select>
				</div>

				<div className="form__inputGroup">
					<label htmlFor="volumen" className="form__label">
						Volumen:
					</label>
					<input
						id="volumen"
						type="number"
						className="form__input"
						placeholder="Volumen a traspasar"
						autoComplete="off"
						name="volumen"
						value={volumen}
						onChange={handleInputChange}
					/>
				</div>

				<div className="form__inputGroup">
					<label htmlFor="cuota" className="form__label">
						Cuota:
					</label>
					<select
						id="cuota"
						name="cuota"
						type="text"
						value={cuota}
						onChange={handleInputChange}
						className="form__input form__input--select"
					>
						<option value="" disabled>
							Cuota
						</option>

						{cuotas.map((cuota) => (
							<option key={cuota.id} value={cuota.id}>
								{cuota.descripcion}
							</option>
						))}
					</select>
				</div>

				{edicion && (
					<div className="form__inputGroup">
						<label htmlFor="filesToUpload">
							<div className="form__filesInput">
								<MdUpload className="form__buttonIcon" /> Documentos
							</div>
						</label>
						<input
							id="filesToUpload"
							type="file"
							multiple="multiple"
							className="filesInput"
							name="filesToUpload"
							value={filesToUpload.files}
							onChange={handleInputChange}
						/>

						{isMobile && (
							<div>
								<label htmlFor="cameraInput">
									<div className="form__cameraInput">
										<MdPhotoCamera className="form__buttonIcon" />
									</div>
								</label>

								<input
									id="cameraInput"
									type="file"
									multiple="multiple"
									capture="camera"
									className="cameraInput"
									name="filesToUpload"
									value={filesToUpload.files}
									onChange={handleInputChange}
								/>
							</div>
						)}
					</div>
				)}

				{traspaso.filesInFirebase.map((file) => (
					<div className="fileLink" key={file.name}>
						<a
							href={file.url}
							rel="noopener noreferrer"
							target="_blank"
							className="fileLink__fileName"
						>
							{file.name}
						</a>

						{edicion && (
							<MdDelete
								className="fileLink__icon"
								onClick={() => handleDeleteSingleFile(traspaso, file)}
							/>
						)}
					</div>
				))}

				{msgError && <div className="form__error">{msgError}</div>}

				<div className="detailForm__buttonsGrid">
					{(edicion && traspaso.id.length) > 0 && (
						<button
							className="form__button detailForm__deleteButton"
							onClick={handleDeleteTraspaso}
						>
							<MdClose className="detailForm__icon" />
							Borrar
						</button>
					)}

					<button className="form__button detailForm__closeButton" onClick={closeForm}>
						<MdLogout className="detailForm__icon detailForm__icon--mirror" />
						Cerrar
					</button>

					{edicion && (thereAreChanges || loading) && (
						<button className="form__button detailForm__saveButton" onClick={handleSaveTraspaso}>
							<MdSave className="detailForm__icon" />
							Guardar {loading && <div className="form__spinner"></div>}
						</button>
					)}
				</div>
			</div>
		</form>
	);
};
