import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdClose, MdSave, MdLogout } from "react-icons/md";

import { useFormToUpper } from "../../../../hooks/UseFormToUpper";
import { objectsDeepEqual } from "../../../../helpers/objectsDeepEqual";
import { uiRemoveError, uiSetError } from "../../../../actions/uiActions";
import {
	closeCuotaForm,
	startDeleteCuota,
	startSaveCuota
} from "../../../../actions/dashboard/sections/settings/cuotasActions";

export const CuotaForm = ({ cuota }) => {
	const { edicion } = useSelector((state) => state.auth);

	const { msgError, loading } = useSelector((state) => state.ui);
	const { cuotas } = useSelector((state) => state.cuotas);

	const dispatch = useDispatch();

	const [values, handleInputChange] = useFormToUpper(cuota);
	const { id, descripcion, inicio, fin, costo } = values;
	const thereAreChanges = !objectsDeepEqual(cuota, values);
	const gmt = "T00:00:00";

	const handleSaveCuota = (e) => {
		e.preventDefault();
		if (isFormValid()) {
			dispatch(startSaveCuota(values));
		}
	};

	const handleDeleteCuota = (e) => {
		e.preventDefault();
		dispatch(startDeleteCuota(cuota.id));
	};

	const closeForm = (e) => {
		e.preventDefault();

		dispatch(closeCuotaForm());
		dispatch(uiRemoveError());
	};

	const isFormValid = () => {
		if (id.trim().length === 0) {
			dispatch(uiSetError("Ingrese id único."));
			return false;
		} else if (cuota.id.length === 0 && cuotas.find((cuota) => cuota.id === id)) {
			dispatch(uiSetError("Ya existe una cuota con el mismo id"));
			return false;
		} else if (descripcion.trim().length === 0) {
			dispatch(uiSetError("Ingrese descripción de la cuota."));
			return false;
		} else if (inicio === "") {
			dispatch(uiSetError("Indique el inicio de la vigencia."));
			return false;
		} else if (fin === "") {
			dispatch(uiSetError("Indique el final de la vigencia."));
			return false;
		} else if (new Date(inicio + gmt) > new Date(fin + gmt)) {
			dispatch(uiSetError("La fecha final no puede ser anterior a la fecha inicial."));
			return false;
		} else if (costo < 0) {
			dispatch(uiSetError("El costo no puede ser negativo."));
			return false;
		}

		dispatch(uiRemoveError());
		return true;
	};

	return (
		<div className="detailForm">
			{cuota.id.length === 0 ? (
				<div className="detailForm__title">Nueva cuota</div>
			) : (
				<div className="detailForm__title">Cuota: {cuota.id}</div>
			)}

			<form className="form">
				{cuota.id.length === 0 && (
					<div className="form__inputGroup">
						<label htmlFor="id" className="form__label">
							Id:
						</label>
						<input
							id="id"
							type="text"
							className="form__input"
							placeholder="Identificador único"
							name="id"
							value={id}
							onChange={handleInputChange}
						/>
					</div>
				)}

				<div className="form__inputGroup">
					<label htmlFor="descripcion" className="form__label">
						Descripción:
					</label>
					<input
						id="descripcion"
						type="text"
						className="form__input"
						placeholder="Nombre de la cuota"
						autoComplete="off"
						name="descripcion"
						value={descripcion}
						onChange={handleInputChange}
					/>
				</div>

				<div className="form__inputGroup">
					<label htmlFor="inicio" className="form__label">
						Inicio:
					</label>
					<input
						id="inicio"
						type="date"
						className="form__input"
						name="inicio"
						value={inicio}
						onChange={handleInputChange}
					/>
				</div>

				<div className="form__inputGroup">
					<label htmlFor="fin" className="form__label">
						Fin:
					</label>

					<input
						id="fin"
						type="date"
						className="form__input"
						name="fin"
						value={fin}
						onChange={handleInputChange}
					/>
				</div>

				<div className="form__inputGroup">
					<label htmlFor="costo" className="form__label">
						Costo:
					</label>
					<input
						id="costo"
						type="number"
						className="form__input"
						placeholder="Superficie con derecho"
						autoComplete="off"
						name="costo"
						value={costo}
						onChange={handleInputChange}
					/>
				</div>

				{msgError && <div className="form__error">{msgError}</div>}

				<div className="detailForm__buttonsGrid">
					{(edicion && cuota.id.length) > 0 && (
						<button className="form__button detailForm__deleteButton" onClick={handleDeleteCuota}>
							<MdClose className="detailForm__icon" />
							Borrar
						</button>
					)}

					<button className="form__button detailForm__closeButton" onClick={closeForm}>
						<MdLogout className="detailForm__icon detailForm__icon--mirror" />
						Cerrar
					</button>

					{edicion && thereAreChanges && (
						<button className="form__button detailForm__saveButton" onClick={handleSaveCuota}>
							<MdSave className="detailForm__icon" />
							Guardar {loading && <div className="form__spinner"></div>}
						</button>
					)}
				</div>
			</form>
		</div>
	);
};
