import React from "react";
import { useSelector } from "react-redux";
import { ReportHeader } from "./ReportHeader";
import { ReportTable } from "./ReportTable";

export const ReportPage = React.forwardRef(({ title = "", items }, ref) => {
	const { sidebarExpanded } = useSelector((state) => state.dashboard);

	return (
		<div
			className={`reportPage ${
				sidebarExpanded ? "reportPage--sidebarExpanded" : "reportPage--sidebarCollapsed"
			}`}
			ref={ref}
		>
			<ReportHeader />

			<div className="reportPage__title">{title}</div>

			{items.map((item, i) => getItem(item, i))}
		</div>
	);
});

const getItem = (item, i) => {
	switch (item.type) {
		case "table":
			return <ReportTable key={`${item.type}-${i}`} patterns={item.patterns} data={item.data} />;

		case "chart":
			const Chart = item.chart;
			return (
				<div
					key={`${item.type}-${i}`}
					className="reportChart"
					id="reportCanvas"
					style={{ maxWidth: item.maxWidth }}
				>
					<Chart />
				</div>
			);

		case "subtitle":
			return <h4 key={`${item.type}-${i}`}>{item.subtitle}</h4>;

		default:
			return <></>;
	}
};
