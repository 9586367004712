import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { SubSectionsMenu } from "../../ui/SubSectionsMenu";
import { setSettingsActiveSubSection } from "../../../../actions/dashboard/sections/settings/settingsActions";

import { TableAndDetailTemplate } from "../templates/TableAndDetailTemplate";
import { EntidadesTable } from "./EntidadesTable";
import { EntidadForm } from "./EntidadForm";
import { startLoadEntidades } from "../../../../actions/dashboard/sections/settings/entidadesActions";
import { CuotasTable } from "./CuotasTable";
import { startLoadCuotas } from "../../../../actions/dashboard/sections/settings/cuotasActions";
import { CuotaForm } from "./CuotaForm";
import { ComisionesTable } from "./ComisionesTable";
import { ComisionForm } from "./ComisionForm";
import { startLoadComisiones } from "../../../../actions/dashboard/sections/settings/comisionesActions";
import { ContactosTable } from "./ContactosTable";
import { ContactoForm } from "./ContactoForm";
import { ProfileTemplate } from "../templates/ProfileTemplate";

export const SettingsSection = () => {
	const { subSections, activeSubSection } = useSelector((state) => state.settings);
	const { entidadSelected } = useSelector((state) => state.entidades);
	const { contactoSelected } = useSelector((state) => state.contactos);
	const { cuotaSelected } = useSelector((state) => state.cuotas);
	const { comisionSelected } = useSelector((state) => state.comisiones);
	const { isMobile } = useSelector((state) => state.ui);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(startLoadEntidades());
		dispatch(startLoadCuotas());
		dispatch(startLoadComisiones());
	}, [dispatch]);

	const setSubSection = (section) => {
		dispatch(setSettingsActiveSubSection(section));
	};

	const getTemplate = () => {
		switch (activeSubSection) {
			case "Perfil":
				return <ProfileTemplate />;

			case "Entidades":
				return (
					<TableAndDetailTemplate
						Table={() => (isMobile && entidadSelected ? <></> : <EntidadesTable />)}
						Detail={() => <EntidadForm entidad={entidadSelected} />}
						isOpenDetail={entidadSelected}
					/>
				);

			case "Contactos":
				return (
					<TableAndDetailTemplate
						Table={() => (isMobile && contactoSelected ? <></> : <ContactosTable />)}
						Detail={() => <ContactoForm contacto={contactoSelected} />}
						isOpenDetail={contactoSelected}
					/>
				);

			case "Cuotas":
				return (
					<TableAndDetailTemplate
						Table={() => (isMobile && cuotaSelected ? <></> : <CuotasTable />)}
						Detail={() => <CuotaForm cuota={cuotaSelected} />}
						isOpenDetail={cuotaSelected}
					/>
				);

			case "Comisiones":
				return (
					<TableAndDetailTemplate
						Table={() => (isMobile && comisionSelected ? <></> : <ComisionesTable />)}
						Detail={() => <ComisionForm comision={comisionSelected} />}
						isOpenDetail={comisionSelected}
					/>
				);

			default:
				return <></>;
		}
	};

	return (
		<div className="section">
			<div className="section__header">
				<h3 className="section__title">Configuración</h3>

				<SubSectionsMenu
					subSections={subSections}
					activeSubSection={activeSubSection}
					action={setSubSection}
				/>
			</div>

			<div className="section__content">{getTemplate()}</div>
		</div>
	);
};
