const meces = {};
for (let i = 1; i <= 12; i++) meces[i] = 0;

export const getWaterData = (data, corte) => {
	const {
		asignacion,
		distribucion,
		ofertas,
		compras,
		traspasosEmitidos,
		traspasosRecibidos,
		transferSalientes,
		transferEntrantes,
	} = data;

	const tableData = [];

	//asignacion
	const asignacionColumns = {
		"OCTUBRE-DICIEMBRE": asignacion.f,
		"ENERO-JULIO": asignacion.k,
		AGOSTO: asignacion.l,
		SEPTIEMBRE: asignacion.n,
	};
	tableData.push(getRow("ASIGNACIÓN", asignacionColumns, corte));

	//distribucion
	tableData.push(getRow("DISTRIBUCIÓN", getColumns(distribucion, -1), corte));

	//ofertas
	const ofertasMesuales = { ...meces };
	ofertas.forEach((oferta) => {
		const month = getMonth(oferta.fecha);

		ofertasMesuales[month] =
			ofertasMesuales[month] +
			(oferta.usoAgricola === "" ? 0 : oferta.usoAgricola) +
			(oferta.organismoOperador === "" ? 0 : oferta.organismoOperador) +
			(oferta.organismoOperadorMA === "" ? 0 : oferta.organismoOperadorMA);
	});
	tableData.push(getRow("OFERTAS", getColumns(ofertasMesuales, -1), corte));

	//compras
	const comprasMesuales = { ...meces };
	compras.forEach((compra) => {
		const month = getMonth(compra.fecha);

		comprasMesuales[month] =
			comprasMesuales[month] +
			(compra.usoAgricola === "" ? 0 : compra.usoAgricola) +
			(compra.organismoOperador === "" ? 0 : compra.organismoOperador) +
			(compra.organismoOperadorMA === "" ? 0 : compra.organismoOperadorMA);
	});
	tableData.push(getRow("COMPRAS", getColumns(comprasMesuales), corte));

	//traspasosEmitidos
	const traspasosEmitidosMensuales = { ...meces };
	traspasosEmitidos.forEach((traspaso) => {
		const month = getMonth(traspaso.fecha);
		traspasosEmitidosMensuales[month] =
			traspasosEmitidosMensuales[month] + Number(traspaso.volumen);
	});
	tableData.push(getRow("TRASPASOS EMITIDOS", getColumns(traspasosEmitidosMensuales, -1), corte));

	//traspasosRecibidos
	const traspasosRecibidosMensuales = { ...meces };
	traspasosRecibidos.forEach((traspaso) => {
		const month = getMonth(traspaso.fecha);
		traspasosRecibidosMensuales[month] =
			traspasosRecibidosMensuales[month] + Number(traspaso.volumen);
	});
	tableData.push(getRow("TRASPASOS RECIBIDOS", getColumns(traspasosRecibidosMensuales), corte));

	//transferSalientes
	tableData.push(getRow("TRANSFERENCIAS EMITIDAS", getColumns(transferSalientes, -1), corte));

	//transferEntrantes
	tableData.push(getRow("TRANSFERENCIAS RECIBIDAS", getColumns(transferEntrantes), corte));

	return tableData;
};

const getRow = (concepto, columns, corte) => {
	const row = {
		concepto,
		"OCTUBRE-DICIEMBRE": 0,
		"ENERO-JULIO": 0,
		AGOSTO: 0,
		SEPTIEMBRE: 0,
		"ENERO-SEPTIEMBRE": 0,
		total: 0,
	};

	const cortes = ["OCTUBRE-DICIEMBRE", "ENERO-JULIO", "AGOSTO", "SEPTIEMBRE"];
	const index = cortes.indexOf(corte);

	for (let i = 0; i <= index; i++) {
		row[cortes[i]] = columns[cortes[i]];
		if (cortes[i] !== "ENERO-SEPTIEMBRE") row.total = row.total + columns[cortes[i]];
		if (cortes[i] !== "OCTUBRE-DICIEMBRE")
			row["ENERO-SEPTIEMBRE"] = row["ENERO-SEPTIEMBRE"] + columns[cortes[i]];
	}

	return row;
};

const getColumns = (monthlyItems, multiplier = 1) => {
	return {
		"OCTUBRE-DICIEMBRE":
			(monthlyItems["10"] + monthlyItems["11"] + monthlyItems["12"]) * multiplier,
		"ENERO-JULIO":
			(monthlyItems["1"] +
				monthlyItems["2"] +
				monthlyItems["3"] +
				monthlyItems["4"] +
				monthlyItems["5"] +
				monthlyItems["6"] +
				monthlyItems["7"]) *
			multiplier,
		AGOSTO: monthlyItems["8"] * multiplier,
		SEPTIEMBRE: monthlyItems["9"] * multiplier,
	};
};

const getMonth = (date) => {
	const dateArray = date.split("-");
	if (dateArray[1].startsWith("0")) return dateArray[1].substring(1);
	else return dateArray[1];
};
