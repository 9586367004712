export const modulos = [
	"1",
	"2",
	"3",
	"4",
	"5",
	"6",
	"7",
	"8",
	"9A",
	"9B",
	"10",
	"11",
	"12",
	"14",
	"15",
	"16",
	"17",
	"18",
	"19",
	"20",
	"21",
	"22",
	"23"
];

export const months = [10, 11, 12, 1, 2, 3, 4, 5, 6, 7, 8, 9];

export const factores = {
	1: -11.3134,
	2: 11.2444212,
	3: 11.3315727,
	4: -11.3134,
	5: -11.3134,
	6: 11.8282,
	7: 10.611,
	8: 11.3579716,
	"9A": 11.3121558,
	"9B": 11.3431125,
	10: 11.1367236,
	11: 11.4049429,
	12: 11.4099955,
	14: 11.4289836,
	15: 11.4273017,
	16: 11.4101252,
	17: 11.3718213,
	18: 11.2054784,
	19: 11.4240137,
	20: 11.2821053,
	21: 11.3221791,
	22: 11.4282266,
	23: 11.4285841
};
