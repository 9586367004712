import { types } from "../../../../types/types";

const initialState = {
	transferenciasSalientes: [],
	transferenciasEntrantes: [],
	balances: [],
	volumenTransferenciasSalientes: [],
	volumenTransferenciasEntrantes: [],
	volumenBalances: [],
	tipoTabla: "Salientes",
	unidad: "Mm3"
};

export const transferenciasReducer = (state = { ...initialState }, action) => {
	switch (action.type) {
		case types.setTransferenciasSalientes:
			return { ...state, transferenciasSalientes: action.payload };

		case types.setTransferenciasEntrantes:
			return { ...state, transferenciasEntrantes: action.payload };

		case types.setBalancesTransferencias:
			return { ...state, balances: action.payload };

		case types.setVolumenTransferenciasSalientes:
			return { ...state, volumenTransferenciasSalientes: action.payload };

		case types.setVolumenTransferenciasEntrantes:
			return { ...state, volumenTransferenciasEntrantes: action.payload };

		case types.setVolumenBalancesTransferencias:
			return { ...state, volumenBalances: action.payload };

		case types.setTipoTabla:
			return { ...state, tipoTabla: action.payload };

		case types.setUnidades:
			return { ...state, unidad: action.payload };

		default:
			return state;
	}
};
