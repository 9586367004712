import { types } from "../../types/types";

export const authReducer = (state = {}, action) => {
	switch (action.type) {
		case types.login:
			return {
				...action.payload
			};

		case types.logout:
			return {};

		case types.setUserImage:
			return {
				...state,
				userImage: action.payload
			};

		case types.setUserName:
			return {
				...state,
				displayName: action.payload
			};

		default:
			return state;
	}
};
