import { doc, deleteDoc } from "firebase/firestore";
import Swal from "sweetalert2";

import { db } from "../firebaseConfig";

export const deleteDocOnFirestore = async (collection, docId) => {
	try {
		const isDeleted = await Swal.fire({
			title: "Atención!!",
			text: `Está a punto de eliminar el registro con id ${docId} de la tabla ${collection}, ¿Realmente desea eliminar este registro?`,
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3182ce",
			cancelButtonColor: "#cf4e46",
			confirmButtonText: "Si",
			cancelButtonText: "No",
			customClass: { popup: "swal__popup" }
		}).then(async ({ isConfirmed }) => {
			if (isConfirmed) {
				await deleteDoc(doc(db, collection, docId));

				Swal.fire({
					title: "Registro eliminado!",
					text: "El registro fue eliminado de la base de datos.",
					icon: "success",
					confirmButtonText: "Ok",
					confirmButtonColor: "#3182ce",
					customClass: { popup: "swal__popup" }
				});

				return true;
			} else return false;
		});

		if (isDeleted) return true;
		else return false;
	} catch (error) {
		console.log("Error code: ", error.code);
		console.log("Error message: ", error.message);
		Swal.fire({
			title: "Error!",
			text: "Error al intentar borrar los datos.",
			icon: "error",
			confirmButtonText: "Ok",
			confirmButtonColor: "#3182ce"
		});

		return false;
	}
};
