// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
	getAuth,
	createUserWithEmailAndPassword,
	signInWithEmailAndPassword,
	updateProfile,
	onAuthStateChanged,
	signOut
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const sicperFirebaseConfig = {
	projectId: process.env.REACT_APP_SICPER_PROJECTID,
	appId: process.env.REACT_APP_SICPER_APPID,
	apiKey: process.env.REACT_APP_SICPER_APIKEY,
	databaseURL: process.env.REACT_APP_SICPER_DATABASEURL,
	authDomain: process.env.REACT_APP_SICPER_AUTHDOMAIN,
	storageBucket: process.env.REACT_APP_SICPER_STORAGEBUCKET,
	messagingSenderId: process.env.REACT_APP_SICPER_MESSAGINGSENDERID
};

// Initialize Firebase
const sicperApp = initializeApp(sicperFirebaseConfig, "sicper");
const sicperAuth = getAuth();
const sicperDb = getFirestore(sicperApp);
const sicperStorage = getStorage(sicperApp);

const sicperFirebaseRegister = (email, password) => {
	return createUserWithEmailAndPassword(sicperAuth, email, password);
};

const sicperFirebaseUpdateUser = (userUpdates) => {
	return updateProfile(sicperAuth.currentUser, userUpdates);
};

const sicperFirebaseLogin = (email, password) => {
	return signInWithEmailAndPassword(sicperAuth, email, password);
};

const sicperFirebaseLogout = () => {
	return signOut(sicperAuth);
};

const sicperFirebaseCheckIsLoggedIn = (actions) => {
	return onAuthStateChanged(sicperAuth, actions);
};

export {
	sicperApp,
	sicperDb,
	sicperStorage,
	sicperFirebaseRegister,
	sicperFirebaseUpdateUser,
	sicperFirebaseLogin,
	sicperFirebaseCheckIsLoggedIn,
	sicperFirebaseLogout
};
