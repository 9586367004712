import React from "react";
import { MdAddCircle } from "react-icons/md";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
	addIngreso,
	selectIngreso,
} from "../../../../actions/dashboard/sections/transacciones/ingresosActions";
import { getFormattedNumberString, roundToN } from "../../../../helpers/mathHelpers";

import { ingresosColumns } from "../../../../settings/tablesSettings";
import { CustomTable } from "../../ui/CustomTable";

export const IngresosTable = () => {
	const { edicion } = useSelector((state) => state.auth);
	const { ingresos, ingresoSelected, totalIngresos } = useSelector((state) => state.ingresos);

	const dispatch = useDispatch();

	const selectRow = (row) => {
		dispatch(selectIngreso(row));
	};

	const addNewIngreso = () => {
		dispatch(addIngreso());
	};

	return (
		<div className="collectionTable">
			<CustomTable
				title={`Ingresos  (${getFormattedNumberString(roundToN(totalIngresos, 2))} Mn)`}
				columns={ingresosColumns}
				data={ingresos}
				selectRowId={ingresoSelected ? ingresoSelected.id : ""}
				onRowClickedAction={selectRow}
			/>

			{edicion && (
				<div className="collectionTable__addButton" onClick={addNewIngreso}>
					<MdAddCircle />
				</div>
			)}
		</div>
	);
};
