import React from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { EditableTable } from "../../ui/EditableTable";
import { useEditableTable } from "../../../../hooks/useEditableTable";
import {
	setAsignacionActiveCell,
	startSaveAsignacionData
} from "../../../../actions/dashboard/sections/operacion/asignacionActions";

export const AsignacionTable = () => {
	//b => Octubre a diciembre gravedad uso agricola
	//c => Octubre a diciembre gravedad uso domestico
	//d => Octubre a diciembre suma uso agrícola y uso doméstico
	//e => Octubre a diciembre pozo federal
	//f => Octubre a diciembre total
	//g => Enero a julio gravedad uso agricola
	//h => Enero a julio gravedad uso domestico
	//i => Enero a julio suma uso agrícola y uso doméstico
	//j => Enero a septiembre pozo federal
	//k => Enero a septiembre total
	//l => Nueva asignacion agosto
	//m => Nueva asignacion uso domestico
	//n => Nueva asignacion septiembre
	//o => Nueva asignacion uso domestico
	//p => Nueva asignacion total enero septiembre uso agricola + uso domestico
	//q => Total octubre a septiembre gravedad uso agricola
	//r => Total octubre a septiembre gravedad uso domestico
	//s => Total octubre a septiembre suma uso agricola y domestico
	//t => Total octubre a septiembre pozo federal
	//total => Total

	const paterns = [
		{
			header: "MODULO",
			name: "id",
			editable: false,
			type: "text"
		},
		{
			header: "GRAVEDAD USO AGRÍCOLA",
			name: "b",
			editable: true,
			type: "number"
		},
		{
			header: "GRAVEDAD USO DOMÉSTICO",
			name: "c",
			editable: true,
			type: "number"
		},
		{
			header: "SUMA USO AGRÍCOLA Y USO DOMÉSTICO",
			name: "d",
			editable: false,
			type: "number"
		},
		{
			header: "POZO FEDERAL",
			name: "e",
			editable: true,
			type: "number"
		},
		{
			header: "TOTAL",
			name: "f",
			editable: false,
			type: "number"
		},
		{
			header: "GRAVEDAD USO AGRÍCOLA",
			name: "g",
			editable: true,
			type: "number"
		},
		{
			header: "GRAVEDAD USO DOMÉSTICO",
			name: "h",
			editable: true,
			type: "number"
		},
		{
			header: "SUMA USO AGRÍCOLA Y USO DOMÉSTICO",
			name: "i",
			editable: false,
			type: "number"
		},
		{
			header: "POZO FEDERAL",
			name: "j",
			editable: true,
			type: "number"
		},
		{
			header: "TOTAL",
			name: "k",
			editable: false,
			type: "number"
		},
		{
			header: "AGOSTO",
			name: "l",
			editable: true,
			type: "number"
		},
		{
			header: "USO DOMESTICO",
			name: "m",
			editable: true,
			type: "number"
		},
		{
			header: "SEPTIEMBRE",
			name: "n",
			editable: true,
			type: "number"
		},
		{
			header: "USO DOMESTICO",
			name: "o",
			editable: true,
			type: "number"
		},
		{
			header: "TOTAL ENERO SEPTIEMBRE",
			name: "p",
			editable: false,
			type: "number"
		},
		{
			header: "GRAVEDAD USO AGRÍCOLA",
			name: "q",
			editable: false,
			type: "number"
		},
		{
			header: "USO DOMESTICO",
			name: "r",
			editable: false,
			type: "number"
		},
		{
			header: "SUMA USO AGRÍCOLA Y DOMESTICO",
			name: "s",
			editable: false,
			type: "number"
		},
		{
			header: "POZO FEDERAL",
			name: "t",
			editable: false,
			type: "number"
		},
		{
			header: "TOTAL",
			name: "total",
			editable: false,
			type: "number"
		}
	];

	//b => Octubre a diciembre gravedad uso agricola
	//c => Octubre a diciembre gravedad uso domestico
	//d => Octubre a diciembre suma uso agrícola y uso doméstico
	//e => Octubre a diciembre pozo federal
	//f => Octubre a diciembre total
	//g => Enero a julio gravedad uso agricola
	//h => Enero a julio gravedad uso domestico
	//i => Enero a julio suma uso agrícola y uso doméstico
	//j => Enero a julio pozo federal
	//k => Enero a julio total
	//l => Nueva asignacion agosto
	//m => Nueva asignacion uso domestico agosto
	//n => Nueva asignacion septiembre
	//o => Nueva asignacion uso domestico septiembre
	//p => Nueva asignacion total enero septiembre uso agricola + uso domestico
	//q => Total octubre a septiembre gravedad uso agricola
	//r => Total octubre a septiembre gravedad uso domestico
	//s => Total octubre a septiembre suma uso agricola y domestico
	//t => Total octubre a septiembre pozo federal
	//total => Total

	const operations = [
		["d", "b,+,c"],
		["f", "b,+,c,+,e"],
		["i", "g,+,h"],
		["k", "g,+,h,+,j"],
		["p", "k,+,l,+,m,+,n,+,o"],
		["q", "b,+,g,+,l,+,n"],
		["r", "c,+,h"],
		["s", "b,+,g,+,l,+,n,+,c,+,h"],
		["t", "e,+,j"],
		["total", "b,+,c,+,e,+,g,+,h,+,j,+,l,+,m,+,n,+,o"]
	];

	const groupers = () => {
		return (
			<tr>
				<th className="editableTable__grouper"></th>
				<th colSpan="5" className="editableTable__grouper">
					OCTUBRE A DICIEMBRE
				</th>
				<th colSpan="3" className="editableTable__grouper">
					ENERO A JULIO
				</th>
				<th colSpan="2" className="editableTable__grouper">
					ENERO A SEPTIEMBRE
				</th>
				<th colSpan="5" className="editableTable__grouper">
					NUEVA ASIGNACIÓN
				</th>
				<th colSpan="4" className="editableTable__grouper">
					TOTAL DISPONIBILIDAD OCTUBRE A SEPTIEMBRE
				</th>
				<th className="editableTable__grouper"></th>
			</tr>
		);
	};

	const { data, asignacionActiveCell } = useSelector((state) => state.asignacion);
	const [dataTable, handleInputChange, setTable] = useEditableTable(data, operations);

	useEffect(() => {
		setTable(data);
	}, [setTable, data]);

	const dispatch = useDispatch();

	const handleSaveChanges = () => {
		dispatch(startSaveAsignacionData(dataTable));
	};

	return data.length > 0 ? (
		<EditableTable
			title="Asignación"
			initialData={data}
			dataState={dataTable}
			setActiveCell={setAsignacionActiveCell}
			activeCell={asignacionActiveCell}
			handler={handleInputChange}
			paterns={paterns}
			groupers={groupers}
			handleSaveChanges={handleSaveChanges}
		/>
	) : (
		<></>
	);
};
