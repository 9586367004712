import { modulos } from "../../../../helpers/constants";
import { types } from "../../../../types/types";

const initialState = {
	subSections: ["Operación", "Transacciones", "Estados de cuenta", "Dispersión"],
	activeSubSection: "Operación",
	operacionReports: ["Asignación", "Distribución"],
	activeOperacionReport: "Asignación",
	transaccionesReports: ["Ofertas", "Compras", "Traspasos", "Transferencias"],
	activeTransaccionesReport: "Ofertas",
	entities: modulos,
	activeEntitie: "",
	cuts: ["OCTUBRE-DICIEMBRE", "ENERO-JULIO", "AGOSTO", "SEPTIEMBRE"],
	activeCut: "SEPTIEMBRE",
};

export const reportesReducer = (state = { ...initialState }, action) => {
	switch (action.type) {
		case types.setReportesActiveSubSection:
			return { ...state, activeSubSection: action.payload };

		case types.setActiveOperacioReport:
			return { ...state, activeOperacionReport: action.payload };

		case types.setActiveTransaccionesReport:
			return { ...state, activeTransaccionesReport: action.payload };

		case types.setActiveEntitie:
			return { ...state, activeEntitie: action.payload };

		case types.setActiveCut:
			return { ...state, activeCut: action.payload };

		default:
			return state;
	}
};
