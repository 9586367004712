import React from "react";
import { MdAddCircle } from "react-icons/md";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
	addCuota,
	selectCuota
} from "../../../../actions/dashboard/sections/settings/cuotasActions";

import { cuotasColumns } from "../../../../settings/tablesSettings";
import { CustomTable } from "../../ui/CustomTable";

export const CuotasTable = () => {
	const { edicion } = useSelector((state) => state.auth);
	const { cuotas, cuotaSelected } = useSelector((state) => state.cuotas);

	const dispatch = useDispatch();

	const selectRow = (row) => {
		dispatch(selectCuota(row));
	};

	const addNewCuota = () => {
		dispatch(addCuota());
	};

	return (
		<div className="collectionTable">
			<CustomTable
				title="Cuotas"
				columns={cuotasColumns}
				data={cuotas}
				selectRowId={cuotaSelected ? cuotaSelected.id : ""}
				onRowClickedAction={selectRow}
			/>

			{edicion && (
				<div className="collectionTable__addButton" onClick={addNewCuota}>
					<MdAddCircle />
				</div>
			)}
		</div>
	);
};
