import { types } from "../../../../types/types";

const initialState = {
	subSections: ["Perfil", "Entidades", "Contactos", "Cuotas", "Comisiones"],
	activeSubSection: "Perfil"
};

export const settingsReducer = (state = { ...initialState }, action) => {
	switch (action.type) {
		case types.setSettingsActiveSubSection:
			return { ...state, activeSubSection: action.payload };

		default:
			return state;
	}
};
