import { types } from "../../../../types/types";

export const setReportesActiveSubSection = (subSection) => ({
	type: types.setReportesActiveSubSection,
	payload: subSection
});

export const setActiveOperacioReport = (activeReport) => ({
	type: types.setActiveOperacioReport,
	payload: activeReport
});

export const setActiveTransaccionesReport = (activeReport) => ({
	type: types.setActiveTransaccionesReport,
	payload: activeReport
});

export const setActiveEntitie = (entitie) => ({
	type: types.setActiveEntitie,
	payload: entitie
});

export const setActiveCut = (cut) => ({
	type: types.setActiveCut,
	payload: cut
});
