import { deleteDocOnFirestore } from "../../../../firebase/crudFirestoreActions/deleteDocOnFirestore";
import { deleteFilesOnFirebase } from "../../../../firebase/crudFirestoreActions/deleteFilesOnFirebase";
import { deleteSingleFileOnFirebase } from "../../../../firebase/crudFirestoreActions/deleteSingleFileOnFirebase";
import { loadCollectionFromFirestore } from "../../../../firebase/crudFirestoreActions/loadCollectionFromFirestor";
import { saveDocOnFirestore } from "../../../../firebase/crudFirestoreActions/saveDocOnFirestore";
import { saveFilesOnFirebase } from "../../../../firebase/crudFirestoreActions/saveFilesOnFirebase";
import { updateDocInFirestore } from "../../../../firebase/crudFirestoreActions/updateDocInFirestore";
import { roundToN } from "../../../../helpers/mathHelpers";
import { types } from "../../../../types/types";
import { uiFinishLoading, uiStartLoading } from "../../../uiActions";

export const startLoadIngresos = (cycle) => {
	return async (dispatch) => {
		const ingresos = await loadCollectionFromFirestore(`ciclos/${cycle}/ingresos`);

		let totalIngresos = 0;
		ingresos.forEach((ingreso) => {
			totalIngresos += ingreso.importe;
			ingreso.importe = roundToN(ingreso.importe, 2);
		});

		dispatch(setTotalIngresos(totalIngresos));
		dispatch(setIngresos(ingresos));
	};
};

export const setIngresos = (ingresos) => ({
	type: types.setIngresos,
	payload: ingresos,
});

export const setTotalIngresos = (total) => ({
	type: types.setTotalIngresos,
	payload: total,
});

export const selectIngreso = (ingreso) => ({
	type: types.selectIngreso,
	payload: ingreso,
});

export const addIngreso = () => ({
	type: types.addIngreso,
});

export const closeIngresoForm = () => ({
	type: types.closeIngresoForm,
});

export const startSaveIngreso = (ingreso, cycle) => {
	return async (dispatch, getState) => {
		const state = getState();

		dispatch(uiStartLoading());

		const today = new Date();
		if (!ingreso.id) ingreso.id = today.getTime().toString().substring(5);

		const filesRefs = await saveFilesOnFirebase(
			ingreso.id,
			ingreso.filesToUpload,
			state.ingresos.ingresoSelected.filesInFirebase,
			`${cycle}/ingresos`
		);

		ingreso.filesInFirebase = [...ingreso.filesInFirebase, ...filesRefs];
		ingreso.filesToUpload = [];

		const isDocSave = await saveDocOnFirestore(
			`ciclos/${cycle}/ingresos`,
			{ ...ingreso },
			ingreso.id
		);

		if (filesRefs && isDocSave) {
			dispatch(setTotalIngresos(state.ingresos.totalIngresos + ingreso.importe));
			dispatch(saveIngreso({ ...ingreso }));
			dispatch(uiFinishLoading());
		}
	};
};

export const saveIngreso = (ingreso) => ({
	type: types.saveIngreso,
	payload: ingreso,
});

export const startDeleteIngreso = (ingreso, cycle) => {
	return async (dispatch, getState) => {
		const state = getState();
		const isDeleted = await deleteDocOnFirestore(`ciclos/${cycle}/ingresos`, ingreso.id);
		const areFilesDeleted = await deleteFilesOnFirebase(
			`${cycle}/ingresos`,
			ingreso.filesInFirebase
		);
		if (isDeleted && areFilesDeleted) {
			dispatch(deleteIngreso(ingreso.id));
			dispatch(
				setTotalIngresos(
					state.ingresos.totalIngresos - Number(ingreso.volumenTotal.replace(",", () => ""))
				)
			);
		}
	};
};

export const deleteIngreso = (ingresoId) => ({
	type: types.deleteIngreso,
	payload: ingresoId,
});

export const startDeleteSingleIngresoFile = (cycle, ingreso, file) => {
	return async (dispatch) => {
		const isDeleted = await deleteSingleFileOnFirebase(`${cycle}/ingresos`, file);

		if (isDeleted) {
			const files = ingreso.filesInFirebase.filter((filterFile) => filterFile.name !== file.name);
			const isUpdates = await updateDocInFirestore(`ciclos/${cycle}/ingresos`, ingreso.id, {
				filesInFirebase: files,
			});
			if (isUpdates) dispatch(deleteSingleIngresoFile(ingreso.id, file.name));
		}
	};
};

export const deleteSingleIngresoFile = (ingresoId, fileName) => ({
	type: types.deleteSingleIngresoFile,
	payload: { ingresoId, fileName },
});
