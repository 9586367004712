import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { startSetAsignacionData } from "../../../../actions/dashboard/sections/operacion/asignacionActions";
import { startSetDistribucionData } from "../../../../actions/dashboard/sections/operacion/distribucionActions";
import { setOperacionActiveSubSection } from "../../../../actions/dashboard/sections/operacion/operacionActions";
import { startLoadEntidades } from "../../../../actions/dashboard/sections/settings/entidadesActions";
import { SubSectionsMenu } from "../../ui/SubSectionsMenu";
import { AsignacionTable } from "./AsignacionTable";
import { DistribucionTable } from "./DistribucionTable";

export const OperacionSection = () => {
	const { subSections, activeSubSection } = useSelector((state) => state.operacion);
	const { currentCycle } = useSelector((state) => state.dashboard);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(startLoadEntidades());
		dispatch(startSetAsignacionData(currentCycle));
		dispatch(startSetDistribucionData(currentCycle));
	}, [dispatch, currentCycle]);

	const setSubSection = (section) => {
		dispatch(setOperacionActiveSubSection(section));
	};

	const getTemplate = () => {
		switch (activeSubSection) {
			case "Asignación":
				return <AsignacionTable />;

			case "Distribución":
				return <DistribucionTable />;

			default:
				return <></>;
		}
	};

	return (
		<div className="section">
			<div className="section__header">
				<h3 className="section__title">Operación</h3>

				<SubSectionsMenu
					subSections={subSections}
					activeSubSection={activeSubSection}
					action={setSubSection}
				/>
			</div>

			<div className="section__content">{getTemplate()}</div>
		</div>
	);
};
