import { deleteDocOnFirestore } from "../../../../firebase/crudFirestoreActions/deleteDocOnFirestore";
import { deleteFilesOnFirebase } from "../../../../firebase/crudFirestoreActions/deleteFilesOnFirebase";
import { deleteSingleFileOnFirebase } from "../../../../firebase/crudFirestoreActions/deleteSingleFileOnFirebase";
import { deleteTraspasoTransaction } from "../../../../firebase/crudFirestoreActions/deleteTraspasoTransaction";
import { loadCollectionFromFirestore } from "../../../../firebase/crudFirestoreActions/loadCollectionFromFirestor";
import { saveDocOnFirestore } from "../../../../firebase/crudFirestoreActions/saveDocOnFirestore";
import { saveFilesOnFirebase } from "../../../../firebase/crudFirestoreActions/saveFilesOnFirebase";
import { saveTraspasoTransaction } from "../../../../firebase/crudFirestoreActions/saveTraspasoTransaction";
import { updateDocInFirestore } from "../../../../firebase/crudFirestoreActions/updateDocInFirestore";
import { types } from "../../../../types/types";
import { uiFinishLoading, uiStartLoading } from "../../../uiActions";

export const startLoadTraspasos = (cycle) => {
	return async (dispatch) => {
		const traspasos = await loadCollectionFromFirestore(`ciclos/${cycle}/traspasos`);

		let totalTraspasos = 0;
		traspasos.forEach((traspaso) => {
			totalTraspasos += traspaso.volumen;
		});

		dispatch(setTotalTraspasos(totalTraspasos));
		dispatch(setTraspasos(traspasos));
	};
};

export const setTraspasos = (traspasos) => ({
	type: types.setTraspasos,
	payload: traspasos,
});

export const setTotalTraspasos = (total) => ({
	type: types.setTotalTraspasos,
	payload: total,
});

export const selectTraspaso = (traspaso) => ({
	type: types.selectTraspaso,
	payload: traspaso,
});

export const addTraspaso = () => ({
	type: types.addTraspaso,
});

export const closeTraspasoForm = () => ({
	type: types.closeTraspasoForm,
});

export const startSaveTraspaso = (traspaso, cycle) => {
	return async (dispatch, getState) => {
		const state = getState();

		dispatch(uiStartLoading());

		const today = new Date();
		if (!traspaso.id) traspaso.id = today.getTime().toString().substring(5);

		const filesRefs = await saveFilesOnFirebase(
			traspaso.id,
			traspaso.filesToUpload,
			state.traspasos.traspasoSelected.filesInFirebase,
			`${cycle}/traspasos`
		);

		traspaso.filesInFirebase = [...traspaso.filesInFirebase, ...filesRefs];
		traspaso.filesToUpload = [];

		const isDocSave =
			traspaso.entidadReceptora.length === 0
				? await saveDocOnFirestore(`ciclos/${cycle}/traspasos`, { ...traspaso }, traspaso.id)
				: await saveTraspasoTransaction(cycle, { ...traspaso }, traspaso.id);

		if (filesRefs && isDocSave) {
			dispatch(setTotalTraspasos(state.traspasos.totalTraspasos + traspaso.volumen));
			dispatch(saveTraspaso({ ...traspaso }));
			dispatch(uiFinishLoading());
		}
	};
};

export const saveTraspaso = (traspaso) => ({
	type: types.saveTraspaso,
	payload: traspaso,
});

export const startDeleteTraspaso = (traspaso, cycle) => {
	return async (dispatch, getState) => {
		const state = getState();

		const isDeleted =
			traspaso.entidadReceptora.length === 0
				? await deleteDocOnFirestore(`ciclos/${cycle}/traspasos`, traspaso.id)
				: await deleteTraspasoTransaction(cycle, traspaso.id);

		const areFilesDeleted = await deleteFilesOnFirebase(
			`${cycle}/traspasos`,
			traspaso.filesInFirebase
		);
		if (isDeleted && areFilesDeleted) {
			dispatch(deleteTraspaso(traspaso.id));
			dispatch(setTotalTraspasos(state.traspasos.totalTraspasos - traspaso.volumen));
		}
	};
};

export const deleteTraspaso = (traspasoId) => ({
	type: types.deleteTraspaso,
	payload: traspasoId,
});

export const startDeleteSingleTraspasoFile = (cycle, traspaso, file) => {
	return async (dispatch) => {
		const isDeleted = await deleteSingleFileOnFirebase(`${cycle}/traspasos`, file);

		if (isDeleted) {
			const files = traspaso.filesInFirebase.filter((filterFile) => filterFile.name !== file.name);
			const isUpdates = await updateDocInFirestore(`ciclos/${cycle}/traspasos`, traspaso.id, {
				filesInFirebase: files,
			});
			if (isUpdates) dispatch(deleteSingleTraspasoFile(traspaso.id, file.name));
		}
	};
};

export const deleteSingleTraspasoFile = (traspasoId, fileName) => ({
	type: types.deleteSingleTraspasoFile,
	payload: { traspasoId, fileName },
});
