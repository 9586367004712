import { types } from "../../../../types/types";

const initialState = {
	ingresos: [],
	totalIngresos: 0,
	ingresoSelected: null,
};

export const ingresosReducer = (state = { ...initialState }, action) => {
	switch (action.type) {
		case types.setIngresos:
			return { ...state, ingresos: action.payload };

		case types.setTotalIngresos:
			return { ...state, totalIngresos: action.payload };

		case types.selectIngreso:
			return { ...state, ingresoSelected: action.payload };

		case types.addIngreso:
			return {
				...state,
				ingresoSelected: {
					id: "",
					fecha: "",
					entidad: "",
					importe: "",
					concepto: "",
					referencia: "",
					filesToUpload: {},
					filesInFirebase: [],
				},
			};

		case types.closeIngresoForm:
			return { ...state, ingresoSelected: null };

		case types.saveIngreso:
			return {
				...state,
				ingresos: [
					action.payload,
					...state.ingresos.filter((ingreso) => ingreso.id !== action.payload.id),
				],
				ingresoSelected: action.payload,
			};

		case types.deleteIngreso:
			return {
				...state,
				ingresos: state.ingresos.filter((ingreso) => ingreso.id !== action.payload),
				ingresoSelected: null,
			};

		case types.deleteSingleIngresoFile:
			return {
				...state,
				ingresos: state.ingresos.map((ingreso) => {
					if (ingreso.id === action.payload.ingresoId) {
						const files = ingreso.filesInFirebase.filter(
							(file) => file.name !== action.payload.fileName
						);
						ingreso.filesInFirebase = files;
						return ingreso;
					} else return ingreso;
				}),
				ingresoSelected: {
					...state.ingresoSelected,
					filesInFirebase: state.ingresoSelected.filesInFirebase.filter(
						(file) => file.name !== action.payload.fileName
					),
				},
			};

		default:
			return state;
	}
};
