import { ref, deleteObject } from "firebase/storage";
import { storage } from "../firebaseConfig";

export const deleteFilesOnFirebase = async (pad, fileList) => {
	try {
		const deletePromices = fileList.map((file) => {
			const storageRef = ref(storage, `${pad}/${file.name}`);
			const deleteQry = deleteObject(storageRef, file);
			return deleteQry;
		});

		await Promise.all(deletePromices);

		return true;
	} catch (error) {
		console.log("Error code: ", error.code);
		console.log("Error message: ", error.message);
		return false;
	}
};
