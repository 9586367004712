import React from "react";
import { MdSave } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { startChangePassword } from "../../../../actions/auth/authActions";
import { uiRemoveError, uiSetError } from "../../../../actions/uiActions";
import { useForm } from "../../../../hooks/useForm";

export const PasswordForm = () => {
	const { msgError } = useSelector((state) => state.ui);
	const dispatch = useDispatch();

	const [values, handleInputChange, reset] = useForm({
		email: "",
		password: "",
		newPassword: "",
		newPassword2: ""
	});
	const { email, password, newPassword, newPassword2 } = values;

	const handleChangePassword = (e) => {
		e.preventDefault();

		if (isFormValid()) {
			dispatch(startChangePassword(email, password, newPassword2));
			reset();
		}
	};

	const isFormValid = () => {
		if (password.trim().length === 0) {
			dispatch(uiSetError("Ingrese la contraseña actual."));
			return false;
		}

		if (newPassword.length < 6) {
			dispatch(uiSetError("La nueva contraseña debe contener por lo menos 6 caracteres."));
			return false;
		}

		if (newPassword !== newPassword2) {
			dispatch(uiSetError("Ambas contraseñas deben ser iguales."));
			return false;
		}

		dispatch(uiRemoveError());
		return true;
	};

	return (
		<form className="passwordForm">
			<div>
				<h2 className="authForm__heading text-center">Cambio de Contraseña</h2>
				<div className="form__inputGroup">
					<label htmlFor="email" className="form__label">
						Email:
					</label>
					<input
						id="email"
						type="email"
						className="form__input"
						placeholder="Tu email"
						name="email"
						value={email}
						onChange={handleInputChange}
					/>
				</div>

				<div className="form__inputGroup">
					<label htmlFor="password" className="form__label">
						Contraseña actual:
					</label>
					<input
						id="password"
						type="password"
						className="form__input"
						placeholder="Tu contraseña actual"
						autoComplete="off"
						name="password"
						value={password}
						onChange={handleInputChange}
					/>
				</div>

				<div className="form__inputGroup">
					<label htmlFor="newPassword" className="form__label">
						Nueva Contraseña:
					</label>
					<input
						id="newPassword"
						type="password"
						className="form__input"
						placeholder="Tu nueva contraseña"
						autoComplete="off"
						name="newPassword"
						value={newPassword}
						onChange={handleInputChange}
					/>
				</div>

				<div className="form__inputGroup">
					<label htmlFor="newPassword2" className="form__label">
						Confirmar Nueva Contraseña:
					</label>
					<input
						id="newPassword2"
						type="password"
						className="form__input"
						placeholder="Tu nueva contraseña de nuevo"
						autoComplete="off"
						name="newPassword2"
						value={newPassword2}
						onChange={handleInputChange}
					/>
				</div>

				{msgError && <div className="passwordForm__error">{msgError}</div>}

				<button className="passwordForm__button" value="Buscar" onClick={handleChangePassword}>
					<MdSave className="detailForm__icon" />
					Guardar Nueva Contraseña
				</button>
			</div>
		</form>
	);
};
