import React from "react";

export const TableAndDetailTemplate = ({ Table, Detail, isOpenDetail }) => {
	return (
		<div className="tableAndDetailTemplate">
			<Table />
			{isOpenDetail && <Detail />}
		</div>
	);
};
