import { types } from "../../../../types/types";

const initialState = {
	pagos: [],
	totalPagos: 0,
	pagoSelected: null,
};

export const pagosReducer = (state = { ...initialState }, action) => {
	switch (action.type) {
		case types.setPagos:
			return { ...state, pagos: action.payload };

		case types.setTotalPagos:
			return { ...state, totalPagos: action.payload };

		case types.selectPago:
			return { ...state, pagoSelected: action.payload };

		case types.addPago:
			return {
				...state,
				pagoSelected: {
					id: "",
					fecha: "",
					entidad: "",
					importe: "",
					concepto: "",
					comision: "",
					referencia: "",
					filesToUpload: {},
					filesInFirebase: [],
				},
			};

		case types.closePagoForm:
			return { ...state, pagoSelected: null };

		case types.savePago:
			return {
				...state,
				pagos: [action.payload, ...state.pagos.filter((pago) => pago.id !== action.payload.id)],
				pagoSelected: action.payload,
			};

		case types.deletePago:
			return {
				...state,
				pagos: state.pagos.filter((pago) => pago.id !== action.payload),
				pagoSelected: null,
			};

		case types.deleteSinglePagoFile:
			return {
				...state,
				pagos: state.pagos.map((pago) => {
					if (pago.id === action.payload.pagoId) {
						const files = pago.filesInFirebase.filter(
							(file) => file.name !== action.payload.fileName
						);
						pago.filesInFirebase = files;
						return pago;
					} else return pago;
				}),
				pagoSelected: {
					...state.pagoSelected,
					filesInFirebase: state.pagoSelected.filesInFirebase.filter(
						(file) => file.name !== action.payload.fileName
					),
				},
			};

		default:
			return state;
	}
};
