import { runTransaction, doc } from "firebase/firestore";
import { db } from "../firebaseConfig";
import Swal from "sweetalert2";

export const deletePagoTransaction = async (cycle, docId) => {
	try {
		const isDeleted = await Swal.fire({
			title: "Atención!!",
			text: `Está a punto de eliminar el registro con id ${docId} de la tabla "Pagos", esta acción eliminará automáticamente el registro asociado con el mismo id en la tabla "Cargos", ¿Realmente desea eliminar este registro?`,
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3182ce",
			cancelButtonColor: "#cf4e46",
			confirmButtonText: "Si",
			cancelButtonText: "No",
			customClass: { popup: "swal__popup" },
		}).then(async ({ isConfirmed }) => {
			if (isConfirmed) {
				const pagoRef = doc(db, `ciclos/${cycle}/pagos`, docId);
				const cargoRef = doc(db, `ciclos/${cycle}/cargos`, docId);

				await runTransaction(db, async (transaction) => {
					transaction.delete(pagoRef);
					transaction.delete(cargoRef);
				});

				Swal.fire({
					title: "Registro eliminado!",
					text: "Se guardó con éxito el nuevo registro en la base de datos.",
					icon: "success",
					confirmButtonText: "Ok",
					confirmButtonColor: "#3182ce",
					customClass: { popup: "swal__popup" },
				});

				return true;
			} else return false;
		});

		if (isDeleted) return true;
		else return false;
	} catch (error) {
		console.log("Error code: ", error.code);
		console.log("Error message: ", error.message);
		Swal.fire({
			title: "Error!",
			text: "Error al intentar borrar los datos.",
			icon: "error",
			confirmButtonText: "Ok",
			confirmButtonColor: "#3182ce",
		});

		return false;
	}
};
