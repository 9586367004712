import { types } from "../../../../types/types";

const initialState = {
	traspasos: [],
	totalTraspasos: 0,
	traspasoSelected: null,
};

export const traspasosReducer = (state = { ...initialState }, action) => {
	switch (action.type) {
		case types.setTraspasos:
			return { ...state, traspasos: action.payload };

		case types.setTotalTraspasos:
			return { ...state, totalTraspasos: action.payload };

		case types.selectTraspaso:
			return { ...state, traspasoSelected: action.payload };

		case types.addTraspaso:
			return {
				...state,
				traspasoSelected: {
					id: "",
					fecha: "",
					cuota: "",
					entidadEmisora: "",
					entidadReceptora: "",
					volumen: "",
					filesToUpload: {},
					filesInFirebase: [],
				},
			};

		case types.closeTraspasoForm:
			return { ...state, traspasoSelected: null };

		case types.saveTraspaso:
			return {
				...state,
				traspasos: [
					action.payload,
					...state.traspasos.filter((traspaso) => traspaso.id !== action.payload.id),
				],
				traspasoSelected: action.payload,
			};

		case types.deleteTraspaso:
			return {
				...state,
				traspasos: state.traspasos.filter((traspaso) => traspaso.id !== action.payload),
				traspasoSelected: null,
			};

		case types.deleteSingleTraspasoFile:
			return {
				...state,
				traspasos: state.traspasos.map((traspaso) => {
					if (traspaso.id === action.payload.traspasoId) {
						const files = traspaso.filesInFirebase.filter(
							(file) => file.name !== action.payload.fileName
						);
						traspaso.filesInFirebase = files;
						return traspaso;
					} else return traspaso;
				}),
				traspasoSelected: {
					...state.traspasoSelected,
					filesInFirebase: state.traspasoSelected.filesInFirebase.filter(
						(file) => file.name !== action.payload.fileName
					),
				},
			};

		default:
			return state;
	}
};
