import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { startSetAsignacionData } from "../../../../actions/dashboard/sections/operacion/asignacionActions";
import { startSetDistribucionData } from "../../../../actions/dashboard/sections/operacion/distribucionActions";
import { startLoadCuotas } from "../../../../actions/dashboard/sections/settings/cuotasActions";
import { startLoadEntidades } from "../../../../actions/dashboard/sections/settings/entidadesActions";
import { startLoadOfertas } from "../../../../actions/dashboard/sections/transacciones/ofertasActions";
import { setReportesActiveSubSection } from "../../../../actions/dashboard/sections/transacciones/reportesActions";
import { startLoadTransferencias } from "../../../../actions/dashboard/sections/transacciones/transferenciasActions";
import { startLoadTraspasos } from "../../../../actions/dashboard/sections/transacciones/traspasosActions";
import { startLoadCompras } from "../../../../actions/dashboard/sections/transacciones/comprasActions";
import { SubSectionsMenu } from "../../ui/SubSectionsMenu";
import { EstadosDeCuentaReports } from "./EstadosDeCuentaReports";
import { OperacionReport } from "./OperacionReport";
import { TransaccionesReports } from "./TransaccionesReports";
import { startLoadIngresos } from "../../../../actions/dashboard/sections/transacciones/ingresosActions";
import { startLoadPagos } from "../../../../actions/dashboard/sections/transacciones/pagosActions";
import { startLoadCargos } from "../../../../actions/dashboard/sections/transacciones/cargosActions";
import { DispersionReport } from "./DispersionReport";

export const ReportesSection = () => {
	const { subSections, activeSubSection } = useSelector((state) => state.reportes);
	const { currentCycle } = useSelector((state) => state.dashboard);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(startSetAsignacionData(currentCycle));
		dispatch(startSetDistribucionData(currentCycle));
		dispatch(startLoadOfertas(currentCycle));
		dispatch(startLoadCompras(currentCycle));
		dispatch(startLoadIngresos(currentCycle));
		dispatch(startLoadPagos(currentCycle));
		dispatch(startLoadCargos(currentCycle));
		dispatch(startLoadTraspasos(currentCycle));
		dispatch(startLoadTransferencias(currentCycle));
		dispatch(startLoadCuotas());
		dispatch(startLoadEntidades());
	}, [dispatch, currentCycle]);

	const setSubSection = (section) => {
		dispatch(setReportesActiveSubSection(section));
	};

	const template = {
		Operación: <OperacionReport />,
		Transacciones: <TransaccionesReports />,
		"Estados de cuenta": <EstadosDeCuentaReports />,
		Dispersión: <DispersionReport />,
	};

	return (
		<div className="section">
			<div className="section__header">
				<h3 className="section__title">Reportes</h3>

				<SubSectionsMenu
					subSections={subSections}
					activeSubSection={activeSubSection}
					action={setSubSection}
				/>
			</div>

			<div className="section__content">{template[activeSubSection]}</div>
		</div>
	);
};
