import { useState } from "react";

export const useEditableTable = (initialState = [], operatios = []) => {
	const [table, setTable] = useState(initialState);

	const handleInputChange = (id, value) => {
		const nameSplit = id.split("-");
		const index = nameSplit[0];
		const name = nameSplit[1];

		const updatedInputsTable = table.map((row, i) => {
			if (index === `${i}`) {
				const stringNumber = typeof value === "number" ? value : value.replace(",", () => "");
				return { ...row, [name]: Number(stringNumber) };
			} else return row;
		});

		const newTable = updatedInputsTable.map((row) => {
			const newRow = { ...row };

			operatios.forEach((operation) => {
				const parameters = operation[1].split(",");
				let newFact = 0;

				for (let i = 0; i < parameters.length; i += 2) {
					if (i === 0) newFact = row[parameters[0]];
					else {
						switch (parameters[i - 1]) {
							case "+":
								newFact += row[parameters[i]];
								break;

							case "-":
								newFact -= row[parameters[i]];
								break;

							case "*":
								newFact *= row[parameters[i]];
								break;

							case "/":
								newFact /= row[parameters[i]];
								break;

							default:
								break;
						}
					}
				}

				newRow[operation[0]] = newFact;
			});

			return newRow;
		});

		setTable(newTable);
	};

	const reset = () => {
		setTable(initialState);
	};

	return [table, handleInputChange, setTable, reset];
};
