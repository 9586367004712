import React from "react";
import { useSelector } from "react-redux";
import { MdSave, MdDone } from "react-icons/md";
import { useDispatch } from "react-redux";

import { arraysObjectsDeepEqual } from "../../../helpers/objectsDeepEqual";
import { getColumnTotal } from "../../../helpers/tableHelpers";
import { getFormattedNumberString, roundToN } from "../../../helpers/mathHelpers";
import { TableCellInput } from "./TableCellInput";

export const EditableTable = ({
	title,
	initialData,
	dataState,
	setActiveCell,
	activeCell = null,
	handler,
	paterns,
	groupers,
	handleSaveChanges,
	showSaveButton = true
}) => {
	const { edicion } = useSelector((state) => state.auth);
	const { sidebarExpanded } = useSelector((state) => state.dashboard);
	const dispatch = useDispatch();

	const handleSetActiveCell = (cell) => {
		dispatch(setActiveCell(cell));
	};

	const headersConstructor = (paterns) => {
		return (
			<>
				{groupers()}

				<tr className="editableTable__headerRow">
					{paterns.map((patern, i) => (
						<th
							key={i + patern.header}
							className={i === 0 ? "editableTable__headerId" : "editableTable__headerCell"}
						>
							{patern.header}
						</th>
					))}
				</tr>
			</>
		);
	};

	const rowConstructor = (row, i, handler, paterns) => {
		return (
			<tr key={row.id} className="editableTable__bodyRow">
				{paterns.map((patern) => {
					return patern.editable && activeCell === `${i}-${patern.name}` ? (
						<td key={`${i}-${patern.name}`} className="editableTable__bodyCell">
							<TableCellInput
								name={`${i}-${patern.name}`}
								initialValue={row[patern.name]}
								handler={handler}
								setActiveCell={setActiveCell}
							/>
						</td>
					) : (
						<td
							key={`${i}-${patern.name}`}
							// className="editableTable__bodyCell"
							className={
								patern.name === "id"
									? "editableTable__cellId"
									: patern.editable
									? "editableTable__bodyCell--editable"
									: "editableTable__bodyCell"
							}
							onClick={() => handleSetActiveCell(`${i}-${patern.name}`)}
						>
							<div className="editableTable__cellContent">
								{patern.type === "number"
									? getFormattedNumberString(roundToN(row[patern.name], 3))
									: row[patern.name]}
							</div>
						</td>
					);
				})}
			</tr>
		);
	};

	const tableConstructor = (data, handler, paterns) => {
		return data.map((row, i) => rowConstructor(row, i, handler, paterns));
	};

	const totalsRowConstructor = (data, paterns) => {
		return (
			<tr className="editableTable__footerRow">
				{paterns.map((patern) => {
					return patern.type === "number" ? (
						<td key={"total" + patern.name} className="editableTable__footerCell">
							{roundToN(getColumnTotal(data, patern.name), 3).toLocaleString("en")}
						</td>
					) : patern.name === "id" ? (
						<td key={"total" + patern.name} className="editableTable__footerTotal">
							TOTALES
						</td>
					) : (
						<td key={"total" + patern.name} className="editableTable__footerCell"></td>
					);
				})}
			</tr>
		);
	};

	const dataWasEdited = !arraysObjectsDeepEqual(initialData, dataState);

	return (
		<div
			className={`editableTable ${
				sidebarExpanded ? "editableTable--sidebarExpanded" : "editableTable--sidebarCollapsed"
			}`}
		>
			<h4 className="editableTable__title">{title}</h4>
			<table className="editableTable__table">
				<thead className="editableTable__tableHeader">{headersConstructor(paterns)}</thead>

				<tbody className="editableTable__tableBody">
					{tableConstructor(dataState, handler, paterns)}
				</tbody>

				<tfoot className="editableTable__tableFooter">
					{totalsRowConstructor(dataState, paterns)}
				</tfoot>
			</table>

			{showSaveButton &&
				(edicion && dataWasEdited ? (
					<button className="editableTable__saveButton" onClick={handleSaveChanges}>
						Guardar cambios
						<MdSave className="editableTable__saveIcon" />
					</button>
				) : (
					<div className="editableTable__dataSavedMessage">
						<MdDone className="editableTable__successIcon" />
					</div>
				))}
		</div>
	);
};
