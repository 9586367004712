import { updateDoc, doc } from "firebase/firestore";
import { db } from "../firebaseConfig";
import Swal from "sweetalert2";

export const updateDocInFirestore = async (pad, docId, updates) => {
	try {
		const docRef = doc(db, pad, docId);
		await updateDoc(docRef, updates);

		Swal.fire({
			title: "Registro actualizado!",
			text: "Se actualizó con éxito el registro en la base de datos.",
			icon: "success",
			confirmButtonText: "Ok",
			confirmButtonColor: "#3182ce",
			customClass: { popup: "swal__popup" }
		});

		return true;
	} catch (error) {
		console.log("Error code: ", error.code);
		console.log("Error message: ", error.message);
		Swal.fire({
			title: "Error!",
			text: "Error al intentar actualizar la base de datos.",
			icon: "error",
			confirmButtonText: "Ok",
			confirmButtonColor: "#3182ce",
			customClass: { popup: "swal__popup" }
		});
		return false;
	}
};
