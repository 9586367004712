import { types } from "../../../../types/types";

const initialState = {
	compras: [],
	totales: {
		usoAgricola: 0,
		organismoOperador: 0,
		organismoOperadorMA: 0,
	},
	totalCompras: 0,
	compraSelected: null,
};

export const comprasReducer = (state = { ...initialState }, action) => {
	switch (action.type) {
		case types.setCompras:
			return { ...state, compras: action.payload };

		case types.setTotalCompras:
			return { ...state, totalCompras: action.payload };

		case types.setTotalesCompras:
			return { ...state, totales: action.payload };

		case types.selectCompra:
			return { ...state, compraSelected: action.payload };

		case types.addCompra:
			return {
				...state,
				compraSelected: {
					id: "",
					entidad: "",
					concepto: "",
					usoAgricola: 0,
					organismoOperador: 0,
					organismoOperadorMA: 0,
					volumen: "",
					cuota: "",
					fecha: "",
					filesToUpload: {},
					filesInFirebase: [],
				},
			};

		case types.closeCompraForm:
			return { ...state, compraSelected: null };

		case types.saveCompra:
			return {
				...state,
				compras: [
					action.payload,
					...state.compras.filter((compra) => compra.id !== action.payload.id),
				],
				compraSelected: action.payload,
			};

		case types.deleteCompra:
			return {
				...state,
				compras: state.compras.filter((compra) => compra.id !== action.payload),
				compraSelected: null,
			};

		case types.deleteSingleCompraFile:
			return {
				...state,
				compras: state.compras.map((compra) => {
					if (compra.id === action.payload.compraId) {
						const files = compra.filesInFirebase.filter(
							(file) => file.name !== action.payload.fileName
						);
						compra.filesInFirebase = files;
						return compra;
					} else return compra;
				}),
				compraSelected: {
					...state.compraSelected,
					filesInFirebase: state.compraSelected.filesInFirebase.filter(
						(file) => file.name !== action.payload.fileName
					),
				},
			};

		default:
			return state;
	}
};
