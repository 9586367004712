import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { startLoadEntidades } from "../../../../actions/dashboard/sections/settings/entidadesActions";
import { startLoadOfertas } from "../../../../actions/dashboard/sections/transacciones/ofertasActions";
import { setTransaccionesActiveSubSection } from "../../../../actions/dashboard/sections/transacciones/transaccionesActions";
import { startLoadTransferencias } from "../../../../actions/dashboard/sections/transacciones/transferenciasActions";
import { startLoadTraspasos } from "../../../../actions/dashboard/sections/transacciones/traspasosActions";
import { startLoadCompras } from "../../../../actions/dashboard/sections/transacciones/comprasActions";
import { SubSectionsMenu } from "../../ui/SubSectionsMenu";
import { TableAndDetailTemplate } from "../templates/TableAndDetailTemplate";
import { OfertaForm } from "./OfertaForm";
import { OfertasTable } from "./OfertasTable";
import { TransferenciasTable } from "./TransferenciasTable";
import { TransferenciasSelector } from "./TransferenciasSelector";
import { TraspasoForm } from "./TraspasoForm";
import { TraspasosTable } from "./TraspasosTable";
import { CompraForm } from "./CompraForm";
import { ComprasTable } from "./ComprasTable";
import { startLoadCuotas } from "../../../../actions/dashboard/sections/settings/cuotasActions";
import { startLoadComisiones } from "../../../../actions/dashboard/sections/settings/comisionesActions";
import { IngresosTable } from "./IngresosTable";
import { IngresoForm } from "./IngresoForm";
import { startLoadIngresos } from "../../../../actions/dashboard/sections/transacciones/ingresosActions";
import { PagosTable } from "./PagosTable";
import { PagoForm } from "./PagoForm";
import { startLoadPagos } from "../../../../actions/dashboard/sections/transacciones/pagosActions";
import { AvailableWaterCard } from "../../ui/AvailableWaterCard";

export const TransaccionesSection = () => {
	const { subSections, activeSubSection } = useSelector((state) => state.transacciones);
	const { currentCycle } = useSelector((state) => state.dashboard);
	const { ofertaSelected } = useSelector((state) => state.ofertas);
	const { compraSelected } = useSelector((state) => state.compras);
	const { traspasoSelected } = useSelector((state) => state.traspasos);
	const { ingresoSelected } = useSelector((state) => state.ingresos);
	const { pagoSelected } = useSelector((state) => state.pagos);
	const { isMobile } = useSelector((state) => state.ui);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(startLoadEntidades());
		dispatch(startLoadOfertas(currentCycle));
		dispatch(startLoadCompras(currentCycle));
		dispatch(startLoadTraspasos(currentCycle));
		dispatch(startLoadIngresos(currentCycle));
		dispatch(startLoadPagos(currentCycle));
		dispatch(startLoadTransferencias(currentCycle));
		dispatch(startLoadCuotas());
		dispatch(startLoadComisiones());
	}, [dispatch, currentCycle]);

	const setSubSection = (section) => {
		dispatch(setTransaccionesActiveSubSection(section));
	};

	const getTemplate = () => {
		switch (activeSubSection) {
			case "Ofertas":
				return (
					<>
						<AvailableWaterCard />
						<TableAndDetailTemplate
							Table={() => (isMobile && ofertaSelected ? <></> : <OfertasTable />)}
							Detail={() => <OfertaForm oferta={ofertaSelected} />}
							isOpenDetail={ofertaSelected}
						/>
					</>
				);

			case "Compras":
				return (
					<>
						<AvailableWaterCard />
						<TableAndDetailTemplate
							Table={() => (isMobile && compraSelected ? <></> : <ComprasTable />)}
							Detail={() => <CompraForm compra={compraSelected} />}
							isOpenDetail={compraSelected}
						/>
					</>
				);

			case "Traspasos":
				return (
					<TableAndDetailTemplate
						Table={() => (isMobile && traspasoSelected ? <></> : <TraspasosTable />)}
						Detail={() => <TraspasoForm traspaso={traspasoSelected} />}
						isOpenDetail={traspasoSelected}
					/>
				);

			case "Ingresos":
				return (
					<TableAndDetailTemplate
						Table={() => (isMobile && ingresoSelected ? <></> : <IngresosTable />)}
						Detail={() => <IngresoForm ingreso={ingresoSelected} />}
						isOpenDetail={ingresoSelected}
					/>
				);

			case "Pagos":
				return (
					<TableAndDetailTemplate
						Table={() => (isMobile && pagoSelected ? <></> : <PagosTable />)}
						Detail={() => <PagoForm pago={pagoSelected} />}
						isOpenDetail={pagoSelected}
					/>
				);

			case "Transferencias":
				return (
					<>
						<TransferenciasSelector />
						<TransferenciasTable />
					</>
				);

			default:
				return <></>;
		}
	};

	return (
		<div className="section">
			<div className="section__header">
				<h3 className="section__title">Transacciones</h3>

				<SubSectionsMenu
					subSections={subSections}
					activeSubSection={activeSubSection}
					action={setSubSection}
				/>
			</div>

			<div className="section__content">{getTemplate()}</div>
		</div>
	);
};
