import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { ReportPage } from "./ReportPage";
import { MdPrint, MdDownload } from "react-icons/md";
import { createExcelWithSheets, exportJSONToExcel } from "../../../helpers/exportJSONToExcel";

export const Report = ({ title, items, downloadable = {}, downloadableSheets, action = false }) => {
	const componentRef = useRef();

	const handleDownload = () => {
		if (downloadableSheets) {
			createExcelWithSheets(
				downloadableSheets.sheets,
				downloadableSheets.title,
				downloadableSheets.author
			);
		} else
			exportJSONToExcel(
				downloadable.data,
				downloadable.headers,
				downloadable.title,
				downloadable.author,
				downloadable.sheetName
			);
	};

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	return (
		<div className="report">
			<div className="reportSettings">
				<div onClick={handleDownload} className="reportSettings__button">
					<MdDownload className="reportSettings__icon" /> Descargar
				</div>

				<div onClick={handlePrint} className="reportSettings__button">
					<MdPrint className="reportSettings__icon" /> Imprimir
				</div>

				{action && (
					<div onClick={action.action} className="reportSettings__button">
						{action.label}
					</div>
				)}
			</div>

			<ReportPage ref={componentRef} title={title} items={items} />
		</div>
	);
};
