import React from "react";
import { useSelector } from "react-redux";

export const UserCard = ({ isExpanded, large }) => {
	const { userImage, displayName } = useSelector((state) => state.auth);

	return (
		<div className="userCard">
			<div className={`userCard__userImage ${large && "userCard__userImage--large"}`}>
				<img src={userImage} alt="user" />
			</div>

			<div
				className={`userCard__name ${
					isExpanded ? "userCard__name--expanded" : "userCard__name--collapsed"
				} ${large && "userCard__name--large"}`}
			>
				{displayName}
			</div>
		</div>
	);
};
