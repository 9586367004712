import { types } from "../../../../types/types";

const initialState = {
	cuotas: [],
	cuotaSelected: null
};

export const cuotasReducer = (state = { ...initialState }, action) => {
	switch (action.type) {
		case types.setCuotas:
			return { ...state, cuotas: action.payload };

		case types.selectCuota:
			return { ...state, cuotaSelected: action.payload };

		case types.addCuota:
			return {
				...state,
				cuotaSelected: {
					id: "",
					descripcion: "",
					vigencia: "",
					inicio: "",
					fin: "",
					costo: 0
				}
			};

		case types.closeCuotaForm:
			return { ...state, cuotaSelected: null };

		case types.saveCuota:
			return {
				...state,
				cuotas: [action.payload, ...state.cuotas.filter((cuota) => cuota.id !== action.payload.id)],
				cuotaSelected: action.payload
			};

		case types.deleteCuota:
			return {
				...state,
				cuotas: state.cuotas.filter((cuota) => cuota.id !== action.payload),
				cuotaSelected: null
			};

		default:
			return state;
	}
};
