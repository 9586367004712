import React from "react";
import { MdAddCircle } from "react-icons/md";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
	addContacto,
	selectContacto
} from "../../../../actions/dashboard/sections/settings/contactosActions";

import { contactosColumns } from "../../../../settings/tablesSettings";
import { CustomTable } from "../../ui/CustomTable";

export const ContactosTable = () => {
	const { edicion } = useSelector((state) => state.auth);
	const { contactos, contactoSelected } = useSelector((state) => state.contactos);

	const dispatch = useDispatch();

	const selectRow = (row) => {
		dispatch(selectContacto(row));
	};

	const addNewContacto = () => {
		dispatch(addContacto());
	};

	return (
		<div className="collectionTable">
			<CustomTable
				title="Contactos"
				columns={contactosColumns}
				data={contactos}
				selectRowId={contactoSelected ? contactoSelected.id : ""}
				onRowClickedAction={selectRow}
			/>

			{edicion && (
				<div className="collectionTable__addButton" onClick={addNewContacto}>
					<MdAddCircle />
				</div>
			)}
		</div>
	);
};
