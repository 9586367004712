import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";

export const TableCellInput = ({ name, initialValue, handler, setActiveCell }) => {
	const [value, setValue] = useState(initialValue);
	const dispatch = useDispatch();

	const handleKeyUp = (event) => {
		if (event.key === "Enter") {
			handler(name, value);
			const cell = name.split("-");
			dispatch(setActiveCell(`${Number(cell[0]) + 1}-${cell[1]}`));
		}

		if (event.key === "Escape") {
			dispatch(setActiveCell(null));
		}
	};

	const handleFocus = (e) => e.target.select();

	return (
		<input
			id={name}
			type="text"
			className="editableTable__input"
			name={name}
			value={value}
			onChange={(e) => setValue(e.target.value)}
			onKeyUp={handleKeyUp}
			onFocus={handleFocus}
			autoFocus
		/>
	);
};
