import { sicperDb } from "../sicperFirebaseConfig";
import { collectionGroup, query, where, getDocs } from "firebase/firestore";

export const getTransferencias = async (ciclo) => {
	const transferencias = [];

	const transferQuery = query(
		collectionGroup(sicperDb, "transferencias"),
		where("ciclo", "==", ciclo)
	);
	const transferSnap = await getDocs(transferQuery);

	transferSnap.forEach((snap) => {
		if (snap.data().moduloDestino !== "dev") {
			const fecha = new Date(snap.data().fecha.seconds * 1000);
			transferencias.push({
				origen: `${snap.data().modulo}`,
				destino: snap.data().moduloDestino,
				ha: snap.data().superficieTransferida,
				mes: fecha.getMonth() + 1
			});
		}
	});

	return transferencias;
};
