import { types } from "../../types/types";

export const setPopoverState = (state) => ({
	type: types.setPopoverState,
	payload: state
});

export const collapsePopover = () => ({
	type: types.collapsePopover
});

export const setSidebarState = (state) => ({
	type: types.setSidebarState,
	payload: state
});

export const collapseSidebar = () => ({
	type: types.collapseSidebar
});

export const setCurrenCycle = (cycle) => ({
	type: types.setCurrenCycle,
	payload: cycle
});
