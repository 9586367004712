import { deleteFilesOnFirebase } from "../../../../firebase/crudFirestoreActions/deleteFilesOnFirebase";
import { deletePagoTransaction } from "../../../../firebase/crudFirestoreActions/deletePagoTransaction";
import { deleteSingleFileOnFirebase } from "../../../../firebase/crudFirestoreActions/deleteSingleFileOnFirebase";
import { loadCollectionFromFirestore } from "../../../../firebase/crudFirestoreActions/loadCollectionFromFirestor";
import { saveFilesOnFirebase } from "../../../../firebase/crudFirestoreActions/saveFilesOnFirebase";
import { savePagoTransaction } from "../../../../firebase/crudFirestoreActions/savePagoTransaction";
import { updateDocInFirestore } from "../../../../firebase/crudFirestoreActions/updateDocInFirestore";
import { roundToN } from "../../../../helpers/mathHelpers";
import { types } from "../../../../types/types";
import { uiFinishLoading, uiStartLoading } from "../../../uiActions";

export const startLoadPagos = (cycle) => {
	return async (dispatch) => {
		const pagos = await loadCollectionFromFirestore(`ciclos/${cycle}/pagos`);

		let totalPagos = 0;
		pagos.forEach((pago) => {
			totalPagos += pago.importe;
			pago.importe = roundToN(pago.importe, 2);
		});

		dispatch(setTotalPagos(totalPagos));
		dispatch(setPagos(pagos));
	};
};

export const setPagos = (pagos) => ({
	type: types.setPagos,
	payload: pagos,
});

export const setTotalPagos = (total) => ({
	type: types.setTotalPagos,
	payload: total,
});

export const selectPago = (pago) => ({
	type: types.selectPago,
	payload: pago,
});

export const addPago = () => ({
	type: types.addPago,
});

export const closePagoForm = () => ({
	type: types.closePagoForm,
});

export const startSavePago = (pago, cycle) => {
	return async (dispatch, getState) => {
		const state = getState();
		const comision = state.comisiones.comisiones.find((comision) => comision.id === pago.comision);

		dispatch(uiStartLoading());

		const today = new Date();
		if (!pago.id) pago.id = today.getTime().toString().substring(5);

		const filesRefs = await saveFilesOnFirebase(
			pago.id,
			pago.filesToUpload,
			state.pagos.pagoSelected.filesInFirebase,
			`${cycle}/pagos`
		);

		pago.filesInFirebase = [...pago.filesInFirebase, ...filesRefs];
		pago.filesToUpload = [];

		const isDocSave = await savePagoTransaction(cycle, { ...pago }, comision.porcentaje, pago.id);

		if (filesRefs && isDocSave) {
			dispatch(setTotalPagos(state.pagos.totalPagos + pago.importe));
			dispatch(savePago({ ...pago }));
			dispatch(uiFinishLoading());
		}
	};
};

export const savePago = (pago) => ({
	type: types.savePago,
	payload: pago,
});

export const startDeletePago = (pago, cycle) => {
	return async (dispatch, getState) => {
		const state = getState();
		const isDeleted = await deletePagoTransaction(cycle, pago.id);
		const areFilesDeleted = await deleteFilesOnFirebase(`${cycle}/pagos`, pago.filesInFirebase);
		if (isDeleted && areFilesDeleted) {
			dispatch(deletePago(pago.id));
			dispatch(setTotalPagos(state.pagos.totalPagos - Number(pago.volumenTotal)));
		}
	};
};

export const deletePago = (pagoId) => ({
	type: types.deletePago,
	payload: pagoId,
});

export const startDeleteSinglePagoFile = (cycle, pago, file) => {
	return async (dispatch) => {
		const isDeleted = await deleteSingleFileOnFirebase(`${cycle}/pagos`, file);

		if (isDeleted) {
			const files = pago.filesInFirebase.filter((filterFile) => filterFile.name !== file.name);
			const isUpdates = await updateDocInFirestore(`ciclos/${cycle}/pagos`, pago.id, {
				filesInFirebase: files,
			});
			if (isUpdates) dispatch(deleteSinglePagoFile(pago.id, file.name));
		}
	};
};

export const deleteSinglePagoFile = (pagoId, fileName) => ({
	type: types.deleteSinglePagoFile,
	payload: { pagoId, fileName },
});
