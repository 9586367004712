import { types } from "../../../../types/types";

const initialState = {
	comisiones: [],
	comisionSelected: null
};

export const comisionesReducer = (state = { ...initialState }, action) => {
	switch (action.type) {
		case types.setComisiones:
			return { ...state, comisiones: action.payload };

		case types.selectComision:
			return { ...state, comisionSelected: action.payload };

		case types.addComision:
			return {
				...state,
				comisionSelected: {
					id: "",
					descripcion: "",
					vigencia: "",
					inicio: "",
					fin: "",
					porcentaje: 0
				}
			};

		case types.closeComisionForm:
			return { ...state, comisionSelected: null };

		case types.saveComision:
			return {
				...state,
				comisiones: [
					action.payload,
					...state.comisiones.filter((comision) => comision.id !== action.payload.id)
				],
				comisionSelected: action.payload
			};

		case types.deleteComision:
			return {
				...state,
				comisiones: state.comisiones.filter((comision) => comision.id !== action.payload),
				comisionSelected: null
			};

		default:
			return state;
	}
};
