import { types } from "../../../../types/types";

const initialState = {
	cargos: [],
	totalCargos: 0,
	cargoSelected: null,
};

export const cargosReducer = (state = { ...initialState }, action) => {
	switch (action.type) {
		case types.setCargos:
			return { ...state, cargos: action.payload };

		case types.setTotalCargos:
			return { ...state, totalCargos: action.payload };

		case types.selectCargo:
			return { ...state, cargoSelected: action.payload };

		case types.addCargo:
			return {
				...state,
				cargoSelected: {
					id: "",
					fecha: "",
					entidad: "",
					importe: "",
					concepto: "",
					comision: "",
					referencia: "",
					filesToUpload: {},
					filesInFirebase: [],
				},
			};

		case types.closeCargoForm:
			return { ...state, cargoSelected: null };

		case types.saveCargo:
			return {
				...state,
				cargos: [action.payload, ...state.cargos.filter((cargo) => cargo.id !== action.payload.id)],
				cargoSelected: action.payload,
			};

		case types.deleteCargo:
			return {
				...state,
				cargos: state.cargos.filter((cargo) => cargo.id !== action.payload),
				cargoSelected: null,
			};

		case types.deleteSingleCargoFile:
			return {
				...state,
				cargos: state.cargos.map((cargo) => {
					if (cargo.id === action.payload.cargoId) {
						const files = cargo.filesInFirebase.filter(
							(file) => file.name !== action.payload.fileName
						);
						cargo.filesInFirebase = files;
						return cargo;
					} else return cargo;
				}),
				cargoSelected: {
					...state.cargoSelected,
					filesInFirebase: state.cargoSelected.filesInFirebase.filter(
						(file) => file.name !== action.payload.fileName
					),
				},
			};

		default:
			return state;
	}
};
