import React from "react";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { modulos } from "../../../../helpers/constants";
import { useSelector } from "react-redux";

export const OperacionChart = () => {
	const { data: asignacionData } = useSelector((state) => state.asignacion);
	const { data: distribucionData } = useSelector((state) => state.distribucion);

	ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

	const options = {
		responsive: true,
		plugins: {
			legend: {
				position: "top",
			},
			// title: {
			// 	display: true,
			// 	text: "Operación (Mm3)"
			// }
		},
	};

	const data = {
		labels: modulos,
		datasets: [
			{
				label: "Asignación",
				data: modulos.map((moduloLabel) => {
					const moduloAsignacion = asignacionData.find((modulo) => modulo.id === moduloLabel);
					return moduloAsignacion.total;
				}),
				backgroundColor: "rgba(45, 55, 72, 0.7)",
			},
			{
				label: "Distribución",
				data: modulos.map((moduloLabel) => {
					const moduloDistribucion = distribucionData.find((modulo) => modulo.id === moduloLabel);
					return moduloDistribucion.total;
				}),
				backgroundColor: "rgba(53, 162, 235, 0.5)",
			},
		],
	};

	return <Bar options={options} data={data} className="operacionChart" />;
};
