import { applyMiddleware, combineReducers, createStore, compose } from "redux";
import thunk from "redux-thunk";

import { uiReducer } from "../reducers/uiReducer";
import { authReducer } from "../reducers/auth/authReducer";
import { dashboardReducer } from "../reducers/dashboard/dashboardReducer";
import { settingsReducer } from "../reducers/dashboard/sections/settings/settingsReducer";
import { entidadesReducer } from "../reducers/dashboard/sections/settings/entidadesReducer";
import { contactosReducer } from "../reducers/dashboard/sections/settings/contactosReducer";
import { cuotasReducer } from "../reducers/dashboard/sections/settings/cuotasReducer";
import { comisionesReducer } from "../reducers/dashboard/sections/settings/comisionesReducer";
import { operacionReducer } from "../reducers/dashboard/sections/operacion/operacionReducer";
import { asignacionReducer } from "../reducers/dashboard/sections/operacion/asignacionReducer";
import { distribucionReducer } from "../reducers/dashboard/sections/operacion/distribucionReducer";
import { transaccionesReducer } from "../reducers/dashboard/sections/transacciones/transaccionesReducer";
import { ofertasReducer } from "../reducers/dashboard/sections/transacciones/ofertasReducer";
import { comprasReducer } from "../reducers/dashboard/sections/transacciones/comprasReducer";
import { traspasosReducer } from "../reducers/dashboard/sections/transacciones/traspasosReducer";
import { transferenciasReducer } from "../reducers/dashboard/sections/transacciones/transferenciasReducer";
import { reportesReducer } from "../reducers/dashboard/sections/transacciones/reportesReducer";
import { ingresosReducer } from "../reducers/dashboard/sections/transacciones/ingresosReducer";
import { pagosReducer } from "../reducers/dashboard/sections/transacciones/pagosReducer";
import { cargosReducer } from "../reducers/dashboard/sections/transacciones/cargosReducer";

const composeEnhancers =
	(typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const reducers = combineReducers({
	ui: uiReducer,
	auth: authReducer,
	dashboard: dashboardReducer,
	settings: settingsReducer,
	entidades: entidadesReducer,
	contactos: contactosReducer,
	cuotas: cuotasReducer,
	comisiones: comisionesReducer,
	operacion: operacionReducer,
	asignacion: asignacionReducer,
	distribucion: distribucionReducer,
	transacciones: transaccionesReducer,
	ofertas: ofertasReducer,
	ingresos: ingresosReducer,
	pagos: pagosReducer,
	cargos: cargosReducer,
	compras: comprasReducer,
	traspasos: traspasosReducer,
	transferencias: transferenciasReducer,
	reportes: reportesReducer,
});

export const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));
