import React from "react";
import { useSelector } from "react-redux";
import { Report } from "../../ui/Report";
import { getTotalRow } from "../../../../helpers/tableHelpers";

export const DispersionReport = () => {
	const { currentCycle } = useSelector((state) => state.dashboard);
	const { displayName } = useSelector((state) => state.auth);
	const { ofertas } = useSelector((state) => state.ofertas);
	const { compras } = useSelector((state) => state.compras);
	const { cuotas } = useSelector((state) => state.cuotas);

	const data = prepareData(ofertas, compras, cuotas);
	const aplicarDispercion = () => {
		const dispercion = {};
		data.forEach((modulo) => {
			dispercion[modulo.id] = modulo.total;
		});

		console.log(dispercion);
	};

	const items = [
		{
			type: "table",
			data,
			patterns: [
				{
					id: "id",
					header: "MÓDULO",
					type: "text",
					width: "auto",
					textAlign: "start",
					volumen: false,
				},

				{
					id: "ofertaUsoAgricola",
					header: "OF-UA (MM3)",
					type: "number",
					width: "auto",
					textAlign: "start",
					volumen: false,
				},

				{
					id: "porcentajeUsoAgricola",
					header: "UA (%)",
					type: "number",
					width: "auto",
					textAlign: "start",
					volumen: false,
				},

				{
					id: "pagoUsoAgricola",
					header: "UA (MN)",
					type: "number",
					width: "auto",
					textAlign: "start",
					volumen: false,
				},

				{
					id: "ofertaOrganismoOperador",
					header: "OF-OO (MM3)",
					type: "number",
					width: "auto",
					textAlign: "start",
					volumen: false,
				},

				{
					id: "porcentajeOrganismoOperador",
					header: "OO (%)",
					type: "number",
					width: "auto",
					textAlign: "start",
					volumen: false,
				},

				{
					id: "pagoOrganismoOperador",
					header: "OO (MN)",
					type: "number",
					width: "auto",
					textAlign: "start",
					volumen: false,
				},

				{
					id: "ofertaOrganismoOperadorMA",
					header: "OF-MA (MM3)",
					type: "number",
					width: "auto",
					textAlign: "start",
					volumen: false,
				},

				{
					id: "porcentajeOrganismoOperadorMA",
					header: "MA (%)",
					type: "number",
					width: "auto",
					textAlign: "start",
					volumen: false,
				},

				{
					id: "pagoOrganismoOperadorMA",
					header: "MA (MN)",
					type: "number",
					width: "auto",
					textAlign: "start",
					volumen: false,
				},

				{
					id: "total",
					header: "TOTAL (MN)",
					type: "number",
					width: "auto",
					textAlign: "start",
					volumen: false,
				},
			],
		},
	];

	const downloadable = {
		data: getDownloadableTable(items[0].patterns, items[0].data),
		headers: items[0].patterns.map((header) => header.id),
		title: `DISPERSIÓN ${currentCycle}`,
		author: displayName,
		sheetName: `DISPERSIÓN ${currentCycle}`,
	};

	const action = {
		action: aplicarDispercion,
		label: "Aplicar",
	};

	return (
		<>
			<Report
				title={`DISPERSIÓN ${currentCycle}`}
				items={items}
				downloadable={downloadable}
				action={action}
			/>
		</>
	);
};

const prepareData = (ofertas, compras, cuotas) => {
	const data = [];
	const conceptos = ["usoAgricola", "organismoOperador", "organismoOperadorMA"];

	const totalOfertas = {
		usoAgricola: 0,
		organismoOperador: 0,
		organismoOperadorMA: 0,
	};

	ofertas.forEach((oferta) => {
		conceptos.forEach((concepto) => {
			totalOfertas[concepto] = totalOfertas[concepto] + oferta[concepto];
		});
	});

	const totalCompras = {
		usoAgricola: 0,
		organismoOperador: 0,
		organismoOperadorMA: 0,
	};

	compras.forEach((compra) => {
		const cuota = cuotas.find((cuota) => cuota.id === compra.cuota);

		conceptos.forEach((concepto) => {
			totalCompras[concepto] = totalCompras[concepto] + compra[concepto] * cuota.costo;
		});
	});

	const modulos = [];
	ofertas.forEach((oferta) => {
		modulos.push(oferta.entidad);
	});

	const modulosUnicos = modulos.filter((modulo, index) => {
		return modulos.indexOf(modulo) === index;
	});

	const ofertasUnicas = modulosUnicos.map((modulo) => {
		const row = {
			entidad: modulo,
			usoAgricola: 0,
			organismoOperador: 0,
			organismoOperadorMA: 0,
		};

		const ofertasModulo = ofertas.filter((oferta) => oferta.entidad === modulo);
		ofertasModulo.forEach((oferta) => {
			row.usoAgricola = row.usoAgricola + oferta.usoAgricola;
			row.organismoOperador = row.organismoOperador + oferta.organismoOperador;
			row.organismoOperadorMA = row.organismoOperadorMA + oferta.organismoOperadorMA;
		});

		return row;
	});

	ofertasUnicas.forEach((oferta) => {
		const row = {
			id: oferta.entidad,
			ofertaUsoAgricola: oferta.usoAgricola,
			ofertaOrganismoOperador: oferta.organismoOperador,
			ofertaOrganismoOperadorMA: oferta.organismoOperadorMA,
		};

		const porcentajes = {
			porcentajeUsoAgricola: (oferta.usoAgricola / totalOfertas.usoAgricola) * 100,
			porcentajeOrganismoOperador:
				(oferta.organismoOperador / totalOfertas.organismoOperador) * 100,
			porcentajeOrganismoOperadorMA:
				(oferta.organismoOperadorMA / totalOfertas.organismoOperadorMA) * 100,
		};

		const pagos = {
			pagoUsoAgricola: (totalCompras.usoAgricola / 100) * porcentajes.porcentajeUsoAgricola,
			pagoOrganismoOperador:
				(totalCompras.organismoOperador / 100) * porcentajes.porcentajeOrganismoOperador,
			pagoOrganismoOperadorMA:
				(totalCompras.organismoOperadorMA / 100) * porcentajes.porcentajeOrganismoOperadorMA,
		};

		data.push({
			...row,
			...porcentajes,
			...pagos,
			total: pagos.pagoUsoAgricola + pagos.pagoOrganismoOperador + pagos.pagoOrganismoOperadorMA,
		});
	});

	return data;
};

const getDownloadableTable = (patterns, data) => {
	const table = [...data];
	table.push(getTotalRow(patterns, data));
	return table;
};
