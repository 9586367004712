import { loadCollectionFromFirestore } from "../../../../firebase/crudFirestoreActions/loadCollectionFromFirestor";
import { saveBatchOnFirestore } from "../../../../firebase/crudFirestoreActions/saveBatchOnFirestore";
import { modulos } from "../../../../helpers/constants";
import { types } from "../../../../types/types";

const generateAsignacionInitialData = (entities, asignacion) => {
	//b => Octubre a diciembre gravedad uso agricola
	//c => Octubre a diciembre gravedad uso domestico
	//d => Octubre a diciembre suma uso agrícola y uso doméstico
	//e => Octubre a diciembre pozo federal
	//f => Octubre a diciembre total
	//g => Enero a julio gravedad uso agricola
	//h => Enero a julio gravedad uso domestico
	//i => Enero a julio suma uso agrícola y uso doméstico
	//j => Enero a septiembre pozo federal
	//k => Enero a septiembre total
	//l => Nueva asignacion agosto
	//m => Nueva asignacion uso domestico
	//n => Nueva asignacion septiembre
	//o => Nueva asignacion uso domestico
	//p => Nueva asignacion total enero septiembre uso agricola + uso domestico
	//q => Total octubre a septiembre gravedad uso agricola
	//r => Total octubre a septiembre gravedad uso domestico
	//s => Total octubre a septiembre suma uso agricola y domestico
	//t => Total

	const initialRow = {
		b: 0,
		c: 0,
		d: 0,
		e: 0,
		f: 0,
		g: 0,
		h: 0,
		i: 0,
		j: 0,
		k: 0,
		l: 0,
		m: 0,
		n: 0,
		o: 0,
		p: 0,
		q: 0,
		r: 0,
		s: 0,
		t: 0,
		total: 0
	};
	const data = entities.map((entitie) => {
		const row = asignacion.find((asignacionEntitie) => asignacionEntitie.id === entitie);

		if (row) return row;
		else return { id: entitie, ...initialRow };
	});

	return data;
};

export const startSetAsignacionData = (cycle) => {
	return async (dispatch) => {
		const asignacionInDB = await loadCollectionFromFirestore(`ciclos/${cycle}/asignacion`);
		const initialAsignacion = await generateAsignacionInitialData(modulos, asignacionInDB);
		dispatch(setAsignacionData(initialAsignacion));
	};
};
export const startSaveAsignacionData = (data) => {
	return async (dispatch, getState) => {
		const currentCycle = getState().dashboard.currentCycle;
		const isSave = saveBatchOnFirestore(`ciclos/${currentCycle}/asignacion`, data);

		if (isSave) dispatch(setAsignacionData(data));
	};
};

export const setAsignacionData = (data) => ({
	type: types.setAsignacionData,
	payload: data
});

export const setAsignacionActiveCell = (activeCell) => ({
	type: types.setAsignacionActiveCell,
	payload: activeCell
});
