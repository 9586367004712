import { types } from "../../../../types/types";

const initialState = {
	ofertas: [],
	totales: {
		usoAgricola: 0,
		organismoOperador: 0,
		organismoOperadorMA: 0,
	},
	totalOfertas: 0,
	ofertaSelected: null,
};

export const ofertasReducer = (state = { ...initialState }, action) => {
	switch (action.type) {
		case types.setOfertas:
			return { ...state, ofertas: action.payload };

		case types.setTotalOfertas:
			return { ...state, totalOfertas: action.payload };

		case types.setTotalesOfertas:
			return { ...state, totales: action.payload };

		case types.selectOferta:
			return { ...state, ofertaSelected: action.payload };

		case types.addOferta:
			return {
				...state,
				ofertaSelected: {
					id: "",
					fecha: "",
					entidad: "",
					usoAgricola: 0,
					organismoOperador: 0,
					organismoOperadorMA: 0,
					volumenTotal: 0,
					filesToUpload: {},
					filesInFirebase: [],
				},
			};

		case types.closeOfertaForm:
			return { ...state, ofertaSelected: null };

		case types.saveOferta:
			return {
				...state,
				ofertas: [
					action.payload,
					...state.ofertas.filter((oferta) => oferta.id !== action.payload.id),
				],
				ofertaSelected: action.payload,
			};

		case types.deleteOferta:
			return {
				...state,
				ofertas: state.ofertas.filter((oferta) => oferta.id !== action.payload),
				ofertaSelected: null,
			};

		case types.deleteSingleOfertaFile:
			return {
				...state,
				ofertas: state.ofertas.map((oferta) => {
					if (oferta.id === action.payload.ofertaId) {
						const files = oferta.filesInFirebase.filter(
							(file) => file.name !== action.payload.fileName
						);
						oferta.filesInFirebase = files;
						return oferta;
					} else return oferta;
				}),
				ofertaSelected: {
					...state.ofertaSelected,
					filesInFirebase: state.ofertaSelected.filesInFirebase.filter(
						(file) => file.name !== action.payload.fileName
					),
				},
			};

		default:
			return state;
	}
};
