export const getMoneyData = (compras, ingresos, pagos, cargos, corte, cuotas, initialBalance) => {
	const tableData = [
		{
			fecha: "",
			concepto: "SALDO INICIAL",
			cuota: 0,
			volumen: 0,
			importe: initialBalance,
		},
	];

	const cortes = {
		"OCTUBRE-DICIEMBRE": ["10", "11", "12"],
		"ENERO-JULIO": ["10", "11", "12", "1", "2", "3", "4", "5", "6", "7"],
		AGOSTO: ["10", "11", "12", "1", "2", "3", "4", "5", "6", "7", "8"],
		SEPTIEMBRE: ["10", "11", "12", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
	};

	compras.forEach((compra) => {
		const cuota = cuotas.find((cuota) => cuota.id === compra.cuota);
		const month = getMonth(compra.fecha);

		if (cortes[corte].includes(month)) {
			tableData.push({
				fecha: compra.fecha,
				concepto: `COMPRA DE VOLUMEN - ${compra.concepto}`,
				cuota: cuota.costo,
				volumen: compra.volumen,
				importe: compra.volumen * cuota.costo * -1,
			});
		}
	});

	ingresos.forEach((ingreso) => {
		const month = getMonth(ingreso.fecha);

		if (cortes[corte].includes(month)) {
			tableData.push({
				fecha: ingreso.fecha,
				concepto: `ABONOS - ${ingreso.concepto} ${ingreso.referencia}`,
				importe: ingreso.importe,
				volumen: 0,
				cuota: 0,
			});
		}
	});

	pagos.forEach((pago) => {
		const month = getMonth(pago.fecha);

		if (cortes[corte].includes(month)) {
			tableData.push({
				fecha: pago.fecha,
				concepto: `PAGOS - ${pago.concepto} ${pago.referencia}`,
				importe: pago.importe * -1,
				volumen: 0,
				cuota: 0,
			});
		}
	});

	cargos.forEach((cargo) => {
		const month = getMonth(cargo.fecha);

		if (cortes[corte].includes(month)) {
			tableData.push({
				fecha: cargo.fecha,
				concepto: `CARGOS - ${cargo.concepto} ${cargo.referencia}`,
				importe: cargo.importe * -1,
				volumen: 0,
				cuota: 0,
			});
		}
	});

	return tableData;
};

const getMonth = (date) => {
	const dateArray = date.split("-");
	if (dateArray[1].startsWith("0")) return dateArray[1].substring(1);
	else return dateArray[1];
};
