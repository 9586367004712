import React from "react";
import { useSelector } from "react-redux";
import { OfertasChart } from "../home/OfertasChart";
import { Report } from "../../ui/Report";
import { getTotalRow } from "../../../../helpers/tableHelpers";

export const OfertasReport = () => {
	const { currentCycle } = useSelector((state) => state.dashboard);
	const { ofertas } = useSelector((state) => state.ofertas);
	const { displayName } = useSelector((state) => state.auth);

	const items = [
		{
			type: "chart",
			chart: OfertasChart,
			maxWidth: "35rem",
		},
		{
			type: "table",
			data: prepareOfertas(ofertas),
			patterns: [
				{ id: "id", header: "ID", type: "text", width: "auto", textAlign: "start", volumen: false },
				{
					id: "entidad",
					header: "ENTIDAD",
					type: "text",
					width: "auto",
					textAlign: "center",
					volumen: false,
				},
				{
					id: "fecha",
					header: "FECHA",
					type: "text",
					width: "auto",
					textAlign: "start",
					volumen: false,
				},
				{
					id: "organismoOperador",
					header: "ORGANISMO OPERADOR",
					type: "number",
					width: "20%",
					textAlign: "end",
					volumen: true,
				},
				{
					id: "organismoOperadorMA",
					header: "ORGANISMO OPERADOR MA",
					type: "number",
					width: "20%",
					textAlign: "end",
					volumen: true,
				},
				{
					id: "usoAgricola",
					header: "USO AGRICOLA",
					type: "number",
					width: "auto",
					textAlign: "end",
					volumen: true,
				},
				{
					id: "volumenTotal",
					header: "VOLUMEN TOTAL",
					type: "number",
					width: "auto",
					textAlign: "end",
					volumen: true,
				},
			],
		},
	];

	const downloadable = {
		data: getDownloadableTable(items[1].patterns, items[1].data),
		headers: items[1].patterns.map((header) => header.id),
		title: `REPORTE DE OFERTAS DE VOLUMEN`,
		author: displayName,
		sheetName: `REPORTE DE OFERTAS DE VOLUMEN`,
	};

	return (
		<Report
			title={`REPORTE DE OFERTAS DE VOLUMEN CICLO ${currentCycle}`}
			items={items}
			downloadable={downloadable}
		/>
	);
};

const prepareOfertas = (ofertas) => {
	return ofertas.map((oferta) => ({
		id: oferta.id,
		entidad: oferta.entidad,
		fecha: oferta.fecha,
		organismoOperador: oferta.organismoOperador === "" ? 0 : oferta.organismoOperador,
		organismoOperadorMA: oferta.organismoOperadorMA === "" ? 0 : oferta.organismoOperadorMA,
		usoAgricola: oferta.usoAgricola === "" ? 0 : oferta.usoAgricola,
		volumenTotal: Number(oferta.volumenTotal.replace(",", () => "")),
	}));
};

const getDownloadableTable = (patterns, data) => {
	const table = [...data];
	table.push(getTotalRow(patterns, data));
	return table;
};
