import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveOperacioReport } from "../../../../actions/dashboard/sections/transacciones/reportesActions";
import { Selector } from "../../ui/Selector";
import { AsignacionReport } from "./AsignacionReport";
import { DistribucionReport } from "./DistribucionReport";

export const OperacionReport = () => {
	const { operacionReports, activeOperacionReport } = useSelector((state) => state.reportes);
	const { data } = useSelector((state) => state.asignacion);

	const dispatch = useDispatch();

	const handleSetReport = (option) => {
		dispatch(setActiveOperacioReport(option));
	};

	const reportType = {
		Asignación: <AsignacionReport />,
		Distribución: <DistribucionReport />
	};

	return (
		<div>
			<Selector
				title={"Reportes de operación:"}
				options={operacionReports}
				activeOption={activeOperacionReport}
				setter={handleSetReport}
			/>

			{data.length > 0 && reportType[activeOperacionReport]}
		</div>
	);
};
