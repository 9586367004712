import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { AuthHeader } from "../../ui/AuthHeader";
import { LoginForm } from "./LoginForm";
import { AuthFooter } from "../../ui/AuthFooter";
import { uiRemoveError } from "../../../../actions/uiActions";

export const LoginScreen = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(uiRemoveError());
	}, [dispatch]);

	return (
		<div className="sectionContainer">
			<div>
				<AuthHeader />
				<LoginForm />
			</div>
			<AuthFooter />
		</div>
	);
};
