import { loadCollectionFromFirestore } from "../../../../firebase/crudFirestoreActions/loadCollectionFromFirestor";
import { saveBatchOnFirestore } from "../../../../firebase/crudFirestoreActions/saveBatchOnFirestore";
import { modulos } from "../../../../helpers/constants";
import { types } from "../../../../types/types";

const generateDistribucionInitialData = (entities, distribucion) => {
	const initialRow = {
		10: 0,
		11: 0,
		12: 0,
		1: 0,
		2: 0,
		3: 0,
		4: 0,
		5: 0,
		6: 0,
		7: 0,
		8: 0,
		9: 0,
		total: 0
	};

	const data = entities.map((entitie) => {
		const row = distribucion.find((distribucionEntitie) => distribucionEntitie.id === entitie);

		if (row) return row;
		else return { id: entitie, ...initialRow };
	});

	return data;
};

export const startSetDistribucionData = (cycle) => {
	return async (dispatch) => {
		// const entities = await loadCollectionFromFirestore("entidades");
		const distribucionInDB = await loadCollectionFromFirestore(`ciclos/${cycle}/distribucion`);
		const initialDistribucion = await generateDistribucionInitialData(modulos, distribucionInDB);
		dispatch(setDistribucionData(initialDistribucion));
	};
};
export const startSaveDistribucionData = (data) => {
	return async (dispatch, getState) => {
		const currentCycle = getState().dashboard.currentCycle;
		const isSave = saveBatchOnFirestore(`ciclos/${currentCycle}/distribucion`, data);

		if (isSave) dispatch(setDistribucionData(data));
	};
};

export const setDistribucionData = (data) => ({
	type: types.setDistribucionData,
	payload: data
});

export const setDistribucionActiveCell = (activeCell) => ({
	type: types.setDistribucionActiveCell,
	payload: activeCell
});
