import { ref, deleteObject } from "firebase/storage";
import Swal from "sweetalert2";
import { storage } from "../firebaseConfig";

export const deleteSingleFileOnFirebase = async (pad, file) => {
	try {
		const isDeleted = await Swal.fire({
			title: "Atención!!",
			text: `Está a punto de eliminar el archivo  "${file.name}", ¿Realmente desea eliminarlo?`,
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3182ce",
			cancelButtonColor: "#cf4e46",
			confirmButtonText: "Si",
			cancelButtonText: "No",
			customClass: { popup: "swal__popup" }
		}).then(async ({ isConfirmed }) => {
			if (isConfirmed) {
				const storageRef = ref(storage, `${pad}/${file.name}`);
				await deleteObject(storageRef);

				Swal.fire({
					title: "Archivo eliminado!",
					text: "El archivo fue eliminado de la base de datos.",
					icon: "success",
					confirmButtonText: "Ok",
					confirmButtonColor: "#3182ce",
					customClass: { popup: "swal__popup" }
				});

				return true;
			} else return false;
		});

		if (isDeleted) return true;
		else return false;
	} catch (error) {
		console.log("Error code: ", error.code);
		console.log("Error message: ", error.message);
		return false;
	}
};
