import React from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { EditableTable } from "../../ui/EditableTable";
import { useEditableTable } from "../../../../hooks/useEditableTable";
import {
	setDistribucionActiveCell,
	startSaveDistribucionData
} from "../../../../actions/dashboard/sections/operacion/distribucionActions";

export const DistribucionTable = () => {
	const paterns = [
		{
			header: "MODULO",
			name: "id",
			editable: false,
			type: "text"
		},
		{
			header: "OCTUBRE",
			name: "10",
			editable: true,
			type: "number"
		},
		{
			header: "NOVIEMBRE",
			name: "11",
			editable: true,
			type: "number"
		},
		{
			header: "DICIEMBRE",
			name: "12",
			editable: true,
			type: "number"
		},
		{
			header: "ENERO",
			name: "1",
			editable: true,
			type: "number"
		},
		{
			header: "FEBRERO",
			name: "2",
			editable: true,
			type: "number"
		},
		{
			header: "MARZO",
			name: "3",
			editable: true,
			type: "number"
		},
		{
			header: "ABRIL",
			name: "4",
			editable: true,
			type: "number"
		},
		{
			header: "MAYO",
			name: "5",
			editable: true,
			type: "number"
		},
		{
			header: "JUNIO",
			name: "6",
			editable: true,
			type: "number"
		},
		{
			header: "JULIO",
			name: "7",
			editable: true,
			type: "number"
		},
		{
			header: "AGOSTO",
			name: "8",
			editable: true,
			type: "number"
		},
		{
			header: "SEPTIEMBRE",
			name: "9",
			editable: true,
			type: "number"
		},
		{
			header: "TOTAL",
			name: "total",
			editable: false,
			type: "number"
		}
	];

	const groupers = () => {
		return (
			<tr>
				<th className="editableTable__grouper"></th>
				<th colSpan="12" className="editableTable__grouper"></th>
				<th className="editableTable__grouper"></th>
			</tr>
		);
	};

	const operations = [["total", "10,+,11,+,12,+,1,+,2,+,3,+,4,+,5,+,6,+,7,+,8,+,9"]];

	const { data, distribucionActiveCell } = useSelector((state) => state.distribucion);
	const [dataTable, handleInputChange, setTable] = useEditableTable(data, operations);

	useEffect(() => {
		setTable(data);
	}, [setTable, data]);

	const dispatch = useDispatch();

	const handleSaveChanges = () => {
		dispatch(startSaveDistribucionData(dataTable));
	};

	return data.length > 0 ? (
		<EditableTable
			title="Distribución"
			initialData={data}
			dataState={dataTable}
			setActiveCell={setDistribucionActiveCell}
			activeCell={distribucionActiveCell}
			handler={handleInputChange}
			paterns={paterns}
			groupers={groupers}
			handleSaveChanges={handleSaveChanges}
		/>
	) : (
		<></>
	);
};
