import React from "react";

export const Selector = ({ title, options, activeOption, setter }) => {
	return (
		<div className="selector">
			<div className="selector__section">
				<div className="selector__sectionTitle">{title}</div>

				<div className="selector__options">
					{options.map((option) => (
						<div
							key={option}
							onClick={() => setter(option)}
							className={`selector__option ${
								activeOption === option && "selector__option--selected"
							}`}
						>
							{option}
						</div>
					))}
				</div>
			</div>
		</div>
	);
};
