import { firebaseUpdatePassword } from "../firebaseConfig";
import Swal from "sweetalert2";

export const updateFirebasePassword = async (password) => {
	try {
		await firebaseUpdatePassword(password);

		Swal.fire({
			title: "Contraseña actualizada.!",
			text: "Se guardó con éxito la nueva contraseña..",
			icon: "success",
			confirmButtonText: "Ok",
			confirmButtonColor: "#3182ce",
			customClass: { popup: "swal__popup" }
		});

		return true;
	} catch (error) {
		console.log("Error code: ", error.code);
		console.log("Error message: ", error.message);
		Swal.fire({
			title: "Error!",
			text: "Error al intentar actualizar la contraseña.",
			icon: "error",
			confirmButtonText: "Ok",
			confirmButtonColor: "#3182ce",
			customClass: { popup: "swal__popup" }
		});
		return false;
	}
};
