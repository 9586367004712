import React from "react";
import { MdUpload } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { startSetUserImage } from "../../../../actions/auth/authActions";

export const ImageForm = () => {
	const { uid } = useSelector((state) => state.auth);
	const dispatch = useDispatch();

	const handleSetUserImage = async (e) => {
		dispatch(startSetUserImage(uid, e.target.files));
	};

	return (
		<form className="form">
			<div className="form__inputGroup">
				<label htmlFor="fileInput">
					<div className="form__filesInput">
						<MdUpload className="form__buttonIcon" /> Imagen
					</div>
				</label>
				<input
					id="fileInput"
					type="file"
					className="filesInput"
					name="fileInput"
					onChange={handleSetUserImage}
				/>

				{/* {isMobile && (
						<div>
							<label htmlFor="cameraInput">
								<div className="form__cameraInput">
									<MdPhotoCamera className="form__buttonIcon" />
								</div>
							</label>

							<input
								id="cameraInput"
								type="file"
								multiple="multiple"
								capture="camera"
								className="cameraInput"
								name="fileInput"
								value={fileInput.files}
								onChange={handleInputChange}
							/>
						</div>
					)} */}
			</div>
		</form>
	);
};
