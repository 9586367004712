import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { useSelector } from "react-redux";

export const ComprasChart = () => {
	const { compras } = useSelector((state) => state.compras);

	let usoAgricola = 0;
	let organismoOperador = 0;
	let organismoOperadorMA = 0;

	compras.forEach((compra) => {
		const volumen =
			typeof compra.volumen === "number"
				? compra.volumen
				: Number(compra.volumen.replace(",", () => ""));

		if (compra.concepto === "USO AGRICOLA") {
			usoAgricola += volumen;
		}

		if (compra.concepto === "ORGANISMO OPERADOR") {
			organismoOperador += volumen;
		}

		if (compra.concepto === "ORGANISMO OPERADOR MA") {
			organismoOperadorMA += volumen;
		}
	});

	ChartJS.register(ArcElement, Tooltip, Legend);
	const options = {
		responsive: true,
		plugins: {
			legend: {
				position: "top",
			},
			title: {
				display: true,
				text: "Operación (Mm3)",
			},
		},
	};

	const data = {
		labels: ["Uso Agricola", "Organismo Operador", "Organismo Operador Ma"],
		datasets: [
			{
				label: "Volumen Comprado (Mm3)",
				data: [usoAgricola, organismoOperador, organismoOperadorMA],
				backgroundColor: [
					// "rgba(54, 162, 235, 0.5)",
					"rgba(49, 130, 206, 0.5)",
					"rgba(75, 192, 192, 0.5)",
					"rgba(45, 55, 72, 0.5)",
				],
			},
		],
	};
	return <Doughnut options={options} data={data} className="comprasChart" />;
};
