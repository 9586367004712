import React from "react";
import { useDispatch } from "react-redux";
import { MdKeyboardArrowRight, MdLogout } from "react-icons/md";

import { NavMenu } from "./NavMenu";
import { UserCard } from "./UserCard";
import { startLogout } from "../../../actions/auth/authActions";
import { useSelector } from "react-redux";
import { setSidebarState } from "../../../actions/dashboard/dashboardActions";

export const DashboardSidebar = () => {
	const dispatch = useDispatch();
	const { sidebarExpanded } = useSelector((state) => state.dashboard);

	const handleSetSidebarState = () => {
		dispatch(setSidebarState(!sidebarExpanded));
	};

	return (
		<div
			className={`dashboardSidebar ${
				sidebarExpanded ? "dashboardSidebar--expanded" : "dashboardSidebar--collapsed"
			}`}
		>
			<div className="dashboardSidebar__expander">
				<MdKeyboardArrowRight
					onClick={handleSetSidebarState}
					className={`dashboardSidebar__expanderIcon ${
						sidebarExpanded
							? "dashboardSidebar__expanderIcon--expanded"
							: "dashboardSidebar__expanderIcon--collapsed"
					}`}
				/>
			</div>

			<UserCard isExpanded={sidebarExpanded} />

			<NavMenu onClickFunction={() => {}} isExpanded={sidebarExpanded} />

			<div className="dashboardSidebar__exit">
				<button className="dashboardSidebar__exitButton" onClick={() => dispatch(startLogout())}>
					<MdLogout className="dashboardSidebar__exitIcon" />

					<div
						className={`dashboardSidebar__exitLabel ${
							sidebarExpanded
								? "dashboardSidebar__exitLabel--expanded"
								: "dashboardSidebar__exitLabel--collapsed"
						}`}
					>
						Salir
					</div>
				</button>
			</div>
		</div>
	);
};
