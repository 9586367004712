import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdClose, MdSave, MdLogout } from "react-icons/md";

import { useFormToUpper } from "../../../../hooks/UseFormToUpper";
import {
	changeContactFormState,
	closeEntidadForm,
	startDeleteEntidad,
	startSaveEntidad
} from "../../../../actions/dashboard/sections/settings/entidadesActions";
import { objectsDeepEqual } from "../../../../helpers/objectsDeepEqual";
import { uiRemoveError, uiSetError } from "../../../../actions/uiActions";

export const EntidadForm = ({ entidad }) => {
	const { edicion } = useSelector((state) => state.auth);

	const { msgError, loading } = useSelector((state) => state.ui);
	const { entidades } = useSelector((state) => state.entidades);

	const dispatch = useDispatch();

	const [values, handleInputChange, , , setValues] = useFormToUpper(entidad);
	const { id, nombre, saldoInicial, isModulo, supFisica, supDerecho } = values;

	const thereAreChanges = !objectsDeepEqual(entidad, values);

	const handleSaveEntidad = (e) => {
		e.preventDefault();
		if (isFormValid()) {
			dispatch(startSaveEntidad(values));
		}
	};

	const handleDeleteEntidad = (e) => {
		e.preventDefault();
		dispatch(startDeleteEntidad(entidad.id));
	};

	const closeForm = (e) => {
		e.preventDefault();

		dispatch(closeEntidadForm());
		dispatch(uiRemoveError());
	};

	const handleOpenContactForm = (e) => {
		e.preventDefault();
		dispatch(changeContactFormState());
	};

	const isFormValid = () => {
		if (id.trim().length === 0) {
			dispatch(uiSetError("Ingrese id único."));
			return false;
		} else if (entidad.id.length === 0 && entidades.find((entidad) => entidad.id === id)) {
			dispatch(uiSetError("Ya existe una entidad con el mismo id"));
			return false;
		} else if (nombre.trim().length === 0) {
			dispatch(uiSetError("Ingrese nombre de la entidad."));
			return false;
		} else if (supFisica < 0) {
			dispatch(uiSetError("La superficie física no puede ser negativa."));
			return false;
		} else if (supDerecho < 0) {
			dispatch(uiSetError("La superficie con derecho no puede ser negativa."));
			return false;
		}

		dispatch(uiRemoveError());
		return true;
	};

	return (
		<div className="detailForm">
			{entidad.id.length === 0 ? (
				<div className="detailForm__title">Nueva entidad</div>
			) : (
				<div className="detailForm__title">Entidad: {entidad.id}</div>
			)}

			<form className="form">
				{entidad.id.length === 0 && (
					<div className="form__inputGroup">
						<label htmlFor="id" className="form__label">
							Id:
						</label>
						<input
							id="id"
							type="text"
							className="form__input"
							placeholder="Identificador único"
							name="id"
							value={id}
							onChange={handleInputChange}
						/>
					</div>
				)}

				<div className="form__inputGroup">
					<label htmlFor="nombre" className="form__label">
						Nombre de la Entidad:
					</label>
					<input
						id="nombre"
						type="text"
						className="form__input"
						placeholder="Nombre de la entidad"
						autoComplete="off"
						name="nombre"
						value={nombre}
						onChange={handleInputChange}
					/>
				</div>

				<div className="form__inputGroup">
					<label htmlFor="saldoInicial" className="form__label">
						Saldo Inicial:
					</label>
					<input
						id="saldoInicial"
						type="number"
						className="form__input"
						placeholder="Saldo inicial"
						autoComplete="off"
						name="saldoInicial"
						value={saldoInicial}
						onChange={handleInputChange}
					/>
				</div>

				<div className="form__inputGroup">
					<label htmlFor="isModulo" className="form__label">
						¿La entidad es un módulo de riego?
					</label>

					<div className="selector__options" id="isModulo">
						{["Si", "No"].map((option) => {
							const isModuloOption = option === "Si" ? true : false;
							return (
								<div
									key={option}
									onClick={() => setValues({ ...values, isModulo: isModuloOption })}
									className={`selector__option ${
										isModuloOption === isModulo && "selector__option--selected"
									}`}
								>
									{option}
								</div>
							);
						})}
					</div>
				</div>

				{isModulo && (
					<>
						<div className="form__inputGroup">
							<label htmlFor="supFisica" className="form__label">
								Superficie física:
							</label>
							<input
								id="supFisica"
								type="number"
								className="form__input"
								placeholder="Superficie física"
								autoComplete="off"
								name="supFisica"
								value={supFisica}
								onChange={handleInputChange}
							/>
						</div>

						<div className="form__inputGroup">
							<label htmlFor="supDerecho" className="form__label">
								Superficie con derecho:
							</label>
							<input
								id="supDerecho"
								type="number"
								className="form__input"
								placeholder="Superficie con derecho"
								autoComplete="off"
								name="supDerecho"
								value={supDerecho}
								onChange={handleInputChange}
							/>
						</div>
					</>
				)}

				{msgError && <div className="form__error">{msgError}</div>}

				<div className="detailForm__buttonsGrid">
					{edicion && (
						<button
							className="form__button detailForm__addContactButton"
							value="Buscar"
							onClick={handleOpenContactForm}
						>
							Añadir contacto
						</button>
					)}

					{(edicion && entidad.id.length) > 0 && (
						<button
							className="form__button detailForm__deleteButton"
							value="Buscar"
							onClick={handleDeleteEntidad}
						>
							<MdClose className="detailForm__icon" />
							Borrar
						</button>
					)}

					<button
						className="form__button detailForm__closeButton"
						value="Buscar"
						onClick={closeForm}
					>
						<MdLogout className="detailForm__icon detailForm__icon--mirror" />
						Cerrar
					</button>

					{edicion && thereAreChanges && (
						<button
							className="form__button detailForm__saveButton"
							value="Buscar"
							onClick={handleSaveEntidad}
						>
							<MdSave className="detailForm__icon" />
							Guardar {loading && <div className="form__spinner"></div>}
						</button>
					)}
				</div>
			</form>
		</div>
	);
};
