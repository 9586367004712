import React from "react";
import { MdAddCircle } from "react-icons/md";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
	addEntidad,
	selectEntidad
} from "../../../../actions/dashboard/sections/settings/entidadesActions";

import { entidadesColumns } from "../../../../settings/tablesSettings";
import { CustomTable } from "../../ui/CustomTable";

export const EntidadesTable = () => {
	const { edicion } = useSelector((state) => state.auth);
	const { entidades, entidadSelected } = useSelector((state) => state.entidades);

	const dispatch = useDispatch();

	const selectRow = (row) => {
		dispatch(selectEntidad(row));
	};

	const addNewEntidad = () => {
		dispatch(addEntidad());
	};

	return (
		<div className="collectionTable">
			<CustomTable
				title="Entidades"
				columns={entidadesColumns}
				data={entidades}
				selectRowId={entidadSelected ? entidadSelected.id : ""}
				onRowClickedAction={selectRow}
			/>

			{edicion && (
				<div className="collectionTable__addButton" onClick={addNewEntidad}>
					<MdAddCircle />
				</div>
			)}
		</div>
	);
};
