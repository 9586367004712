import { deleteDocOnFirestore } from "../../../../firebase/crudFirestoreActions/deleteDocOnFirestore";
import { loadCollectionFromFirestore } from "../../../../firebase/crudFirestoreActions/loadCollectionFromFirestor";
import { saveDocOnFirestore } from "../../../../firebase/crudFirestoreActions/saveDocOnFirestore";
import { types } from "../../../../types/types";
import { uiFinishLoading, uiStartLoading } from "../../../uiActions";

export const startLoadContactos = () => {
	return async (dispatch) => {
		const contactos = await loadCollectionFromFirestore(`contactos`);
		dispatch(setContactos(contactos));
	};
};

export const setContactos = (contactos) => ({
	type: types.setContactos,
	payload: contactos
});

export const selectContacto = (contacto) => ({
	type: types.selectContacto,
	payload: contacto
});

export const addContacto = () => ({
	type: types.addContacto
});

export const closeContactoForm = () => ({
	type: types.closeContactoForm
});

export const startSaveContacto = (contacto) => {
	return async (dispatch, getState) => {
		dispatch(uiStartLoading());

		const today = new Date();

		if (!contacto.id) contacto.id = today.getTime().toString().substring(5);

		const isDocSave = await saveDocOnFirestore(`contactos`, { ...contacto }, contacto.id);

		if (isDocSave) {
			dispatch(saveContacto({ ...contacto }));
			dispatch(uiFinishLoading());
		}
	};
};

export const saveContacto = (contacto) => ({
	type: types.saveContacto,
	payload: contacto
});

export const startDeleteContacto = (contacto) => {
	return async (dispatch) => {
		const isDeleted = await deleteDocOnFirestore(`contactos`, contacto.id);

		if (isDeleted) {
			dispatch(deleteContacto(contacto.id));
		}
	};
};

export const deleteContacto = (contactoId) => ({
	type: types.deleteContacto,
	payload: contactoId
});
