import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { startLoginWithEmailPassword } from "../../../../actions/auth/authActions";
import { useForm } from "../../../../hooks/useForm";

export const LoginForm = () => {
	const { msgError, loading } = useSelector((state) => state.ui);

	const dispatch = useDispatch();

	const [values, handleInputChange] = useForm({
		email: "",
		password: ""
	});
	const { email, password } = values;

	const startLogin = (e) => {
		e.preventDefault();

		dispatch(startLoginWithEmailPassword(email, password));
	};

	return (
		<div className="authForm">
			<h2 className="authForm__heading text-center">Inicio de sesión</h2>

			<form className="form">
				<div className="form__inputGroup">
					<label htmlFor="email" className="form__label">
						Email:
					</label>
					<input
						id="email"
						type="email"
						className="form__input"
						placeholder="Tu email"
						name="email"
						value={email}
						onChange={handleInputChange}
					/>
				</div>

				<div className="form__inputGroup">
					<label htmlFor="password" className="form__label">
						Contraseña:
					</label>
					<input
						id="password"
						type="password"
						className="form__input"
						placeholder="Tu contraseña"
						autoComplete="off"
						name="password"
						value={password}
						onChange={handleInputChange}
					/>
				</div>

				{msgError && <div className="form__error">{msgError}</div>}

				<button className="form__button" value="Buscar" onClick={startLogin}>
					Iniciar Sesión {loading && <div className="form__spinner"></div>}
				</button>
			</form>

			<p className="authForm__footer text-center">
				No tienes cuenta?{" "}
				<Link to="/auth/register" className="authForm__link">
					Registro
				</Link>
			</p>
		</div>
	);
};
