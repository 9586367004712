import React from "react";

export const AuthFooter = () => {
	return (
		<div className="authFooter">
			<p className="authFooter__text text-center">
				<span>MARMOL</span> system and computer solution. Todos los derechos reservados.
			</p>
		</div>
	);
};
