import { getFormattedNumberString, roundToN } from "../../../helpers/mathHelpers";
import { getTotalRow } from "../../../helpers/tableHelpers";

export const ReportTable = ({ patterns, data }) => {
	const totalRow = getTotalRow(patterns, data);

	return (
		<div className="tableContainer">
			<table className="reportTable">
				<thead>
					<tr className="reportTable__header">
						{patterns.map((pattern, i) => (
							<th key={`${i}header-${pattern.header}`} className="reportTable__headerCell">
								{pattern.header}
							</th>
						))}
					</tr>
				</thead>

				<tbody>
					{data.map((row, i) => (
						<tr key={`tableRow-${i}`}>
							{patterns.map((pattern) => (
								<td
									key={`${pattern.id}-${i}`}
									style={{ textAlign: pattern.textAlign, width: pattern.width }}
									className="reportTable__tableCell"
								>
									{pattern.type === "number"
										? getFormattedNumberString(roundToN(row[pattern.id], 3))
										: row[pattern.id]}
								</td>
							))}
						</tr>
					))}
				</tbody>

				<tfoot>
					<tr>
						{patterns.map((pattern) => (
							<td
								key={`${pattern.id}-total`}
								style={{ textAlign: pattern.textAlign, width: pattern.width }}
								className="reportTable__footerCell"
							>
								{pattern.type === "number"
									? getFormattedNumberString(roundToN(totalRow[pattern.id], 3))
									: totalRow[pattern.id]}
							</td>
						))}
					</tr>
				</tfoot>
			</table>
		</div>
	);
};

// const getTotalRow = (patterns, data) => {
// 	const row = {};
// 	patterns.forEach((pattern) => {
// 		row[pattern.id] = pattern.type === "number" ? 0 : "";
// 	});

// 	data.forEach((dataRow) => {
// 		patterns.forEach((pattern) => {
// 			pattern.type === "number" && (row[pattern.id] += dataRow[pattern.id]);
// 		});
// 	});

// 	row.id = "TOTAL";

// 	return row;
// };
