import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
	setTipoTabla,
	setUnidades
} from "../../../../actions/dashboard/sections/transacciones/transferenciasActions";

export const TransferenciasSelector = () => {
	const dispatch = useDispatch();

	const tablesOptions = ["Salientes", "Entrantes", "Balace"];
	const { tipoTabla } = useSelector((state) => state.transferencias);
	const handleSetTipoTabla = (option) => {
		dispatch(setTipoTabla(option));
	};

	const unidadesOptions = ["Mm3", "Ha"];
	const { unidad } = useSelector((state) => state.transferencias);
	const handleSetUnidades = (option) => {
		dispatch(setUnidades(option));
	};

	return (
		<div className="selector">
			<div className="selector__section">
				<div className="selector__sectionTitle">Tabla:</div>

				<div className="selector__options">
					{tablesOptions.map((option) => (
						<div
							key={option}
							onClick={() => handleSetTipoTabla(option)}
							className={`selector__option ${tipoTabla === option && "selector__option--selected"}`}
						>
							{option}
						</div>
					))}
				</div>
			</div>

			<div className="selector__section">
				<div className="selector__sectionTitle">Unidades:</div>

				<div className="selector__options">
					{unidadesOptions.map((option) => (
						<div
							key={option}
							onClick={() => handleSetUnidades(option)}
							className={`selector__option ${unidad === option && "selector__option--selected"}`}
						>
							{option}
						</div>
					))}
				</div>
			</div>
		</div>
	);
};
