import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Report } from "../../ui/Report";
import { getAccountStatementWaterData } from "./reportsHelpers/getAccountStatementWaterData";
import { getAccountStatementItems } from "./reportsHelpers/getAccountStatementItems";
import { getTotalRow } from "../../../../helpers/tableHelpers";

export const EstadoDeCuenta = ({ entitie }) => {
	const { currentCycle } = useSelector((state) => state.dashboard);
	const { displayName } = useSelector((state) => state.auth);
	const { activeCut } = useSelector((state) => state.reportes);
	const { cuotas } = useSelector((state) => state.cuotas);

	const { entidades } = useSelector((state) => state.entidades);

	const entitieData = entidades.find((entitieData) => entitieData.id === entitie);
	const {
		asignacion,
		distribucion,
		ofertas,
		compras,
		ingresos,
		pagos,
		cargos,
		traspasos,
		transferencias,
	} = useSelector((state) => state);

	const waterData = useMemo(
		() => ({
			asignacion,
			distribucion,
			ofertas,
			compras,
			ingresos,
			pagos,
			cargos,
			traspasos,
			transferencias,
		}),
		[asignacion, distribucion, ofertas, compras, ingresos, pagos, cargos, traspasos, transferencias]
	);

	const waterFilteredData = useMemo(
		() => getAccountStatementWaterData(waterData, entitie),
		[waterData, entitie]
	);

	const items = getAccountStatementItems(
		waterFilteredData,
		activeCut,
		cuotas,
		entitieData.saldoInicial
	);

	const downloadableSheets = {
		sheets: [
			{
				name: "VOLUMEN EN MM3",
				headers: items[1].patterns.map((header) => header.id),
				data: getDownloadableTable(items[1].patterns, items[1].data),
			},
			{
				name: "ESTADO FINANCIERO",
				headers: items[3].patterns.map((header) => header.id),
				data: getDownloadableTable(items[3].patterns, items[3].data),
			},
		],
		title: `ESTADO DE CUENTA`,
		author: displayName,
	};

	const action = {
		action: () => console.log("Guardando saldo final"),
		label: "Guardar Saldo",
	};

	return (
		<Report
			title={`ESTADO DE CUENTA DEL MÓDULO ${entitie}, CICLO ${currentCycle} (CORTE: ${activeCut})`}
			items={items}
			downloadableSheets={downloadableSheets}
			action={action}
		/>
	);
};

const getDownloadableTable = (patterns, data) => {
	const table = [...data];
	table.push(getTotalRow(patterns, data));
	return table;
};
