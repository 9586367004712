import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setActiveTransaccionesReport } from "../../../../actions/dashboard/sections/transacciones/reportesActions";
import { Selector } from "../../ui/Selector";
import { OfertasReport } from "./OfertasReport";
import { TransferenciasReportSelector } from "./TransferenciasReportSelector";
import { TraspasosReport } from "./TraspasosReport";
import { ComprasReport } from "./ComprasReport";

export const TransaccionesReports = () => {
	const { transaccionesReports, activeTransaccionesReport } = useSelector(
		(state) => state.reportes
	);

	const dispatch = useDispatch();

	const handleSetReport = (option) => {
		dispatch(setActiveTransaccionesReport(option));
	};

	const reportType = {
		Ofertas: <OfertasReport />,
		Compras: <ComprasReport />,
		Traspasos: <TraspasosReport />,
		Transferencias: <TransferenciasReportSelector />,
	};

	return (
		<>
			<Selector
				title={"Reportes de transacciones:"}
				options={transaccionesReports}
				activeOption={activeTransaccionesReport}
				setter={handleSetReport}
			/>

			{reportType[activeTransaccionesReport]}
		</>
	);
};
