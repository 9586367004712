import React from "react";

export const ReportHeader = () => {
	const today = new Date();
	const options = { weekday: "long", year: "numeric", month: "long", day: "numeric" };

	return (
		<div className="reportHeader">
			<div className="reportHeader__logo">
				BancoDe<span>AGUA</span>
			</div>

			<div className="reportHeader__date">
				<div>Mexicali, Baja California</div>
				<div>{today.toLocaleString("es-MX", options)}</div>
			</div>
		</div>
	);
};
