import { getTransferencias } from "../../../../firebase/sicperCrudFirestoreActions/getTransferencias";
import { factores, modulos, months } from "../../../../helpers/constants";
import { types } from "../../../../types/types";

export const startLoadTransferencias = (ciclo) => {
	return async (dispatch) => {
		const row = {};
		months.forEach((month) => {
			row[month] = 0;
		});
		const table = modulos.map((modulo) => ({
			id: modulo,
			...row,
			total: 0
		}));

		const transferencias = await getTransferencias(ciclo);

		const transferenciasSalientes = table.map((row) => ({ ...row }));
		const transferenciasEntrantes = table.map((row) => ({ ...row }));
		const volumenTransferenciasSalientes = table.map((row) => ({ ...row }));
		const volumenTransferenciasEntrantes = table.map((row) => ({ ...row }));

		transferencias.forEach((transferencia) => {
			const factor = factores[transferencia.origen];

			const origenIndex = transferenciasSalientes.findIndex(
				(row) => row.id === transferencia.origen
			);
			transferenciasSalientes[origenIndex][transferencia.mes] += transferencia.ha;
			transferenciasSalientes[origenIndex].total += transferencia.ha;
			volumenTransferenciasSalientes[origenIndex][transferencia.mes] += transferencia.ha * factor;
			volumenTransferenciasSalientes[origenIndex].total += transferencia.ha * factor;

			const destinoIndex = transferenciasEntrantes.findIndex(
				(row) => row.id === transferencia.destino
			);
			transferenciasEntrantes[destinoIndex][transferencia.mes] += transferencia.ha;
			transferenciasEntrantes[destinoIndex].total += transferencia.ha;
			volumenTransferenciasEntrantes[destinoIndex][transferencia.mes] += transferencia.ha * factor;
			volumenTransferenciasEntrantes[destinoIndex].total += transferencia.ha * factor;
		});

		const balances = transferenciasSalientes.map((salienteRow) => {
			const entranteRow = transferenciasEntrantes.find(
				(entrante) => entrante.id === salienteRow.id
			);

			const keys = Object.keys(salienteRow);
			const balanceRow = {};

			keys.forEach((key) => {
				balanceRow[key] = entranteRow[key] - salienteRow[key];
			});
			balanceRow.id = salienteRow.id;

			return balanceRow;
		});

		const roundedVolumenTransferenciasSalientes = volumenTransferenciasSalientes.map((modulo) => {
			const newModulo = {};
			let total = 0;
			months.forEach((month) => {
				newModulo[month] = modulo[month];
				total += modulo[month];
			});
			newModulo.total = total;
			newModulo.id = modulo.id;

			return newModulo;
		});

		const roundedVolumenTransferenciasEntrantes = volumenTransferenciasEntrantes.map((modulo) => {
			const newModulo = {};
			let total = 0;
			months.forEach((month) => {
				newModulo[month] = modulo[month];
				total += modulo[month];
			});
			newModulo.total = total;

			newModulo.id = modulo.id;
			return newModulo;
		});

		const balancesVolumenes = roundedVolumenTransferenciasSalientes.map((salienteRow) => {
			const entranteRow = roundedVolumenTransferenciasEntrantes.find(
				(entrante) => entrante.id === salienteRow.id
			);

			const keys = Object.keys(salienteRow);
			const balanceRow = {};

			keys.forEach((key) => {
				balanceRow[key] = entranteRow[key] - salienteRow[key];
			});

			balanceRow.id = salienteRow.id;

			return balanceRow;
		});

		// console.table(transferenciasSalientes);
		// console.table(transferenciasEntrantes);
		// console.table(balances);
		// console.table(roundedVolumenTransferenciasSalientes);
		// console.table(roundedVolumenTransferenciasEntrantes);
		// console.table(balancesVolumenes);

		dispatch(setTransferenciasEntrantes(transferenciasEntrantes));
		dispatch(setTransferenciasSalientes(transferenciasSalientes));
		dispatch(setBalancesTransferencias(balances));
		dispatch(setVolumenTransferenciasSalientes(roundedVolumenTransferenciasSalientes));
		dispatch(setVolumenTransferenciasEntrantes(roundedVolumenTransferenciasEntrantes));
		dispatch(setVolumenBalancesTransferencias(balancesVolumenes));
	};
};

export const setTransferenciasSalientes = (transferencias) => ({
	type: types.setTransferenciasSalientes,
	payload: transferencias
});

export const setTransferenciasEntrantes = (transferencias) => ({
	type: types.setTransferenciasEntrantes,
	payload: transferencias
});

export const setBalancesTransferencias = (balances) => ({
	type: types.setBalancesTransferencias,
	payload: balances
});

export const setVolumenTransferenciasSalientes = (transferencias) => ({
	type: types.setVolumenTransferenciasSalientes,
	payload: transferencias
});

export const setVolumenTransferenciasEntrantes = (transferencias) => ({
	type: types.setVolumenTransferenciasEntrantes,
	payload: transferencias
});

export const setVolumenBalancesTransferencias = (balances) => ({
	type: types.setVolumenBalancesTransferencias,
	payload: balances
});

export const setTipoTabla = (tipoTabla) => ({
	type: types.setTipoTabla,
	payload: tipoTabla
});

export const setUnidades = (unidades) => ({
	type: types.setUnidades,
	payload: unidades
});
