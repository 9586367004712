export const objectsDeepEqual = (object1, object2) => {
	const keys1 = Object.keys(object1);
	const keys2 = Object.keys(object2);

	if (keys1.length !== keys2.length) {
		return false;
	}

	for (const key of keys1) {
		const val1 = object1[key];
		const val2 = object2[key];
		const areObjects = isObject(val1) && isObject(val2);
		if ((areObjects && !objectsDeepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
			return false;
		}
	}

	return true;
};

const isObject = (object) => {
	return object != null && typeof object === "object";
};

export const arraysObjectsDeepEqual = (array1, array2) => {
	if (array1.length !== array2.length) return false;

	for (let i = 0; i < array1.length; i++) {
		const rowsAreEquals = objectsDeepEqual(array1[i], array2[i]);
		if (rowsAreEquals === false) return false;
	}

	return true;
};
