import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebaseConfig";

export const loadCollectionFromFirestore = async (collectionName) => {
	try {
		const documents = [];
		const querySnapshot = await getDocs(collection(db, collectionName));
		querySnapshot.forEach((doc) => {
			documents.push(doc.data());
		});

		return documents;
	} catch (error) {
		console.log("Error code: ", error.code);
		console.log("Error message: ", error.message);
	}
};
