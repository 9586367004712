import { deleteDocOnFirestore } from "../../../../firebase/crudFirestoreActions/deleteDocOnFirestore";
import { deleteFilesOnFirebase } from "../../../../firebase/crudFirestoreActions/deleteFilesOnFirebase";
import { deleteSingleFileOnFirebase } from "../../../../firebase/crudFirestoreActions/deleteSingleFileOnFirebase";
import { loadCollectionFromFirestore } from "../../../../firebase/crudFirestoreActions/loadCollectionFromFirestor";
import { saveDocOnFirestore } from "../../../../firebase/crudFirestoreActions/saveDocOnFirestore";
import { saveFilesOnFirebase } from "../../../../firebase/crudFirestoreActions/saveFilesOnFirebase";
import { updateDocInFirestore } from "../../../../firebase/crudFirestoreActions/updateDocInFirestore";
import { getFormattedNumberString, roundToN } from "../../../../helpers/mathHelpers";
import { types } from "../../../../types/types";
import { uiFinishLoading, uiStartLoading } from "../../../uiActions";

export const startLoadOfertas = (cycle) => {
	return async (dispatch) => {
		const ofertas = await loadCollectionFromFirestore(`ciclos/${cycle}/ofertas`);

		let totalOfertas = 0;
		const totales = {
			usoAgricola: 0,
			organismoOperador: 0,
			organismoOperadorMA: 0,
		};

		ofertas.forEach((oferta) => {
			totales.usoAgricola = totales.usoAgricola + oferta.usoAgricola;
			totales.organismoOperador = totales.organismoOperador + oferta.organismoOperador;
			totales.organismoOperadorMA = totales.organismoOperadorMA + oferta.organismoOperadorMA;

			totalOfertas += oferta.volumenTotal;
			oferta.volumenTotal = getFormattedNumberString(roundToN(oferta.volumenTotal, 3));
		});

		dispatch(setTotalesOfertas(totales));
		dispatch(setTotalOfertas(totalOfertas));
		dispatch(setOfertas(ofertas));
	};
};

export const setOfertas = (ofertas) => ({
	type: types.setOfertas,
	payload: ofertas,
});

export const setTotalOfertas = (total) => ({
	type: types.setTotalOfertas,
	payload: total,
});

export const setTotalesOfertas = (totales) => ({
	type: types.setTotalesOfertas,
	payload: totales,
});

export const selectOferta = (oferta) => ({
	type: types.selectOferta,
	payload: oferta,
});

export const addOferta = () => ({
	type: types.addOferta,
});

export const closeOfertaForm = () => ({
	type: types.closeOfertaForm,
});

export const startSaveOferta = (oferta, cycle) => {
	return async (dispatch, getState) => {
		const state = getState();

		dispatch(uiStartLoading());

		const today = new Date();
		oferta.volumenTotal =
			Number(oferta.usoAgricola) +
			Number(oferta.organismoOperadorMA) +
			Number(oferta.organismoOperador);
		if (!oferta.id) oferta.id = today.getTime().toString().substring(5);

		const filesRefs = await saveFilesOnFirebase(
			oferta.id,
			oferta.filesToUpload,
			state.ofertas.ofertaSelected.filesInFirebase,
			`${cycle}/ofertas`
		);

		oferta.filesInFirebase = [...oferta.filesInFirebase, ...filesRefs];
		oferta.filesToUpload = [];

		const isDocSave = await saveDocOnFirestore(`ciclos/${cycle}/ofertas`, { ...oferta }, oferta.id);

		const ofertasAnteriores = state.ofertas.ofertas.filter(
			(ofertaAnterior) => ofertaAnterior.id !== oferta.id
		);
		const totales = {
			usoAgricola: 0,
			organismoOperador: 0,
			organismoOperadorMA: 0,
		};

		ofertasAnteriores.forEach((oferta) => {
			totales.usoAgricola = totales.usoAgricola + oferta.usoAgricola;
			totales.organismoOperador = totales.organismoOperador + oferta.organismoOperador;
			totales.organismoOperadorMA = totales.organismoOperadorMA + oferta.organismoOperadorMA;
		});

		totales.usoAgricola = totales.usoAgricola + oferta.usoAgricola;
		totales.organismoOperador = totales.organismoOperador + oferta.organismoOperador;
		totales.organismoOperadorMA = totales.organismoOperadorMA + oferta.organismoOperadorMA;

		if (filesRefs && isDocSave) {
			dispatch(setTotalOfertas(state.ofertas.totalOfertas + oferta.volumenTotal));
			oferta.volumenTotal = getFormattedNumberString(roundToN(oferta.volumenTotal, 3));
			dispatch(saveOferta({ ...oferta }));
			dispatch(setTotalesOfertas(totales));
			dispatch(uiFinishLoading());
		}
	};
};

export const saveOferta = (oferta) => ({
	type: types.saveOferta,
	payload: oferta,
});

export const startDeleteOferta = (oferta, cycle) => {
	return async (dispatch, getState) => {
		const state = getState();

		const newTotales = { ...state.ofertas.totales };
		newTotales.usoAgricola = newTotales.usoAgricola - oferta.usoAgricola;
		newTotales.organismoOperador = newTotales.organismoOperador - oferta.organismoOperador;
		newTotales.organismoOperadorMA = newTotales.organismoOperadorMA - oferta.organismoOperadorMA;

		const isDeleted = await deleteDocOnFirestore(`ciclos/${cycle}/ofertas`, oferta.id);
		const areFilesDeleted = await deleteFilesOnFirebase(`${cycle}/ofertas`, oferta.filesInFirebase);
		if (isDeleted && areFilesDeleted) {
			dispatch(deleteOferta(oferta.id));
			dispatch(setTotalesOfertas(newTotales));
			dispatch(
				setTotalOfertas(
					state.ofertas.totalOfertas - Number(oferta.volumenTotal.replace(",", () => ""))
				)
			);
		}
	};
};

export const deleteOferta = (ofertaId) => ({
	type: types.deleteOferta,
	payload: ofertaId,
});

export const startDeleteSingleOfertaFile = (cycle, oferta, file) => {
	return async (dispatch) => {
		const isDeleted = await deleteSingleFileOnFirebase(`${cycle}/ofertas`, file);

		if (isDeleted) {
			const files = oferta.filesInFirebase.filter((filterFile) => filterFile.name !== file.name);
			const isUpdates = await updateDocInFirestore(`ciclos/${cycle}/ofertas`, oferta.id, {
				filesInFirebase: files,
			});
			if (isUpdates) dispatch(deleteSingleOfertaFile(oferta.id, file.name));
		}
	};
};

export const deleteSingleOfertaFile = (ofertaId, fileName) => ({
	type: types.deleteSingleOfertaFile,
	payload: { ofertaId, fileName },
});
