import React from "react";
import DataTable from "react-data-table-component";

export const CustomTable = ({ title, columns, data, selectRowId = "", onRowClickedAction }) => {
	const paginationOptions = {
		rowsPerPageText: "Filas por página",
		rangeSeparatorText: "de",
		selectAllRowsItem: true,
		selectAllRowsItemText: "Todos"
	};

	const customStyles = {
		headCells: {
			style: {
				color: "#3182ce",
				fontWeight: 900
			}
		},

		rows: {
			style: {
				minHeight: "3.2rem",
				transition: "background-color 300ms ease",
				"&:hover": {
					borderRadius: "0.5rem",
					backgroundColor: "#d6e6f5",
					cursor: "pointer"
				}
			}
		}
	};

	const conditionalRowStyles = [
		{
			when: (row) => row.id === selectRowId,
			style: {
				color: "#3182ce",
				"&:hover": {
					cursor: "pointer"
				}
			}
		}
	];

	return (
		<DataTable
			title={title}
			columns={columns}
			data={data}
			customStyles={customStyles}
			conditionalRowStyles={conditionalRowStyles}
			pagination
			paginationComponentOptions={paginationOptions}
			onRowClicked={onRowClickedAction}
			noDataComponent="No hay elementos para mostrar."
		/>
	);
};
