import React from "react";
import { MdAddCircle } from "react-icons/md";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
	addCompra,
	selectCompra,
} from "../../../../actions/dashboard/sections/transacciones/comprasActions";
import { getFormattedNumberString, roundToN } from "../../../../helpers/mathHelpers";

import { comprasColumns } from "../../../../settings/tablesSettings";
import { CustomTable } from "../../ui/CustomTable";

export const ComprasTable = () => {
	const { edicion } = useSelector((state) => state.auth);
	const { compras, compraSelected, totalCompras } = useSelector((state) => state.compras);

	const dispatch = useDispatch();

	const selectRow = (row) => {
		dispatch(selectCompra(row));
	};

	const addNewCompra = () => {
		dispatch(addCompra());
	};

	return (
		<div className="collectionTable">
			<CustomTable
				title={`Compras  (${getFormattedNumberString(roundToN(totalCompras, 3))} mm3)`}
				columns={comprasColumns}
				data={compras}
				selectRowId={compraSelected ? compraSelected.id : ""}
				onRowClickedAction={selectRow}
			/>

			{edicion && (
				<div className="collectionTable__addButton" onClick={addNewCompra}>
					<MdAddCircle />
				</div>
			)}
		</div>
	);
};
