import React from "react";
import { NavLink } from "react-router-dom";
import { MdHomeFilled, MdSettings, MdTextSnippet, MdWaterDrop, MdSyncAlt } from "react-icons/md";

export const NavMenu = ({ onClickFunction, isExpanded = true }) => {
	const sections = [
		{
			pad: "/home",
			label: "Inicio",
			icon: MdHomeFilled
		},
		{
			pad: "/operacion",
			label: "Operación",
			icon: MdWaterDrop
		},
		{
			pad: "/transacciones",
			label: "Transacciones",
			icon: MdSyncAlt
		},
		{
			pad: "/reportes",
			label: "Reportes",
			icon: MdTextSnippet
		},
		{
			pad: "/settings",
			label: "Configuración",
			icon: MdSettings
		}
	];

	return (
		<div className="navMenu">
			{sections.map((section) => (
				<NavLink
					key={section.label}
					onClick={() => onClickFunction(false)}
					className={({ isActive }) => "navMenu__link" + (isActive ? " navMenu__active" : "")}
					to={section.pad}
				>
					<div
						className={`navMenu__label ${
							isExpanded ? "navMenu__label--expanded" : "navMenu__label--collapsed"
						}`}
					>
						{section.label}
					</div>
					<section.icon className="navMenu__icon" />
				</NavLink>
			))}
		</div>
	);
};
