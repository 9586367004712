import React from "react";
import { UserCard } from "../../ui/UserCard";
import { ImageForm } from "../settings/ImageForm";
import { NameForm } from "../settings/NameForm";
import { PasswordForm } from "../settings/PasswordForm";

export const ProfileTemplate = () => {
	return (
		<div className="profileTemplate">
			<div className="profileTemplate__imageSection">
				<UserCard isExpanded={true} large={true} />
				<ImageForm />
			</div>

			<div className="profileTemplate__namePassSection">
				<NameForm />
				<PasswordForm />
			</div>
		</div>
	);
};
