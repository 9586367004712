import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	setActiveCut,
	setActiveEntitie
} from "../../../../actions/dashboard/sections/transacciones/reportesActions";
import { Selector } from "../../ui/Selector";
import { EstadoDeCuenta } from "./EstadoDeCuenta";

export const EstadosDeCuentaReports = () => {
	const { entities, activeEntitie, cuts, activeCut } = useSelector((state) => state.reportes);

	const dispatch = useDispatch();

	const handleSetReport = (option) => {
		dispatch(setActiveEntitie(option));
	};

	const handleSetCut = (option) => {
		dispatch(setActiveCut(option));
	};

	return (
		<>
			<Selector
				title={"Módulo:"}
				options={entities}
				activeOption={activeEntitie}
				setter={handleSetReport}
			/>

			<Selector title={"Corte:"} options={cuts} activeOption={activeCut} setter={handleSetCut} />

			{activeEntitie !== "" && <EstadoDeCuenta entitie={activeEntitie} />}
		</>
	);
};
