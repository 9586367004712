import { doc, writeBatch } from "firebase/firestore";
import { db } from "../firebaseConfig";
import Swal from "sweetalert2";

export const saveBatchOnFirestore = async (collectionPad, data) => {
	try {
		const batch = writeBatch(db);

		data.forEach((row) => {
			const rowRef = doc(db, collectionPad, row.id);
			batch.set(rowRef, row);
		});

		await batch.commit();

		Swal.fire({
			title: "Datos guardados!",
			text: "Se guardaron los cambios en la base de datos.",
			icon: "success",
			confirmButtonText: "Ok",
			confirmButtonColor: "#3182ce",
			customClass: { popup: "swal__popup" }
		});

		return true;
	} catch (error) {
		console.log("Error code: ", error.code);
		console.log("Error message: ", error.message);
		Swal.fire({
			title: "Error!",
			text: "Error al intentar guardar en la base de datos.",
			icon: "error",
			confirmButtonText: "Ok",
			confirmButtonColor: "#3182ce",
			customClass: { popup: "swal__popup" }
		});

		return false;
	}
};
