import { types } from "../../../../types/types";

const initialState = {
	subSections: ["Ofertas", "Compras", "Traspasos", "Ingresos", "Pagos", "Transferencias"],
	activeSubSection: "Ofertas",
};

export const transaccionesReducer = (state = { ...initialState }, action) => {
	switch (action.type) {
		case types.setTransaccionesActiveSubSection:
			return { ...state, activeSubSection: action.payload };

		default:
			return state;
	}
};
