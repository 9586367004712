export const getColumnTotal = (data, columnName) => {
	let total = 0;
	data.forEach((row) => {
		total += row[columnName];
	});

	return total;
};

export const getTotalRow = (patterns, data) => {
	const row = {};
	patterns.forEach((pattern) => {
		row[pattern.id] = pattern.type === "number" ? 0 : "";
	});

	data.forEach((dataRow) => {
		patterns.forEach((pattern) => {
			pattern.type === "number" && (row[pattern.id] += dataRow[pattern.id]);
		});
	});

	row.id = "TOTAL";

	return row;
};
