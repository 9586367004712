import { deleteDocOnFirestore } from "../../../../firebase/crudFirestoreActions/deleteDocOnFirestore";
import { loadCollectionFromFirestore } from "../../../../firebase/crudFirestoreActions/loadCollectionFromFirestor";
import { saveDocOnFirestore } from "../../../../firebase/crudFirestoreActions/saveDocOnFirestore";
import { types } from "../../../../types/types";

export const startLoadEntidades = () => {
	return async (dispatch) => {
		const entidades = await loadCollectionFromFirestore("entidades");
		dispatch(setEntidades(entidades));
	};
};

export const setEntidades = (entidades) => ({
	type: types.setEntidades,
	payload: entidades
});

export const selectEntidad = (entidad) => ({
	type: types.selectEntidad,
	payload: entidad
});

export const addEntidad = () => ({
	type: types.addEntidad
});

export const closeEntidadForm = () => ({
	type: types.closeEntidadForm
});

export const startSaveEntidad = (entidad) => {
	return async (dispatch) => {
		const isDocSave = await saveDocOnFirestore("entidades", entidad, entidad.id);
		if (isDocSave) dispatch(saveEntidad(entidad));
	};
};

export const saveEntidad = (entidad) => ({
	type: types.saveEntidad,
	payload: entidad
});

export const startDeleteEntidad = (entidadId) => {
	return async (dispatch) => {
		const isDeleted = await deleteDocOnFirestore("entidades", entidadId);
		if (isDeleted) {
			dispatch(deleteEntidad(entidadId));
		}
	};
};

export const deleteEntidad = (entidadId) => ({
	type: types.deleteEntidad,
	payload: entidadId
});

export const changeContactFormState = () => ({
	type: types.changeContactFormState
});
