import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { MdSave } from "react-icons/md";
import { useDispatch } from "react-redux";
import { handleSetUserName } from "../../../../actions/auth/authActions";
import { uiRemoveError, uiSetError } from "../../../../actions/uiActions";

export const NameForm = () => {
	const { displayName } = useSelector((state) => state.auth);
	const [userName, setUserName] = useState(displayName);
	const { msgError } = useSelector((state) => state.ui);

	const dispatch = useDispatch();

	const handleSaveUserName = (e) => {
		e.preventDefault();
		if (isFormValid()) dispatch(handleSetUserName(userName));
	};

	const isFormValid = () => {
		if (userName.trim().length === 0) {
			dispatch(uiSetError("Ingrese nombre de usuario."));
			return false;
		}

		dispatch(uiRemoveError());
		return true;
	};

	return (
		<form className="userNameForm">
			<h2 className="authForm__heading text-center">Nombre de usuario</h2>

			<label htmlFor="userName" className="form__label">
				Nombre de usuario:
			</label>

			<div className="userNameForm__inputGroup">
				<input
					id="userName"
					type="text"
					className="form__input"
					placeholder="Volumen a ofertar"
					autoComplete="off"
					name="userName"
					value={userName}
					onChange={(e) => setUserName(e.target.value)}
				/>

				<button className="userNameForm__button" onClick={handleSaveUserName}>
					<MdSave className="detailForm__icon" />
					Guardar
				</button>
			</div>

			{msgError && <div className="userNameForm__error">{msgError}</div>}
		</form>
	);
};
