import { types } from "../../types/types";

const getCurrentCycle = () => {
	const today = new Date();
	const month = today.getMonth() + 1;
	const year = month > 9 ? today.getFullYear() : today.getFullYear() - 1;

	return `${year}-${year + 1}`;
};

const initialState = {
	userPopoverExpanded: false,
	sidebarExpanded: true,
	currentCycle: getCurrentCycle()
};

export const dashboardReducer = (state = { ...initialState }, action) => {
	switch (action.type) {
		case types.setPopoverState:
			return {
				...state,
				userPopoverExpanded: action.payload
			};

		case types.collapsePopover:
			return {
				...state,
				userPopoverExpanded: false
			};

		case types.setSidebarState:
			return {
				...state,
				sidebarExpanded: action.payload
			};

		case types.collapseSidebar:
			return {
				...state,
				sidebarExpanded: false
			};

		case types.setCurrenCycle:
			return {
				...state,
				currentCycle: action.payload
			};

		default:
			return state;
	}
};
