import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdClose, MdSave, MdLogout, MdDelete, MdPhotoCamera, MdUpload } from "react-icons/md";

import { useFormToUpper } from "../../../../hooks/UseFormToUpper";
import { objectsDeepEqual } from "../../../../helpers/objectsDeepEqual";
import { uiRemoveError, uiSetError } from "../../../../actions/uiActions";
import {
	closeOfertaForm,
	startDeleteOferta,
	startDeleteSingleOfertaFile,
	startSaveOferta,
} from "../../../../actions/dashboard/sections/transacciones/ofertasActions";

export const OfertaForm = ({ oferta }) => {
	const { edicion } = useSelector((state) => state.auth);

	const { msgError, loading } = useSelector((state) => state.ui);
	const { entidades } = useSelector((state) => state.entidades);
	const { currentCycle } = useSelector((state) => state.dashboard);
	const { isMobile } = useSelector((state) => state.ui);

	const dispatch = useDispatch();

	const [values, handleInputChange] = useFormToUpper(oferta);
	const { entidad, fecha, usoAgricola, organismoOperador, organismoOperadorMA, filesToUpload } =
		values;
	const thereAreChanges = !objectsDeepEqual(oferta, values);
	// console.log({ thereAreChanges, oferta, values });
	// const gmt = "T00:00:00";

	const handleSaveOferta = (e) => {
		e.preventDefault();
		if (isFormValid()) {
			dispatch(startSaveOferta(values, currentCycle));
		}
	};

	const handleDeleteOferta = (e) => {
		e.preventDefault();
		dispatch(startDeleteOferta(oferta, currentCycle));
	};

	const closeForm = (e) => {
		e.preventDefault();

		dispatch(closeOfertaForm());
		dispatch(uiRemoveError());
	};

	const handleDeleteSingleFile = (oferta, file) => {
		dispatch(startDeleteSingleOfertaFile(currentCycle, oferta, file));
	};

	const isFormValid = () => {
		if (fecha === "") {
			dispatch(uiSetError("Indique la fecha."));
			return false;
		}

		if (entidad.trim().length === 0) {
			dispatch(uiSetError("Seleccione la entidad que oferta."));
			return false;
		}

		if (usoAgricola + organismoOperadorMA + organismoOperador === 0) {
			dispatch(uiSetError("Indique el volumen a ofertar."));
			return false;
		}

		if (usoAgricola < 0) {
			dispatch(uiSetError("El volumen para uso agrícola no puede ser negativo."));
			return false;
		}

		if (organismoOperador < 0) {
			dispatch(uiSetError("El volumen para el organismo operador no puede ser negativo."));
			return false;
		}

		if (organismoOperadorMA < 0) {
			dispatch(
				uiSetError("El volumen para el organismo operador mesa arenosa no puede ser negativo.")
			);
			return false;
		}

		dispatch(uiRemoveError());
		return true;
	};

	return (
		<form className="detailForm">
			{oferta.id.length === 0 ? (
				<div className="detailForm__title">Nueva oferta</div>
			) : (
				<div className="detailForm__title">Oferta: {oferta.id}</div>
			)}

			<div className="form">
				<div className="form__inputGroup">
					<label htmlFor="fecha" className="form__label">
						Fecha:
					</label>
					<input
						id="fecha"
						type="date"
						className="form__input"
						name="fecha"
						value={fecha}
						onChange={handleInputChange}
					/>
				</div>

				<div className="form__inputGroup">
					<label htmlFor="entidad" className="form__label">
						Entidad:
					</label>
					<select
						id="entidad"
						name="entidad"
						type="text"
						value={entidad}
						onChange={handleInputChange}
						className="form__input form__input--select"
					>
						<option value="" disabled>
							Entidad
						</option>

						{entidades.map((entidad) => (
							<option key={entidad.id} value={entidad.id}>
								{entidad.id}
							</option>
						))}
					</select>
				</div>

				<div className="form__inputGroup">
					<label htmlFor="usoAgricola" className="form__label">
						Uso Agrícola:
					</label>
					<input
						id="usoAgricola"
						type="number"
						className="form__input"
						placeholder="Volumen a ofertar"
						autoComplete="off"
						name="usoAgricola"
						value={usoAgricola}
						onChange={handleInputChange}
					/>
				</div>

				<div className="form__inputGroup">
					<label htmlFor="organismoOperador" className="form__label">
						Organismo Operador:
					</label>
					<input
						id="organismoOperador"
						type="number"
						className="form__input"
						placeholder="Volumen a ofertar"
						autoComplete="off"
						name="organismoOperador"
						value={organismoOperador}
						onChange={handleInputChange}
					/>
				</div>

				<div className="form__inputGroup">
					<label htmlFor="organismoOperadorMA" className="form__label">
						Organismo Operador Mesa Arenosa:
					</label>
					<input
						id="organismoOperadorMA"
						type="number"
						className="form__input"
						placeholder="Volumen a ofertar"
						autoComplete="off"
						name="organismoOperadorMA"
						value={organismoOperadorMA}
						onChange={handleInputChange}
					/>
				</div>

				<div className="form__inputGroup">
					<label className="form__label">
						Total volumen ofertado:{" "}
						{Number(usoAgricola) + Number(organismoOperadorMA) + Number(organismoOperador)}
					</label>
				</div>
				{edicion && (
					<div className="form__inputGroup">
						<label htmlFor="filesToUpload">
							<div className="form__filesInput">
								<MdUpload className="form__buttonIcon" /> Documentos
							</div>
						</label>
						<input
							id="filesToUpload"
							type="file"
							multiple="multiple"
							className="filesInput"
							name="filesToUpload"
							value={filesToUpload.files}
							onChange={handleInputChange}
						/>

						{isMobile && (
							<div>
								<label htmlFor="cameraInput">
									<div className="form__cameraInput">
										<MdPhotoCamera className="form__buttonIcon" />
									</div>
								</label>

								<input
									id="cameraInput"
									type="file"
									multiple="multiple"
									capture="camera"
									className="cameraInput"
									name="filesToUpload"
									value={filesToUpload.files}
									onChange={handleInputChange}
								/>
							</div>
						)}
					</div>
				)}

				{oferta.filesInFirebase.map((file) => (
					<div className="fileLink" key={file.name}>
						<a
							href={file.url}
							rel="noopener noreferrer"
							target="_blank"
							className="fileLink__fileName"
						>
							{file.name}
						</a>

						{edicion && (
							<MdDelete
								className="fileLink__icon"
								onClick={() => handleDeleteSingleFile(oferta, file)}
							/>
						)}
					</div>
				))}

				{msgError && <div className="form__error">{msgError}</div>}

				<div className="detailForm__buttonsGrid">
					{(edicion && oferta.id.length) > 0 && (
						<button className="form__button detailForm__deleteButton" onClick={handleDeleteOferta}>
							<MdClose className="detailForm__icon" />
							Borrar
						</button>
					)}

					<button className="form__button detailForm__closeButton" onClick={closeForm}>
						<MdLogout className="detailForm__icon detailForm__icon--mirror" />
						Cerrar
					</button>

					{edicion && (thereAreChanges || loading) && (
						<button className="form__button detailForm__saveButton" onClick={handleSaveOferta}>
							<MdSave className="detailForm__icon" />
							Guardar {loading && <div className="form__spinner"></div>}
						</button>
					)}
				</div>
			</div>
		</form>
	);
};

// fecha
// entidad
// concepto
// referncia
// volumen - importe
// cuota
// comision
