import { types } from "../../../../types/types";

const initialState = {
	data: [],
	distribucionActiveCell: null
};

export const distribucionReducer = (state = { ...initialState }, action) => {
	switch (action.type) {
		case types.setDistribucionData:
			return { ...state, data: action.payload };

		case types.setDistribucionActiveCell:
			return { ...state, distribucionActiveCell: action.payload };

		default:
			return state;
	}
};
