import React from "react";
import { useSelector } from "react-redux";
import { TransferenciasSelector } from "../transacciones/TransferenciasSelector";
import { TransferenciasReport } from "./TransferenciasReport";

export const TransferenciasReportSelector = () => {
	const {
		transferenciasSalientes,
		transferenciasEntrantes,
		balances,
		volumenTransferenciasSalientes,
		volumenTransferenciasEntrantes,
		volumenBalances,
		tipoTabla,
		unidad
	} = useSelector((state) => state.transferencias);

	const data = {
		SalientesHa: transferenciasSalientes,
		EntrantesHa: transferenciasEntrantes,
		BalaceHa: balances,
		SalientesMm3: volumenTransferenciasSalientes,
		EntrantesMm3: volumenTransferenciasEntrantes,
		BalaceMm3: volumenBalances
	};

	return (
		<div>
			<TransferenciasSelector />
			<TransferenciasReport transferencias={data[`${tipoTabla}${unidad}`]} />
		</div>
	);
};

//`${tipoTabla}-${unidad}`
