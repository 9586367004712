import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setCurrenCycle } from "../../../actions/dashboard/dashboardActions";

export const CycleSelector = () => {
	const cyclesGenerator = (start) => {
		const cycles = [];
		const today = new Date();
		const end = today.getFullYear();

		for (let year = start; year <= end; year++) {
			cycles.push(`${year}-${year + 1}`);
		}

		return cycles;
	};

	const { currentCycle } = useSelector((state) => state.dashboard);
	const cycles = cyclesGenerator(2020);

	const dispatch = useDispatch();

	const handleSetCycle = ({ target }) => {
		dispatch(setCurrenCycle(target.value));
	};

	return (
		<select
			id="cycle"
			name="cycle"
			type="text"
			value={currentCycle}
			onChange={handleSetCycle}
			className="cycleSelector"
		>
			{cycles.map((cycleOption) => (
				<option key={cycleOption} value={cycleOption} className="cycleSelector__option">
					{cycleOption}
				</option>
			))}
		</select>
	);
};
