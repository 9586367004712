import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdClose, MdSave, MdLogout } from "react-icons/md";

import { useFormToUpper } from "../../../../hooks/UseFormToUpper";
import { objectsDeepEqual } from "../../../../helpers/objectsDeepEqual";
import { uiRemoveError, uiSetError } from "../../../../actions/uiActions";
import {
	closeComisionForm,
	startDeleteComision,
	startSaveComision
} from "../../../../actions/dashboard/sections/settings/comisionesActions";

export const ComisionForm = ({ comision }) => {
	const { edicion } = useSelector((state) => state.auth);

	const { msgError, loading } = useSelector((state) => state.ui);
	const { comisiones } = useSelector((state) => state.comisiones);

	const dispatch = useDispatch();

	const [values, handleInputChange] = useFormToUpper(comision);
	const { id, descripcion, inicio, fin, porcentaje } = values;
	const thereAreChanges = !objectsDeepEqual(comision, values);
	const gmt = "T00:00:00";

	const handleSaveComision = (e) => {
		e.preventDefault();
		if (isFormValid()) {
			dispatch(startSaveComision(values));
		}
	};

	const handleDeleteComision = (e) => {
		e.preventDefault();
		dispatch(startDeleteComision(comision.id));
	};

	const closeForm = (e) => {
		e.preventDefault();

		dispatch(closeComisionForm());
		dispatch(uiRemoveError());
	};

	const isFormValid = () => {
		if (id.trim().length === 0) {
			dispatch(uiSetError("Ingrese id único."));
			return false;
		} else if (comision.id.length === 0 && comisiones.find((comision) => comision.id === id)) {
			dispatch(uiSetError("Ya existe una comisión con el mismo id"));
			return false;
		} else if (descripcion.trim().length === 0) {
			dispatch(uiSetError("Ingrese descripción de la comisión."));
			return false;
		} else if (inicio === "") {
			dispatch(uiSetError("Indique el inicio de la vigencia."));
			return false;
		} else if (fin === "") {
			dispatch(uiSetError("Indique el final de la vigencia."));
			return false;
		} else if (new Date(inicio + gmt) > new Date(fin + gmt)) {
			dispatch(uiSetError("La fecha final no puede ser anterior a la fecha inicial."));
			return false;
		} else if (porcentaje < 0) {
			dispatch(uiSetError("El porcentaje no puede ser negativo."));
			return false;
		} else if (porcentaje > 100) {
			dispatch(uiSetError("El porcentaje no puede ser mayor a 100."));
			return false;
		}

		dispatch(uiRemoveError());
		return true;
	};

	return (
		<div className="detailForm">
			{comision.id.length === 0 ? (
				<div className="detailForm__title">Nueva comisión</div>
			) : (
				<div className="detailForm__title">Comisión: {comision.id}</div>
			)}

			<form className="form">
				{comision.id.length === 0 && (
					<div className="form__inputGroup">
						<label htmlFor="id" className="form__label">
							Id:
						</label>
						<input
							id="id"
							type="text"
							className="form__input"
							placeholder="Identificador único"
							name="id"
							value={id}
							onChange={handleInputChange}
						/>
					</div>
				)}

				<div className="form__inputGroup">
					<label htmlFor="descripcion" className="form__label">
						Descripción:
					</label>
					<input
						id="descripcion"
						type="text"
						className="form__input"
						placeholder="Nombre de la comisión"
						autoComplete="off"
						name="descripcion"
						value={descripcion}
						onChange={handleInputChange}
					/>
				</div>

				<div className="form__inputGroup">
					<label htmlFor="inicio" className="form__label">
						Inicio:
					</label>
					<input
						id="inicio"
						type="date"
						className="form__input"
						name="inicio"
						value={inicio}
						onChange={handleInputChange}
					/>
				</div>

				<div className="form__inputGroup">
					<label htmlFor="fin" className="form__label">
						Fin:
					</label>

					<input
						id="fin"
						type="date"
						className="form__input"
						name="fin"
						value={fin}
						onChange={handleInputChange}
					/>
				</div>

				<div className="form__inputGroup">
					<label htmlFor="porcentaje" className="form__label">
						Porcentaje:
					</label>
					<input
						id="porcentaje"
						type="number"
						className="form__input"
						autoComplete="off"
						name="porcentaje"
						value={porcentaje}
						onChange={handleInputChange}
					/>
				</div>

				{msgError && <div className="form__error">{msgError}</div>}

				<div className="detailForm__buttonsGrid">
					{(edicion && comision.id.length) > 0 && (
						<button
							className="form__button detailForm__deleteButton"
							onClick={handleDeleteComision}
						>
							<MdClose className="detailForm__icon" />
							Borrar
						</button>
					)}

					<button className="form__button detailForm__closeButton" onClick={closeForm}>
						<MdLogout className="detailForm__icon detailForm__icon--mirror" />
						Cerrar
					</button>

					{edicion && thereAreChanges && (
						<button className="form__button detailForm__saveButton" onClick={handleSaveComision}>
							<MdSave className="detailForm__icon" />
							Guardar {loading && <div className="form__spinner"></div>}
						</button>
					)}
				</div>
			</form>
		</div>
	);
};
