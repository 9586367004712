export const entidadesColumns = [
	{
		name: "Logo",
		selector: (row) => row.logo,
		sortable: false,
		cell: (row) => <img src={row.logo} alt="user" style={{ width: "4.8rem", margin: ".5rem" }} />,
		width: "10rem",
		center: true,
	},
	{
		name: "Id",
		selector: (row) => row.id,
		sortable: true,
		width: "7rem",
		center: true,
	},
	{
		name: "Nombre",
		selector: (row) => row.nombre,
		sortable: true,
	},
	{
		name: "Saldo",
		selector: (row) => row.saldoInicial,
		sortable: true,
		width: "12rem",
		center: true,
	},
	{
		name: "Sup.Fisica",
		selector: (row) => row.supFisica,
		sortable: true,
		width: "12rem",
		center: true,
	},
	{
		name: "Sup.Derecho",
		selector: (row) => row.supDerecho,
		sortable: true,
		width: "12rem",
		center: true,
	},
];

export const contactosColumns = [
	{
		name: "Id",
		selector: (row) => row.id,
		sortable: true,
		width: "10rem",
		center: true,
	},
	{
		name: "Entidad",
		selector: (row) => row.entidad,
		width: "9rem",
		sortable: true,
		center: true,
	},
	{
		name: "Nombre",
		selector: (row) => row.nombre,
		sortable: true,
		width: "20rem",
	},
	{
		name: "Cargo",
		selector: (row) => row.cargo,
		sortable: true,
		width: "12rem",
	},
	{
		name: "Teléfono",
		selector: (row) => row.tel,
		sortable: true,
		width: "12rem",
	},
	{
		name: "Email",
		selector: (row) => row.email,
		sortable: true,
		width: "20rem",
	},
];

export const cuotasColumns = [
	{
		name: "Id",
		selector: (row) => row.id,
		sortable: true,
		width: "10rem",
		center: true,
	},
	{
		name: "Descripción",
		selector: (row) => row.descripcion,
		sortable: true,
	},
	{
		name: "Vigencia",
		selector: (row) => row.vigencia,
		sortable: true,
		width: "20rem",
		center: true,
	},
	{
		name: "Costo/mm3",
		selector: (row) => row.costo,
		sortable: true,
		width: "12rem",
		center: true,
	},
];

export const comisionesColumns = [
	{
		name: "Id",
		selector: (row) => row.id,
		sortable: true,
		width: "10rem",
		center: true,
	},
	{
		name: "Descripción",
		selector: (row) => row.descripcion,
		sortable: true,
	},
	{
		name: "Vigencia",
		selector: (row) => row.vigencia,
		sortable: true,
		width: "20rem",
		center: true,
	},
	{
		name: "%",
		selector: (row) => row.porcentaje,
		sortable: true,
		width: "12rem",
		center: true,
	},
];

export const ofertasColumns = [
	{
		name: "Id",
		selector: (row) => row.id,
		sortable: true,
		width: "10rem",
		center: true,
	},
	{
		name: "Entidad",
		selector: (row) => row.entidad,
		sortable: true,
		width: "10rem",
	},
	{
		name: "Fecha",
		selector: (row) => row.fecha,
		sortable: true,
		width: "10rem",
		center: true,
	},
	{
		name: "Volumen (mm3)",
		selector: (row) => row.volumenTotal,
		sortable: true,
		width: "20rem",
		center: true,
	},
	{
		name: "Documentos",
		selector: (row) => row.filesInFirebase.length,
		sortable: true,
		width: "12rem",
		center: true,
	},
];

export const ingresosColumns = [
	{
		name: "Id",
		selector: (row) => row.id,
		sortable: true,
		width: "10rem",
		center: true,
	},
	{
		name: "Entidad",
		selector: (row) => row.entidad,
		sortable: true,
		width: "10rem",
	},
	{
		name: "Fecha",
		selector: (row) => row.fecha,
		sortable: true,
		width: "10rem",
		center: true,
	},
	{
		name: "Importe (Mn)",
		selector: (row) => row.importe,
		sortable: true,
		width: "15rem",
		center: true,
	},
	{
		name: "Referencia",
		selector: (row) => row.referencia,
		sortable: true,
		width: "15rem",
		center: true,
	},
	{
		name: "Documentos",
		selector: (row) => row.filesInFirebase.length,
		sortable: true,
		width: "12rem",
		center: true,
	},
];

export const pagosColumns = [
	{
		name: "Id",
		selector: (row) => row.id,
		sortable: true,
		width: "10rem",
		center: true,
	},
	{
		name: "Entidad",
		selector: (row) => row.entidad,
		sortable: true,
		width: "10rem",
	},
	{
		name: "Fecha",
		selector: (row) => row.fecha,
		sortable: true,
		width: "10rem",
		center: true,
	},
	{
		name: "Importe (Mn)",
		selector: (row) => row.importe,
		sortable: true,
		width: "15rem",
		center: true,
	},
	{
		name: "Referencia",
		selector: (row) => row.referencia,
		sortable: true,
		width: "15rem",
		center: true,
	},
	{
		name: "Documentos",
		selector: (row) => row.filesInFirebase.length,
		sortable: true,
		width: "12rem",
		center: true,
	},
];

export const comprasColumns = [
	{
		name: "Id",
		selector: (row) => row.id,
		sortable: true,
		width: "10rem",
		center: true,
	},
	{
		name: "Entidad",
		selector: (row) => row.entidad,
		sortable: true,
		width: "10rem",
	},
	{
		name: "Fecha",
		selector: (row) => row.fecha,
		sortable: true,
		width: "10rem",
		center: true,
	},
	{
		name: "Volumen (mm3)",
		selector: (row) => row.volumen,
		sortable: true,
		width: "20rem",
		center: true,
	},
	{
		name: "Documentos",
		selector: (row) => row.filesInFirebase.length,
		sortable: true,
		width: "12rem",
		center: true,
	},
];

export const traspasosColumns = [
	{
		name: "Id",
		selector: (row) => row.id,
		sortable: true,
		width: "10rem",
		center: true,
	},
	{
		name: "Emisor",
		selector: (row) => row.entidadEmisora,
		sortable: true,
		width: "10rem",
	},
	{
		name: "Receptor",
		selector: (row) => row.entidadReceptora,
		sortable: true,
		width: "10rem",
	},
	{
		name: "Fecha",
		selector: (row) => row.fecha,
		sortable: true,
		width: "10rem",
		center: true,
	},
	{
		name: "Volumen (mm3)",
		selector: (row) => row.volumen,
		sortable: true,
		width: "20rem",
		center: true,
	},
	{
		name: "Documentos",
		selector: (row) => row.filesInFirebase.length,
		sortable: true,
		width: "12rem",
		center: true,
	},
];
