import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Routes, Route, HashRouter } from "react-router-dom";
import { login } from "../actions/auth/authActions";
import { DashboardScreen } from "../components/dashboard/DashboardScreen";
import { getDocFromFirestore } from "../firebase/crudFirestoreActions/getDocFromFirestore";
import { firebaseCheckIsLoggedIn } from "../firebase/firebaseConfig";

import { AuthRouter } from "./AuthRouter";
import { PrivateRoute } from "./PrivateRoute";
import { PublicRoute } from "./PublicRoute";

export const AppRouter = () => {
	const [checkingAuth, setCheckingAuth] = useState(true);
	const dispatch = useDispatch();

	useEffect(() => {
		firebaseCheckIsLoggedIn(async (user) => {
			if (user) {
				const userProfile = await getDocFromFirestore("users", user.uid);

				const img = user.photoURL ? user.photoURL : "./img/user.jpg";
				dispatch(
					login({
						uid: user.uid,
						displayName: user.displayName,
						userImage: img,
						consulta: userProfile.consulta,
						edicion: userProfile.edicion
					})
				);
			}

			setCheckingAuth(false);
		});
	}, [dispatch]);

	if (checkingAuth) return <div className="authSpinner"></div>;

	return (
		<HashRouter>
			<Routes>
				<Route
					path="/auth/*"
					element={
						<PublicRoute>
							<AuthRouter />
						</PublicRoute>
					}
				/>

				<Route
					path="/*"
					element={
						<PrivateRoute>
							<DashboardScreen />
						</PrivateRoute>
					}
				/>
			</Routes>
		</HashRouter>
	);
};
