import { runTransaction, doc } from "firebase/firestore";
import { db } from "../firebaseConfig";
import Swal from "sweetalert2";

export const savePagoTransaction = async (cycle, pago, comision, docId = false) => {
	try {
		const pagoRef = doc(db, `ciclos/${cycle}/pagos`, docId);
		const comisionRef = doc(db, `ciclos/${cycle}/cargos`, docId);

		await runTransaction(db, async (transaction) => {
			transaction.set(pagoRef, pago);
			transaction.set(comisionRef, {
				...pago,
				importe: (pago.importe / 100) * comision,
				concepto: `COMISIÓN BANCO DE AGUA (${pago.id})"`,
			});
		});

		Swal.fire({
			title: "Registro guardado!",
			text: "Se guardó con éxito el nuevo registro en la base de datos.",
			icon: "success",
			confirmButtonText: "Ok",
			confirmButtonColor: "#3182ce",
			customClass: { popup: "swal__popup" },
		});

		return true;
	} catch (error) {
		console.log("Error code: ", error.code);
		console.log("Error message: ", error.message);
		Swal.fire({
			title: "Error!",
			text: "Error al intentar guardar en la base de datos.",
			icon: "error",
			confirmButtonText: "Ok",
			confirmButtonColor: "#3182ce",
			customClass: { popup: "swal__popup" },
		});
		return false;
	}
};
