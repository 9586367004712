// import { deleteDocOnFirestore } from "../../../../firebase/crudFirestoreActions/deleteDocOnFirestore";
// import { deleteFilesOnFirebase } from "../../../../firebase/crudFirestoreActions/deleteFilesOnFirebase";
// import { deleteSingleFileOnFirebase } from "../../../../firebase/crudFirestoreActions/deleteSingleFileOnFirebase";
import { loadCollectionFromFirestore } from "../../../../firebase/crudFirestoreActions/loadCollectionFromFirestor";
// import { saveFilesOnFirebase } from "../../../../firebase/crudFirestoreActions/saveFilesOnFirebase";
// import { saveCargoTransaction } from "../../../../firebase/crudFirestoreActions/saveCargoTransaction";
// import { updateDocInFirestore } from "../../../../firebase/crudFirestoreActions/updateDocInFirestore";
import { roundToN } from "../../../../helpers/mathHelpers";
import { types } from "../../../../types/types";
// import { uiFinishLoading, uiStartLoading } from "../../../uiActions";

export const startLoadCargos = (cycle) => {
	return async (dispatch) => {
		const cargos = await loadCollectionFromFirestore(`ciclos/${cycle}/cargos`);

		let totalCargos = 0;
		cargos.forEach((cargo) => {
			totalCargos += cargo.importe;
			cargo.importe = roundToN(cargo.importe, 2);
		});

		dispatch(setTotalCargos(totalCargos));
		dispatch(setCargos(cargos));
	};
};

export const setCargos = (cargos) => ({
	type: types.setCargos,
	payload: cargos,
});

export const setTotalCargos = (total) => ({
	type: types.setTotalCargos,
	payload: total,
});

// export const selectCargo = (cargo) => ({
// 	type: types.selectCargo,
// 	payload: cargo,
// });

// export const addCargo = () => ({
// 	type: types.addCargo,
// });

// export const closeCargoForm = () => ({
// 	type: types.closeCargoForm,
// });

// export const startSaveCargo = (cargo, cycle) => {
// 	return async (dispatch, getState) => {
// 		const state = getState();
// 		const comision = state.comisiones.comisiones.find((comision) => comision.id === cargo.comision);

// 		dispatch(uiStartLoading());

// 		const today = new Date();
// 		if (!cargo.id) cargo.id = today.getTime().toString().substring(5);

// 		const filesRefs = await saveFilesOnFirebase(
// 			cargo.id,
// 			cargo.filesToUpload,
// 			state.cargos.cargoSelected.filesInFirebase,
// 			`${cycle}/cargos`
// 		);

// 		cargo.filesInFirebase = [...cargo.filesInFirebase, ...filesRefs];
// 		cargo.filesToUpload = [];

// 		const isDocSave = await saveCargoTransaction(
// 			cycle,
// 			{ ...cargo },
// 			comision.porcentaje,
// 			cargo.id
// 		);

// 		if (filesRefs && isDocSave) {
// 			dispatch(setTotalCargos(state.cargos.totalCargos + cargo.importe));
// 			dispatch(saveCargo({ ...cargo }));
// 			dispatch(uiFinishLoading());
// 		}
// 	};
// };

// export const saveCargo = (cargo) => ({
// 	type: types.saveCargo,
// 	payload: cargo,
// });

// export const startDeleteCargo = (cargo, cycle) => {
// 	return async (dispatch, getState) => {
// 		const state = getState();
// 		const isDeleted = await deleteDocOnFirestore(`ciclos/${cycle}/cargos`, cargo.id);
// 		const areFilesDeleted = await deleteFilesOnFirebase(`${cycle}/cargos`, cargo.filesInFirebase);
// 		if (isDeleted && areFilesDeleted) {
// 			dispatch(deleteCargo(cargo.id));
// 			dispatch(
// 				setTotalCargos(state.cargos.totalCargos - Number(cargo.volumenTotal.replace(",", () => "")))
// 			);
// 		}
// 	};
// };

// export const deleteCargo = (cargoId) => ({
// 	type: types.deleteCargo,
// 	payload: cargoId,
// });

// export const startDeleteSingleCargoFile = (cycle, cargo, file) => {
// 	return async (dispatch) => {
// 		const isDeleted = await deleteSingleFileOnFirebase(`${cycle}/cargos`, file);

// 		if (isDeleted) {
// 			const files = cargo.filesInFirebase.filter((filterFile) => filterFile.name !== file.name);
// 			const isUpdates = await updateDocInFirestore(`ciclos/${cycle}/cargos`, cargo.id, {
// 				filesInFirebase: files,
// 			});
// 			if (isUpdates) dispatch(deleteSingleCargoFile(cargo.id, file.name));
// 		}
// 	};
// };

// export const deleteSingleCargoFile = (cargoId, fileName) => ({
// 	type: types.deleteSingleCargoFile,
// 	payload: { cargoId, fileName },
// });
