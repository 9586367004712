import { types } from "../../../../types/types";

const initialState = {
	contactos: [],
	contactoSelected: null
};

export const contactosReducer = (state = { ...initialState }, action) => {
	switch (action.type) {
		case types.setContactos:
			return { ...state, contactos: action.payload };

		case types.selectContacto:
			return { ...state, contactoSelected: action.payload };

		case types.addContacto:
			return {
				...state,
				contactoSelected: {
					id: "",
					entidad: "",
					nombre: "",
					cargo: "",
					tel: "",
					email: ""
				}
			};

		case types.closeContactoForm:
			return { ...state, contactoSelected: null };

		case types.saveContacto:
			return {
				...state,
				contactos: [
					action.payload,
					...state.contactos.filter((contacto) => contacto.id !== action.payload.id)
				],
				contactoSelected: action.payload
			};

		case types.deleteContacto:
			return {
				...state,
				contactos: state.contactos.filter((contacto) => contacto.id !== action.payload),
				contactoSelected: null
			};

		default:
			return state;
	}
};
