import React from "react";
import { useSelector } from "react-redux";
import { Report } from "../../ui/Report";
import { getTotalRow } from "../../../../helpers/tableHelpers";

export const TraspasosReport = () => {
	const { currentCycle } = useSelector((state) => state.dashboard);
	const { traspasos } = useSelector((state) => state.traspasos);
	const { displayName } = useSelector((state) => state.auth);

	const items = [
		{
			type: "table",
			data: prepareTraspasos(traspasos),
			patterns: [
				{ id: "id", header: "ID", type: "text", width: "auto", textAlign: "start", volumen: false },
				{
					id: "fecha",
					header: "FECHA",
					type: "text",
					width: "auto",
					textAlign: "start",
					volumen: false,
				},
				{
					id: "entidadEmisora",
					header: "EMISOR",
					type: "text",
					width: "auto",
					textAlign: "center",
					volumen: false,
				},
				{
					id: "entidadReceptora",
					header: "RECEPTOR",
					type: "text",
					width: "auto",
					textAlign: "center",
					volumen: false,
				},

				{
					id: "volumen",
					header: "VOLUMEN",
					type: "number",
					width: "auto",
					textAlign: "end",
					volumen: true,
				},
			],
		},
	];

	const downloadable = {
		data: getDownloadableTable(items[0].patterns, items[0].data),
		headers: items[0].patterns.map((header) => header.id),
		title: `REPORTE DE TRASPASOS DE VOLUMEN`,
		author: displayName,
		sheetName: `REPORTE DE TRASPASOS DE VOLUMEN`,
	};

	return (
		<Report
			title={`REPORTE DE TRASPASOS DE VOLUMEN CICLO ${currentCycle}`}
			items={items}
			downloadable={downloadable}
		/>
	);
};

const prepareTraspasos = (traspasos) => {
	return traspasos.map((traspaso) => ({
		id: traspaso.id,
		entidadEmisora: traspaso.entidadEmisora,
		entidadReceptora: traspaso.entidadReceptora,
		fecha: traspaso.fecha,
		// volumen: Number(traspaso.volumen.replace(",", () => ""))
		volumen: traspaso.volumen,
	}));
};

const getDownloadableTable = (patterns, data) => {
	const table = [...data];
	table.push(getTotalRow(patterns, data));
	return table;
};
