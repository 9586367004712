export const types = {
	//auth
	login: "[auth] login",
	logout: "[auth] logout",
	setUserImage: "[auth] set User Image",
	setUserName: "[auth] set User Name",

	//ui
	uiSetError: "[ui] ui Set Error",
	uiRemoveError: "[ui] ui Remove Error",
	uiStartLoading: "[ui] ui Start Loading",
	uiFinishLoading: "[ui] ui Finish Loading",

	//dashboard
	setPopoverState: "[dashboard] set Popover State",
	collapsePopover: "[dashboard] collapse Popover",
	setSidebarState: "[dashboard] set Sidebar State",
	collapseSidebar: "[dashboard] collapse Sidebar",
	setCurrenCycle: "[dashboard] set Curren Cycle",

	//settings
	setSettingsActiveSubSection: "[settings] set Settings Active Sub Section",

	setEntidades: "[settings/entidades] set Entidades",
	selectEntidad: "[settings/entidades] select Entidad",
	addEntidad: "[settings/entidades] add Entidad",
	saveEntidad: "[settings/entidades] save Entidad",
	deleteEntidad: "[settings/entidades] delete Entidad",
	closeEntidadForm: "[settings/entidades] close Entidad Form",
	changeContactFormState: "[settings/entidades] change Contact Form State",

	setContactos: "[settings/contactos] set Contactos",
	selectContacto: "[settings/contactos] select Contacto",
	addContacto: "[settings/contactos] add Contacto",
	closeContactoForm: "[settings/contactos] close Contacto Form",
	saveContacto: "[settings/contactos] save Contacto",
	deleteContacto: "[settings/contactos] delete Contacto",

	setCuotas: "[settings/cuotas] set Cuotas",
	selectCuota: "[settings/cuotas] select Cuota",
	addCuota: "[settings/cuotas] add Cuota",
	closeCuotaForm: "[settings/cuotas] close Cuota Form",
	saveCuota: "[settings/cuotas] save Cuota",
	deleteCuota: "[settings/cuotas] delete Cuota",

	setComisiones: "[settings/comisiones] set Comisiones",
	selectComision: "[settings/comisiones] select Comision",
	addComision: "[settings/comisiones] add Comision",
	closeComisionForm: "[settings/comisiones] close Comision Form",
	saveComision: "[settings/comisiones] save Comision",
	deleteComision: "[settings/comisiones] delete Comision",

	//operacion
	setOperacionActiveSubSection: "[operacion] set Operacion Active Sub Section",

	setAsignacionData: "[operacion/asignacion] set Asignacion Data",
	setAsignacionActiveCell: "[operacion/asignacion] set Asignacion Active Cell",

	setDistribucionData: "[operacion/distribucion] set Distribucion Data",
	setDistribucionActiveCell: "[operacion/distribucion] set Distribucion Active Cell",

	//transacciones
	setTransaccionesActiveSubSection: "[transacciones] set Transacciones Active Sub Section",

	setOfertas: "[transacciones/ofertas] set Ofertas",
	setTotalOfertas: "[transacciones/ofertas] set Total Ofertas",
	setTotalesOfertas: "[transacciones/ofertas] set Totales Ofertas",
	selectOferta: "[transacciones/ofertas] select Oferta",
	addOferta: "[transacciones/ofertas] add Oferta",
	closeOfertaForm: "[transacciones/ofertas] close Oferta Form",
	saveOferta: "[transacciones/ofertas] save Oferta",
	deleteOferta: "[transacciones/ofertas] delete Oferta",
	deleteSingleOfertaFile: "[transacciones/ofertas] delete Single Oferta File",

	setCompras: "[transacciones/compras] set Compras",
	setTotalCompras: "[transacciones/compras] set Total Compras",
	setTotalesCompras: "[transacciones/compras] set Totales Compras",
	selectCompra: "[transacciones/compras] select Compra",
	addCompra: "[transacciones/ofertascompras aadd Compra",
	closeCompraForm: "[transacciones/compras] close Compra Form",
	saveCompra: "[transacciones/compras] save Compra",
	deleteCompra: "[transacciones/compras] delete Compra",
	deleteSingleCompraFile: "[transacciones/compras] delete Single Compra File",

	setTraspasos: "[transacciones/traspasos] set Traspasos",
	setTotalTraspasos: "[transacciones/traspasos] set Total Traspasos",
	selectTraspaso: "[transacciones/traspasos] select Traspaso",
	addTraspaso: "[transacciones/traspasos aadd Traspaso",
	closeTraspasoForm: "[transacciones/traspasos] close Traspaso Form",
	saveTraspaso: "[transacciones/traspasos] save Traspaso",
	deleteTraspaso: "[transacciones/traspasos] delete Traspaso",
	deleteSingleTraspasoFile: "[transacciones/traspasos] delete Single Traspaso File",

	setIngresos: "[transacciones/ingresos] set Ingresos",
	setTotalIngresos: "[transacciones/ingresos] set Total Ingresos",
	selectIngreso: "[transacciones/ingresos] select Ingreso",
	addIngreso: "[transacciones/ingresos] add Ingreso",
	closeIngresoForm: "[transacciones/ingresos] close Ingreso Form",
	saveIngreso: "[transacciones/ingresos] save Ingreso",
	deleteIngreso: "[transacciones/ingresos] delete Ingreso",
	deleteSingleIngresoFile: "[transacciones/ingresos] delete Single Ingreso File",

	setPagos: "[transacciones/pagos] set Pagos",
	setTotalPagos: "[transacciones/pagos] set Total Pagos",
	selectPago: "[transacciones/pagos] select Pago",
	addPago: "[transacciones/pagos] add Pago",
	closePagoForm: "[transacciones/pagos] close Pago Form",
	savePago: "[transacciones/pagos] save Pago",
	deletePago: "[transacciones/pagos] delete Pago",
	deleteSinglePagoFile: "[transacciones/pagos] delete Single Pago File",

	setCargos: "[transacciones/cargos] set Cargos",
	setTotalCargos: "[transacciones/cargos] set Total Cargos",
	selectCargo: "[transacciones/cargos] select Cargo",
	addCargo: "[transacciones/cargos] add Cargo",
	closeCargoForm: "[transacciones/cargos] close Cargo Form",
	saveCargo: "[transacciones/cargos] save Cargo",
	deleteCargo: "[transacciones/cargos] delete Cargo",
	deleteSingleCargoFile: "[transacciones/cargos] delete Single Cargo File",

	setTransferenciasSalientes: "[transacciones/transferencias] set Transferencias Salientes",
	setTransferenciasEntrantes: "[transacciones/transferencias] set Transferencias Entrantes",
	setBalancesTransferencias: "[transacciones/transferencias] set Balances Transferencias",
	setVolumenTransferenciasSalientes:
		"[transacciones/transferencias] set Volumen Transferencias Salientes",
	setVolumenTransferenciasEntrantes:
		"[transacciones/transferencias] set Volumen Transferencias Entrantes",
	setVolumenBalancesTransferencias:
		"[transacciones/transferencias] set Volumen Balances Transferencias",
	setTipoTabla: "[transacciones/transferencias] set Tipo Tabla",
	setUnidades: "[transacciones/transferencias] set Unidades",

	//Reportes
	setReportesActiveSubSection: "[reportes] set Reportes Active Sub Section",
	setActiveOperacioReport: "[reportes] set Active Operacio Report",
	setActiveTransaccionesReport: "[reportes] set Active Transacciones Report",
	setActiveEntitie: "[reportes] set Active Entitie",
	setActiveCut: "[reportes] set Active Cut",
};
