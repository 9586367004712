import React from "react";
import { MdAddCircle } from "react-icons/md";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
	addOferta,
	selectOferta
} from "../../../../actions/dashboard/sections/transacciones/ofertasActions";
import { getFormattedNumberString, roundToN } from "../../../../helpers/mathHelpers";

import { ofertasColumns } from "../../../../settings/tablesSettings";
import { CustomTable } from "../../ui/CustomTable";

export const OfertasTable = () => {
	const { edicion } = useSelector((state) => state.auth);
	const { ofertas, ofertaSelected, totalOfertas } = useSelector((state) => state.ofertas);

	const dispatch = useDispatch();

	const selectRow = (row) => {
		dispatch(selectOferta(row));
	};

	const addNewOferta = () => {
		dispatch(addOferta());
	};

	return (
		<div className="collectionTable">
			<CustomTable
				title={`Ofertas  (${getFormattedNumberString(roundToN(totalOfertas, 3))} mm3)`}
				columns={ofertasColumns}
				data={ofertas}
				selectRowId={ofertaSelected ? ofertaSelected.id : ""}
				onRowClickedAction={selectRow}
			/>

			{edicion && (
				<div className="collectionTable__addButton" onClick={addNewOferta}>
					<MdAddCircle />
				</div>
			)}
		</div>
	);
};
