import { types } from "../../../../types/types";

const initialState = {
	data: [],
	asignacionActiveCell: null
};

export const asignacionReducer = (state = { ...initialState }, action) => {
	switch (action.type) {
		case types.setAsignacionData:
			return { ...state, data: action.payload };

		case types.setAsignacionActiveCell:
			return { ...state, asignacionActiveCell: action.payload };

		default:
			return state;
	}
};
