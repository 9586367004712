import React from "react";
import { MdAddCircle } from "react-icons/md";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
	addTraspaso,
	selectTraspaso
} from "../../../../actions/dashboard/sections/transacciones/traspasosActions";
import { getFormattedNumberString, roundToN } from "../../../../helpers/mathHelpers";

import { traspasosColumns } from "../../../../settings/tablesSettings";
import { CustomTable } from "../../ui/CustomTable";

export const TraspasosTable = () => {
	const { edicion } = useSelector((state) => state.auth);
	const { traspasos, traspasoSelected, totalTraspasos } = useSelector((state) => state.traspasos);

	const dispatch = useDispatch();

	const selectRow = (row) => {
		dispatch(selectTraspaso(row));
	};

	const addNewTraspaso = () => {
		dispatch(addTraspaso());
	};

	return (
		<div className="collectionTable">
			<CustomTable
				title={`Traspasos  (${getFormattedNumberString(roundToN(totalTraspasos, 3))} mm3)`}
				columns={traspasosColumns}
				data={traspasos}
				selectRowId={traspasoSelected ? traspasoSelected.id : ""}
				onRowClickedAction={selectRow}
			/>

			{edicion && (
				<div className="collectionTable__addButton" onClick={addNewTraspaso}>
					<MdAddCircle />
				</div>
			)}
		</div>
	);
};
