import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdClose, MdSave, MdLogout } from "react-icons/md";

import { useFormToUpper } from "../../../../hooks/UseFormToUpper";
import { objectsDeepEqual } from "../../../../helpers/objectsDeepEqual";
import { uiRemoveError, uiSetError } from "../../../../actions/uiActions";
import {
	closeContactoForm,
	startDeleteContacto,
	startSaveContacto
} from "../../../../actions/dashboard/sections/settings/contactosActions";

export const ContactoForm = ({ contacto }) => {
	const { edicion } = useSelector((state) => state.auth);

	const { msgError, loading } = useSelector((state) => state.ui);
	const { entidades } = useSelector((state) => state.entidades);

	const dispatch = useDispatch();

	const [values, handleInputChange] = useFormToUpper(contacto);
	const { entidad, nombre, cargo, tel, email } = values;
	const thereAreChanges = !objectsDeepEqual(contacto, values);

	const handleSaveContacto = (e) => {
		e.preventDefault();
		if (isFormValid()) {
			dispatch(startSaveContacto(values));
		}
	};

	const handleDeleteContacto = (e) => {
		e.preventDefault();
		dispatch(startDeleteContacto(contacto));
	};

	const closeForm = (e) => {
		e.preventDefault();

		dispatch(closeContactoForm());
		dispatch(uiRemoveError());
	};

	const isFormValid = () => {
		if (entidad.trim().length === 0) {
			dispatch(uiSetError("Seleccione la entidad a la que pertenece."));
			return false;
		}

		if (nombre.trim().length === 0) {
			dispatch(uiSetError("Ingrese el nombre del contacto."));
			return false;
		}

		dispatch(uiRemoveError());
		return true;
	};

	return (
		<form className="detailForm">
			{contacto.id.length === 0 ? (
				<div className="detailForm__title">Registro de contacto</div>
			) : (
				<div className="detailForm__title">Contacto: {contacto.id}</div>
			)}

			<div className="form">
				<div className="form__inputGroup">
					<label htmlFor="entidad" className="form__label">
						Entidad:
					</label>
					<select
						id="entidad"
						name="entidad"
						type="text"
						value={entidad}
						onChange={handleInputChange}
						className="form__input form__input--select"
					>
						<option value="" disabled>
							Entidad
						</option>

						{entidades.map((entidad) => (
							<option key={entidad.id} value={entidad.id}>
								{entidad.id}
							</option>
						))}
					</select>
				</div>

				<div className="form__inputGroup">
					<label htmlFor="nombre" className="form__label">
						Nombre:
					</label>
					<input
						id="nombre"
						type="text"
						className="form__input"
						placeholder="Nombre de la entidad"
						autoComplete="off"
						name="nombre"
						value={nombre}
						onChange={handleInputChange}
					/>
				</div>

				<div className="form__inputGroup">
					<label htmlFor="cargo" className="form__label">
						Cargo:
					</label>
					<input
						id="cargo"
						type="text"
						className="form__input"
						placeholder="Nombre de la entidad"
						autoComplete="off"
						name="cargo"
						value={cargo}
						onChange={handleInputChange}
					/>
				</div>

				<div className="form__inputGroup">
					<label htmlFor="tel" className="form__label">
						Teléfono:
					</label>
					<input
						id="tel"
						type="tel"
						className="form__input"
						placeholder="Nombre de la entidad"
						autoComplete="off"
						name="tel"
						value={tel}
						onChange={handleInputChange}
					/>
				</div>

				<div className="form__inputGroup">
					<label htmlFor="email" className="form__label">
						Email:
					</label>
					<input
						id="email"
						type="email"
						className="form__input"
						placeholder="Nombre de la entidad"
						autoComplete="off"
						name="email"
						value={email}
						onChange={handleInputChange}
					/>
				</div>

				{msgError && <div className="form__error">{msgError}</div>}

				<div className="detailForm__buttonsGrid">
					{(edicion && contacto.id.length) > 0 && (
						<button
							className="form__button detailForm__deleteButton"
							onClick={handleDeleteContacto}
						>
							<MdClose className="detailForm__icon" />
							Borrar
						</button>
					)}

					<button className="form__button detailForm__closeButton" onClick={closeForm}>
						<MdLogout className="detailForm__icon detailForm__icon--mirror" />
						Cerrar
					</button>

					{edicion && (thereAreChanges || loading) && (
						<button className="form__button detailForm__saveButton" onClick={handleSaveContacto}>
							<MdSave className="detailForm__icon" />
							Guardar {loading && <div className="form__spinner"></div>}
						</button>
					)}
				</div>
			</div>
		</form>
	);
};
