import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";
import Swal from "sweetalert2";

export const saveDocOnFirestore = async (collectionName, document, docId = false) => {
	try {
		if (docId) await setDoc(doc(db, collectionName, docId), document);
		else await addDoc(collection(db, collectionName), document);

		Swal.fire({
			title: "Registro guardado!",
			text: "Se guardó con éxito el nuevo registro en la base de datos.",
			icon: "success",
			confirmButtonText: "Ok",
			confirmButtonColor: "#3182ce",
			customClass: { popup: "swal__popup" },
		});

		return true;
	} catch (error) {
		console.log("Error code: ", error.code);
		console.log("Error message: ", error.message);
		Swal.fire({
			title: "Error!",
			text: "Error al intentar guardar en la base de datos.",
			icon: "error",
			confirmButtonText: "Ok",
			confirmButtonColor: "#3182ce",
			customClass: { popup: "swal__popup" },
		});
		return false;
	}
};
