import React from "react";

export const NewUserMessage = () => {
	return (
		<div className="newUserMessage">
			<h1 className="newUserMessage__title">¡Bienvenido!</h1>
			<p className="newUserMessage__text">
				Aún no se han asignado privilegios de usuario a su perfil. Por favor, contacte al
				administrador del sistema.
			</p>
		</div>
	);
};
