import { deleteDocOnFirestore } from "../../../../firebase/crudFirestoreActions/deleteDocOnFirestore";
import { loadCollectionFromFirestore } from "../../../../firebase/crudFirestoreActions/loadCollectionFromFirestor";
import { saveDocOnFirestore } from "../../../../firebase/crudFirestoreActions/saveDocOnFirestore";
import { types } from "../../../../types/types";

export const startLoadCuotas = () => {
	return async (dispatch) => {
		const cuotas = await loadCollectionFromFirestore("cuotas");
		dispatch(setCuotas(cuotas));
	};
};

export const setCuotas = (cuotas) => ({
	type: types.setCuotas,
	payload: cuotas
});

export const selectCuota = (cuota) => ({
	type: types.selectCuota,
	payload: cuota
});

export const addCuota = () => ({
	type: types.addCuota
});

export const closeCuotaForm = () => ({
	type: types.closeCuotaForm
});

export const startSaveCuota = (cuota) => {
	return async (dispatch) => {
		const i = cuota.inicio.split("-");
		const f = cuota.fin.split("-");

		const vigencia = `${i[2]}/${i[1]}/${i[0]} - ${f[2]}/${f[1]}/${f[0]}`;
		const isDocSave = await saveDocOnFirestore("cuotas", { ...cuota, vigencia }, cuota.id);
		if (isDocSave) dispatch(saveCuota({ ...cuota, vigencia }));
	};
};

export const saveCuota = (cuota) => ({
	type: types.saveCuota,
	payload: cuota
});

export const startDeleteCuota = (cuotaId) => {
	return async (dispatch) => {
		const isDeleted = await deleteDocOnFirestore("cuotas", cuotaId);
		if (isDeleted) {
			dispatch(deleteCuota(cuotaId));
		}
	};
};

export const deleteCuota = (cuotaId) => ({
	type: types.deleteCuota,
	payload: cuotaId
});
