import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../firebaseConfig";

export const saveFilesOnFirebase = async (ownerId, fileList, prevList, pad) => {
	try {
		const files = [];
		const filesRefs = [];

		for (let i = 0; i < fileList.length; i++) {
			const duplicateFile = prevList.find(
				(prevFile) =>
					prevFile.name === `${ownerId}-${fileList.item(i).name.replace(/[^a-zA-Z0-9.]/g, "")}`
			);

			if (duplicateFile) {
				console.log("ya existe: ", duplicateFile.name);
			} else {
				files.push(fileList.item(i));
			}
		}

		const snapshoPromices = files.map((file) => {
			const storageRef = ref(
				storage,
				`${pad}/${ownerId}-${file.name.replace(/[^a-zA-Z0-9.]/g, "")}`
			);
			filesRefs.push({ name: `${ownerId}-${file.name.replace(/[^a-zA-Z0-9.]/g, "")}` });
			const snapshot = uploadBytes(storageRef, file);
			return snapshot;
		});

		const resolveSnapshots = await Promise.all(snapshoPromices);
		const urlPromices = resolveSnapshots.map((snapshot) => {
			const url = getDownloadURL(snapshot.ref);
			return url;
		});

		const resolveUrls = await Promise.all(urlPromices);
		filesRefs.forEach((file) => {
			file.url = resolveUrls.find((url) => url.includes(file.name));
		});

		return filesRefs;
	} catch (error) {
		console.log("Error code: ", error.code);
		console.log("Error message: ", error.message);
		return false;
	}
};
