import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { startSetAsignacionData } from "../../../../actions/dashboard/sections/operacion/asignacionActions";
import { startSetDistribucionData } from "../../../../actions/dashboard/sections/operacion/distribucionActions";
import { startLoadEntidades } from "../../../../actions/dashboard/sections/settings/entidadesActions";
import { startLoadOfertas } from "../../../../actions/dashboard/sections/transacciones/ofertasActions";
import { startLoadTransferencias } from "../../../../actions/dashboard/sections/transacciones/transferenciasActions";
import { startLoadTraspasos } from "../../../../actions/dashboard/sections/transacciones/traspasosActions";
import { startLoadCompras } from "../../../../actions/dashboard/sections/transacciones/comprasActions";
import { getFormattedNumberString, roundToN } from "../../../../helpers/mathHelpers";
import { OfertasChart } from "./OfertasChart";
import { OperacionChart } from "./OperacionChart";
import { TransferenciasChart } from "./TransferenciasChart";
import { ComprasChart } from "./ComprasChart";
import { AvailableWaterCard } from "../../ui/AvailableWaterCard";

export const HomeSection = () => {
	const { currentCycle } = useSelector((state) => state.dashboard);
	const { data: asignacionData } = useSelector((state) => state.asignacion);
	const { ofertas, totalOfertas } = useSelector((state) => state.ofertas);
	const { compras, totalCompras } = useSelector((state) => state.compras);
	const { volumenBalances } = useSelector((state) => state.transferencias);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(startLoadEntidades());
		dispatch(startSetAsignacionData(currentCycle));
		dispatch(startSetDistribucionData(currentCycle));
		dispatch(startLoadOfertas(currentCycle));
		dispatch(startLoadCompras(currentCycle));
		dispatch(startLoadTraspasos(currentCycle));
		dispatch(startLoadTransferencias(currentCycle));
	}, [dispatch, currentCycle]);

	return (
		<div className="homeSection">
			<h2>Inicio</h2>

			<AvailableWaterCard />

			<div className="homeSection__grid">
				<div className="homeSection__section homeSection__operacion">
					<div className="homeSection__title">Operación (Mm3)</div>
					{asignacionData.length > 0 && <OperacionChart />}
				</div>

				<div className="homeSection__section homeSection__ofertas">
					<div className="homeSection__title">
						Volumen Ofertado: {getFormattedNumberString(roundToN(totalOfertas, 3))} Mm3
					</div>
					{ofertas.length > 0 && <OfertasChart />}
				</div>

				<div className="homeSection__section homeSection__compras">
					<div className="homeSection__title">
						Compras: {getFormattedNumberString(roundToN(totalCompras, 3))} Mm3
					</div>
					{compras.length > 0 && <ComprasChart />}
				</div>

				<div className="homeSection__section homeSection__transferencias">
					<div className="homeSection__title">Balances de Transferencias (Mm3)</div>
					{volumenBalances.length > 0 && <TransferenciasChart />}
				</div>
			</div>
		</div>
	);
};
