import { types } from "../../../../types/types";

const initialState = {
	subSections: ["Asignación", "Distribución"],
	activeSubSection: "Asignación"
};

export const operacionReducer = (state = { ...initialState }, action) => {
	switch (action.type) {
		case types.setOperacionActiveSubSection:
			return { ...state, activeSubSection: action.payload };

		default:
			return state;
	}
};
