import React from "react";
import { useSelector } from "react-redux";
import { Report } from "../../ui/Report";
import { getTotalRow } from "../../../../helpers/tableHelpers";
import { OperacionChart } from "../home/OperacionChart";

export const AsignacionReport = () => {
	const { currentCycle } = useSelector((state) => state.dashboard);
	const { data } = useSelector((state) => state.asignacion);
	const { displayName } = useSelector((state) => state.auth);

	const items = [
		{
			type: "chart",
			chart: OperacionChart,
			maxWidth: "70rem"
		},
		{
			type: "table",
			data,
			patterns: [
				{
					id: "id",
					header: "MODULO",
					type: "text",
					width: "auto",
					textAlign: "center",
					volumen: false
				},
				{
					id: "b",
					header: "GRAVEDAD USO AGRÍCOLA",
					type: "number",
					width: "auto",
					textAlign: "end",
					volumen: true
				},
				{
					id: "c",
					header: "GRAVEDAD USO DOMÉSTICO",
					type: "number",
					width: "auto",
					textAlign: "end",
					volumen: true
				},
				{
					id: "d",
					header: "SUMA USO AGRÍCOLA Y USO DOMÉSTICO",
					type: "number",
					width: "auto",
					textAlign: "end",
					volumen: true
				},
				{
					id: "e",
					header: "POZO FEDERAL",
					type: "number",
					width: "auto",
					textAlign: "end",
					volumen: true
				},
				{
					id: "f",
					header: "TOTAL",
					type: "number",
					width: "auto",
					textAlign: "end",
					volumen: true
				},
				{
					id: "g",
					header: "GRAVEDAD USO AGRÍCOLA",
					type: "number",
					width: "auto",
					textAlign: "end",
					volumen: true
				},
				{
					id: "h",
					header: "GRAVEDAD USO DOMÉSTICO",
					type: "number",
					width: "auto",
					textAlign: "end",
					volumen: true
				},
				{
					id: "i",
					header: "SUMA USO AGRÍCOLA Y USO DOMÉSTICO",
					type: "number",
					width: "auto",
					textAlign: "end",
					volumen: true
				},
				{
					id: "j",
					header: "POZO FEDERAL",
					type: "number",
					width: "auto",
					textAlign: "end",
					volumen: true
				},
				{
					id: "k",
					header: "TOTAL",
					type: "number",
					width: "auto",
					textAlign: "end",
					volumen: true
				},
				{
					id: "l",
					header: "AGOSTO",
					type: "number",
					width: "auto",
					textAlign: "end",
					volumen: true
				},
				{
					id: "m",
					header: "USO DOMESTICO",
					type: "number",
					width: "auto",
					textAlign: "end",
					volumen: true
				},
				{
					id: "n",
					header: "SEPTIEMBRE",
					type: "number",
					width: "auto",
					textAlign: "end",
					volumen: true
				},
				{
					id: "o",
					header: "USO DOMESTICO",
					type: "number",
					width: "auto",
					textAlign: "end",
					volumen: true
				},
				{
					id: "p",
					header: "TOTAL ENERO SEPTIEMBRE",
					type: "number",
					width: "auto",
					textAlign: "end",
					volumen: true
				},
				{
					id: "q",
					header: "GRAVEDAD USO AGRÍCOLA",
					type: "number",
					width: "auto",
					textAlign: "end",
					volumen: true
				},
				{
					id: "r",
					header: "USO DOMESTICO",
					type: "number",
					width: "auto",
					textAlign: "end",
					volumen: true
				},
				{
					id: "s",
					header: "SUMA USO AGRÍCOLA Y DOMESTICO",
					type: "number",
					width: "auto",
					textAlign: "end",
					volumen: true
				},
				{
					id: "t",
					header: "POZO FEDERAL",
					type: "number",
					width: "auto",
					textAlign: "end",
					volumen: true
				},

				{
					id: "total",
					header: "TOTAL",
					type: "number",
					width: "auto",
					textAlign: "end",
					volumen: true
				}
			]
		}
	];

	const downloadable = {
		data: getDownloadableTable(items[1].patterns, items[1].data),
		headers: items[1].patterns.map((header) => header.id),
		title: `REPORTE DE ASIGNACIÓN DE VOLUMEN`,
		author: displayName,
		sheetName: `REPORTE DE ASIGNACIÓN DE VOLUMEN`
	};

	return (
		<Report
			title={`REPORTE DE ASIGNACIÓN DE VOLUMEN CICLO ${currentCycle}`}
			items={items}
			downloadable={downloadable}
		/>
	);
};

const getDownloadableTable = (patterns, data) => {
	const table = [...data];
	table.push(getTotalRow(patterns, data));
	return table;
};
