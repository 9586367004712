import { useSelector } from "react-redux";
import { roundToN } from "../../../helpers/mathHelpers";

export const AvailableWaterCard = () => {
	const { totales: ofertas } = useSelector((state) => state.ofertas);
	const { totales: compras } = useSelector((state) => state.compras);

	return (
		<div className="availableWaterCard">
			<div className="availableWaterCard__title">Volumen Disponible (MM3)</div>

			<div className="availableWaterCard__section">
				<div className="availableWaterCard__item">
					<div className="availableWaterCard__name">Uso Agricola:</div>
					<div className="availableWaterCard__quantity">
						{roundToN(ofertas.usoAgricola - compras.usoAgricola, 3)}
					</div>
				</div>

				<div className="availableWaterCard__item">
					<div className="availableWaterCard__name">Organismo Operador:</div>
					<div className="availableWaterCard__quantity">
						{roundToN(ofertas.organismoOperador - compras.organismoOperador, 3)}
					</div>
				</div>

				<div className="availableWaterCard__item">
					<div className="availableWaterCard__name">Organismo Operador MA:</div>
					<div className="availableWaterCard__quantity">
						{roundToN(ofertas.organismoOperadorMA - compras.organismoOperadorMA, 3)}
					</div>
				</div>
			</div>
		</div>
	);
};
