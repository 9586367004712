import React from "react";
import { useSelector } from "react-redux";
import { EditableTable } from "../../ui/EditableTable";

export const TransferenciasTable = () => {
	const paterns = [
		{
			header: "MODULO",
			name: "id",
			editable: false,
			type: "text"
		},
		{
			header: "OCTUBRE",
			name: "10",
			editable: false,
			type: "number"
		},
		{
			header: "NOVIEMBRE",
			name: "11",
			editable: false,
			type: "number"
		},
		{
			header: "DICIEMBRE",
			name: "12",
			editable: false,
			type: "number"
		},
		{
			header: "ENERO",
			name: "1",
			editable: false,
			type: "number"
		},
		{
			header: "FEBRERO",
			name: "2",
			editable: false,
			type: "number"
		},
		{
			header: "MARZO",
			name: "3",
			editable: false,
			type: "number"
		},
		{
			header: "ABRIL",
			name: "4",
			editable: false,
			type: "number"
		},
		{
			header: "MAYO",
			name: "5",
			editable: false,
			type: "number"
		},
		{
			header: "JUNIO",
			name: "6",
			editable: false,
			type: "number"
		},
		{
			header: "JULIO",
			name: "7",
			editable: false,
			type: "number"
		},
		{
			header: "AGOSTO",
			name: "8",
			editable: false,
			type: "number"
		},
		{
			header: "SEPTIEMBRE",
			name: "9",
			editable: false,
			type: "number"
		},
		{
			header: "TOTAL",
			name: "total",
			editable: false,
			type: "number"
		}
	];

	const groupers = () => {
		return (
			<tr>
				<th className="editableTable__grouper"></th>
				<th colSpan="12" className="editableTable__grouper"></th>
				<th className="editableTable__grouper"></th>
			</tr>
		);
	};

	const {
		transferenciasSalientes,
		transferenciasEntrantes,
		balances,
		volumenTransferenciasSalientes,
		volumenTransferenciasEntrantes,
		volumenBalances,
		tipoTabla,
		unidad
	} = useSelector((state) => state.transferencias);

	// ["Salientes", "Entrantes", "Balace"]
	//["Mm3", "Ha"]
	const getTable = () => {
		switch (`${tipoTabla}-${unidad}`) {
			case "Salientes-Ha":
				return { title: "Transferencias Salientes (Ha)", data: transferenciasSalientes };

			case "Entrantes-Ha":
				return { title: "Transferencias Entrantes (Ha)", data: transferenciasEntrantes };

			case "Balace-Ha":
				return { title: "Balances (Ha)", data: balances };

			case "Salientes-Mm3":
				return {
					title: "Volumen Transferencias Salientes (Mm3)",
					data: volumenTransferenciasSalientes
				};

			case "Entrantes-Mm3":
				return {
					title: "Volumen Transferencias Entrantes (Mm3)",
					data: volumenTransferenciasEntrantes
				};

			case "Balace-Mm3":
				return { title: "Balances de Volumenes (Mm3)", data: volumenBalances };

			default:
				return { title: "", data: [] };
		}
	};

	const table = getTable();

	return transferenciasSalientes.length > 0 ? (
		<EditableTable
			title={table.title}
			initialData={table.data}
			dataState={table.data}
			handler={() => {}}
			paterns={paterns}
			groupers={groupers}
			handleSaveChanges={() => {}}
			showSaveButton={false}
		/>
	) : (
		<></>
	);
};
