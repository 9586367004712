import React from "react";
import { useSelector } from "react-redux";
import { ComprasChart } from "../home/ComprasChart";
import { Report } from "../../ui/Report";
import { getTotalRow } from "../../../../helpers/tableHelpers";

export const ComprasReport = () => {
	const { currentCycle } = useSelector((state) => state.dashboard);
	const { compras } = useSelector((state) => state.compras);
	const { cuotas } = useSelector((state) => state.cuotas);
	const { displayName } = useSelector((state) => state.auth);

	const items = [
		{
			type: "chart",
			chart: ComprasChart,
			maxWidth: "35rem",
		},
		{
			type: "table",
			data: prepareOfertas(compras, cuotas),
			patterns: [
				{ id: "id", header: "ID", type: "text", width: "auto", textAlign: "start", volumen: false },
				{
					id: "entidad",
					header: "ENTIDAD",
					type: "text",
					width: "auto",
					textAlign: "center",
					volumen: false,
				},
				{
					id: "fecha",
					header: "FECHA",
					type: "text",
					width: "auto",
					textAlign: "start",
					volumen: false,
				},

				{
					id: "concepto",
					header: "CONCEPTO",
					type: "text",
					width: "auto",
					textAlign: "start",
					volumen: false,
				},

				{
					id: "volumen",
					header: "VOLUMEN",
					type: "number",
					width: "auto",
					textAlign: "end",
					volumen: true,
				},

				{
					id: "cuota",
					header: "CUOTA",
					type: "text",
					width: "auto",
					textAlign: "end",
					volumen: false,
				},

				{
					id: "importe",
					header: "IMPORTE (MN)",
					type: "number",
					width: "auto",
					textAlign: "end",
					volumen: false,
				},
			],
		},
	];

	const downloadable = {
		data: getDownloadableTable(items[1].patterns, items[1].data),
		headers: items[1].patterns.map((header) => header.id),
		title: `REPORTE DE COMPRAS DE VOLUMEN`,
		author: displayName,
		sheetName: `REPORTE DE COMPRAS DE VOLUMEN`,
	};

	return (
		<Report
			title={`REPORTE DE COMPRAS DE VOLUMEN CICLO ${currentCycle}`}
			items={items}
			downloadable={downloadable}
		/>
	);
};

const prepareOfertas = (compras, cuotas) => {
	return compras.map((compra) => {
		const cuota = cuotas.find((cuota) => cuota.id === compra.cuota);
		return {
			...compra,
			cuota: cuota.costo,
			importe: compra.volumen * cuota.costo,
		};
	});
};

const getDownloadableTable = (patterns, data) => {
	const table = [...data];
	table.push(getTotalRow(patterns, data));
	return table;
};
