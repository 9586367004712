import { deleteDocOnFirestore } from "../../../../firebase/crudFirestoreActions/deleteDocOnFirestore";
import { loadCollectionFromFirestore } from "../../../../firebase/crudFirestoreActions/loadCollectionFromFirestor";
import { saveDocOnFirestore } from "../../../../firebase/crudFirestoreActions/saveDocOnFirestore";
import { types } from "../../../../types/types";

export const startLoadComisiones = () => {
	return async (dispatch) => {
		const comisiones = await loadCollectionFromFirestore("comisiones");
		dispatch(setComisiones(comisiones));
	};
};

export const setComisiones = (comisiones) => ({
	type: types.setComisiones,
	payload: comisiones
});

export const selectComision = (comision) => ({
	type: types.selectComision,
	payload: comision
});

export const addComision = () => ({
	type: types.addComision
});

export const closeComisionForm = () => ({
	type: types.closeComisionForm
});

export const startSaveComision = (comision) => {
	return async (dispatch) => {
		const i = comision.inicio.split("-");
		const f = comision.fin.split("-");

		const vigencia = `${i[2]}/${i[1]}/${i[0]} - ${f[2]}/${f[1]}/${f[0]}`;
		const isDocSave = await saveDocOnFirestore(
			"comisiones",
			{ ...comision, vigencia },
			comision.id
		);

		if (isDocSave) dispatch(saveComision({ ...comision, vigencia }));
	};
};

export const saveComision = (comision) => ({
	type: types.saveComision,
	payload: comision
});

export const startDeleteComision = (comisionId) => {
	return async (dispatch) => {
		const isDeleted = await deleteDocOnFirestore("comisiones", comisionId);
		if (isDeleted) {
			dispatch(deleteComision(comisionId));
		}
	};
};

export const deleteComision = (comisionId) => ({
	type: types.deleteComision,
	payload: comisionId
});
