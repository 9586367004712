import { deleteDocOnFirestore } from "../../../../firebase/crudFirestoreActions/deleteDocOnFirestore";
import { deleteFilesOnFirebase } from "../../../../firebase/crudFirestoreActions/deleteFilesOnFirebase";
import { deleteSingleFileOnFirebase } from "../../../../firebase/crudFirestoreActions/deleteSingleFileOnFirebase";
import { loadCollectionFromFirestore } from "../../../../firebase/crudFirestoreActions/loadCollectionFromFirestor";
import { saveDocOnFirestore } from "../../../../firebase/crudFirestoreActions/saveDocOnFirestore";
import { saveFilesOnFirebase } from "../../../../firebase/crudFirestoreActions/saveFilesOnFirebase";
import { updateDocInFirestore } from "../../../../firebase/crudFirestoreActions/updateDocInFirestore";
import { types } from "../../../../types/types";
import { uiFinishLoading, uiStartLoading } from "../../../uiActions";

export const startLoadCompras = (cycle) => {
	return async (dispatch) => {
		const compras = await loadCollectionFromFirestore(`ciclos/${cycle}/compras`);

		let totalCompras = 0;
		const totales = {
			usoAgricola: 0,
			organismoOperador: 0,
			organismoOperadorMA: 0,
		};

		compras.forEach((compra) => {
			totales.usoAgricola = totales.usoAgricola + compra.usoAgricola;
			totales.organismoOperador = totales.organismoOperador + compra.organismoOperador;
			totales.organismoOperadorMA = totales.organismoOperadorMA + compra.organismoOperadorMA;

			totalCompras += compra.volumen;
		});

		dispatch(setTotalesCompras(totales));
		dispatch(setTotalCompras(totalCompras));
		dispatch(setCompras(compras));
	};
};

export const setCompras = (compras) => ({
	type: types.setCompras,
	payload: compras,
});

export const setTotalCompras = (total) => ({
	type: types.setTotalCompras,
	payload: total,
});

export const setTotalesCompras = (totales) => ({
	type: types.setTotalesCompras,
	payload: totales,
});

export const selectCompra = (compra) => ({
	type: types.selectCompra,
	payload: compra,
});

export const addCompra = () => ({
	type: types.addCompra,
});

export const closeCompraForm = () => ({
	type: types.closeCompraForm,
});

export const startSaveCompra = (compra, cycle) => {
	return async (dispatch, getState) => {
		const state = getState();

		dispatch(uiStartLoading());

		const today = new Date();
		if (!compra.id) compra.id = today.getTime().toString().substring(5);

		const filesRefs = await saveFilesOnFirebase(
			compra.id,
			compra.filesToUpload,
			state.compras.compraSelected.filesInFirebase,
			`${cycle}/compras`
		);

		const conceptos = {
			"USO AGRICOLA": "usoAgricola",
			"ORGANISMO OPERADOR": "organismoOperador",
			"ORGANISMO OPERADOR MA": "organismoOperadorMA",
		};

		compra[conceptos[compra.concepto]] = compra.volumen;
		compra.filesInFirebase = [...compra.filesInFirebase, ...filesRefs];
		compra.filesToUpload = [];

		const isDocSave = await saveDocOnFirestore(`ciclos/${cycle}/compras`, { ...compra }, compra.id);

		const comprasAnteriores = state.compras.compras.filter(
			(compraAnterior) => compraAnterior.id !== compra.id
		);
		const totales = {
			usoAgricola: 0,
			organismoOperador: 0,
			organismoOperadorMA: 0,
		};

		comprasAnteriores.forEach((compra) => {
			totales.usoAgricola = totales.usoAgricola + compra.usoAgricola;
			totales.organismoOperador = totales.organismoOperador + compra.organismoOperador;
			totales.organismoOperadorMA = totales.organismoOperadorMA + compra.organismoOperadorMA;
		});

		totales.usoAgricola = totales.usoAgricola + compra.usoAgricola;
		totales.organismoOperador = totales.organismoOperador + compra.organismoOperador;
		totales.organismoOperadorMA = totales.organismoOperadorMA + compra.organismoOperadorMA;

		if (filesRefs && isDocSave) {
			dispatch(setTotalCompras(state.compras.totalCompras + compra.volumen));
			dispatch(saveCompra({ ...compra }));
			dispatch(setTotalesCompras(totales));
			dispatch(uiFinishLoading());
		}
	};
};

export const saveCompra = (compra) => ({
	type: types.saveCompra,
	payload: compra,
});

export const startDeleteCompra = (compra, cycle) => {
	return async (dispatch, getState) => {
		const state = getState();

		const conceptos = {
			"USO AGRICOLA": "usoAgricola",
			"ORGANISMO OPERADOR": "organismoOperador",
			"ORGANISMO OPERADOR MA": "organismoOperadorMA",
		};

		const newTotales = { ...state.compras.totales };
		newTotales[conceptos[compra.concepto]] =
			newTotales[conceptos[compra.concepto]] - compra.volumen;

		const isDeleted = await deleteDocOnFirestore(`ciclos/${cycle}/compras`, compra.id);
		const areFilesDeleted = await deleteFilesOnFirebase(`${cycle}/compras`, compra.filesInFirebase);
		if (isDeleted && areFilesDeleted) {
			dispatch(deleteCompra(compra.id));
			dispatch(setTotalesCompras(newTotales));
			dispatch(setTotalCompras(state.compras.totalCompras - compra.volumen));
		}
	};
};

export const deleteCompra = (compraId) => ({
	type: types.deleteCompra,
	payload: compraId,
});

export const startDeleteSingleCompraFile = (cycle, compra, file) => {
	return async (dispatch) => {
		const isDeleted = await deleteSingleFileOnFirebase(`${cycle}/compras`, file);

		if (isDeleted) {
			const files = compra.filesInFirebase.filter((filterFile) => filterFile.name !== file.name);
			const isUpdates = await updateDocInFirestore(`ciclos/${cycle}/compras`, compra.id, {
				filesInFirebase: files,
			});
			if (isUpdates) dispatch(deleteSingleCompraFile(compra.id, file.name));
		}
	};
};

export const deleteSingleCompraFile = (compraId, fileName) => ({
	type: types.deleteSingleCompraFile,
	payload: { compraId, fileName },
});
