import React from "react";

export const SubSectionsMenu = ({ subSections, activeSubSection, action: setSubsection }) => {
	return (
		<div className="subSectionsMenu">
			{subSections.map((subSection) => (
				<div
					key={subSection}
					onClick={() => {
						setSubsection(subSection);
					}}
					className={`subSectionsMenu__subSection ${
						subSection === activeSubSection && "subSectionsMenu__subSection--active"
					}`}
				>
					{subSection}
				</div>
			))}
		</div>
	);
};
