import React from "react";
import { useSelector } from "react-redux";
import { Report } from "../../ui/Report";
import { getTotalRow } from "../../../../helpers/tableHelpers";
import { OperacionChart } from "../home/OperacionChart";

export const DistribucionReport = () => {
	const { currentCycle } = useSelector((state) => state.dashboard);
	const { data } = useSelector((state) => state.distribucion);
	const { displayName } = useSelector((state) => state.auth);

	const items = [
		{
			type: "chart",
			chart: OperacionChart,
			maxWidth: "70rem"
		},
		{
			type: "table",
			data,
			patterns: [
				{
					id: "id",
					header: "MODULO",
					type: "text",
					width: "auto",
					textAlign: "center",
					volumen: false
				},
				{
					id: "10",
					header: "OCTUBRE",
					type: "number",
					width: "auto",
					textAlign: "end",
					volumen: true
				},
				{
					id: "11",
					header: "NOVIEMBRE",
					type: "number",
					width: "auto",
					textAlign: "end",
					volumen: true
				},
				{
					id: "12",
					header: "DICIEMBRE",
					type: "number",
					width: "auto",
					textAlign: "end",
					volumen: true
				},
				{
					id: "1",
					header: "ENERO",
					type: "number",
					width: "auto",
					textAlign: "end",
					volumen: true
				},
				{
					id: "2",
					header: "FEBRERO",
					type: "number",
					width: "auto",
					textAlign: "end",
					volumen: true
				},
				{
					id: "3",
					header: "MARZO",
					type: "number",
					width: "auto",
					textAlign: "end",
					volumen: true
				},
				{
					id: "4",
					header: "ABRIL",
					type: "number",
					width: "auto",
					textAlign: "end",
					volumen: true
				},
				{
					id: "5",
					header: "MAYO",
					type: "number",
					width: "auto",
					textAlign: "end",
					volumen: true
				},
				{
					id: "6",
					header: "JUNIO",
					type: "number",
					width: "auto",
					textAlign: "end",
					volumen: true
				},
				{
					id: "7",
					header: "JULIO",
					type: "number",
					width: "auto",
					textAlign: "end",
					volumen: true
				},
				{
					id: "8",
					header: "AGOSTO",
					type: "number",
					width: "auto",
					textAlign: "end",
					volumen: true
				},
				{
					id: "9",
					header: "SEPTIEMBRE",
					type: "number",
					width: "auto",
					textAlign: "end",
					volumen: true
				},

				{
					id: "total",
					header: "TOTAL",
					type: "number",
					width: "auto",
					textAlign: "end",
					volumen: true
				}
			]
		}
	];

	const downloadable = {
		data: getDownloadableTable(items[1].patterns, items[1].data),
		headers: items[1].patterns.map((header) => header.id),
		title: `REPORTE DE DISTRIBUCIÓN DE VOLUMEN`,
		author: displayName,
		sheetName: `REPORTE DE DISTRIBUCIÓN DE VOLUMEN`
	};

	return (
		<Report
			title={`REPORTE DE DISTRIBUCIÓN DE VOLUMEN CICLO ${currentCycle}`}
			items={items}
			downloadable={downloadable}
		/>
	);
};

const getDownloadableTable = (patterns, data) => {
	const table = [...data];
	table.push(getTotalRow(patterns, data));
	return table;
};
