import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdClose, MdSave, MdLogout, MdDelete, MdPhotoCamera, MdUpload } from "react-icons/md";

import { useFormToUpper } from "../../../../hooks/UseFormToUpper";
import { objectsDeepEqual } from "../../../../helpers/objectsDeepEqual";
import { uiRemoveError, uiSetError } from "../../../../actions/uiActions";
import {
	closeIngresoForm,
	startDeleteIngreso,
	startDeleteSingleIngresoFile,
	startSaveIngreso,
} from "../../../../actions/dashboard/sections/transacciones/ingresosActions";

export const IngresoForm = ({ ingreso }) => {
	const { edicion } = useSelector((state) => state.auth);

	const { msgError, loading } = useSelector((state) => state.ui);
	const { entidades } = useSelector((state) => state.entidades);
	const { currentCycle } = useSelector((state) => state.dashboard);
	const { isMobile } = useSelector((state) => state.ui);

	const dispatch = useDispatch();

	const [values, handleInputChange] = useFormToUpper(ingreso);
	const { entidad, fecha, importe, concepto, referencia, filesToUpload } = values;
	const thereAreChanges = !objectsDeepEqual(ingreso, values);
	// console.log({ thereAreChanges, ingreso, values });
	// const gmt = "T00:00:00";

	const handleSaveIngreso = (e) => {
		e.preventDefault();
		if (isFormValid()) {
			dispatch(startSaveIngreso(values, currentCycle));
		}
	};

	const handleDeleteIngreso = (e) => {
		e.preventDefault();
		dispatch(startDeleteIngreso(ingreso, currentCycle));
	};

	const closeForm = (e) => {
		e.preventDefault();

		dispatch(closeIngresoForm());
		dispatch(uiRemoveError());
	};

	const handleDeleteSingleFile = (ingreso, file) => {
		dispatch(startDeleteSingleIngresoFile(currentCycle, ingreso, file));
	};

	const isFormValid = () => {
		if (fecha === "") {
			dispatch(uiSetError("Indique la fecha."));
			return false;
		}

		if (entidad.trim().length === 0) {
			dispatch(uiSetError("Seleccione la entidad."));
			return false;
		}

		if (concepto.trim().length === 0) {
			dispatch(uiSetError("Indique el concepto."));
			return false;
		}

		if (importe === 0 || importe === "") {
			dispatch(uiSetError("Indique el volumen a ingresor."));
			return false;
		}

		if (importe < 0) {
			dispatch(uiSetError("El importe no puede ser negativo."));
			return false;
		}

		dispatch(uiRemoveError());
		return true;
	};

	return (
		<form className="detailForm">
			{ingreso.id.length === 0 ? (
				<div className="detailForm__title">Nuevo ingreso</div>
			) : (
				<div className="detailForm__title">Ingreso: {ingreso.id}</div>
			)}

			<div className="form">
				<div className="form__inputGroup">
					<label htmlFor="fecha" className="form__label">
						Fecha:
					</label>
					<input
						id="fecha"
						type="date"
						className="form__input"
						name="fecha"
						value={fecha}
						onChange={handleInputChange}
					/>
				</div>

				<div className="form__inputGroup">
					<label htmlFor="entidad" className="form__label">
						Entidad:
					</label>
					<select
						id="entidad"
						name="entidad"
						type="text"
						value={entidad}
						onChange={handleInputChange}
						className="form__input form__input--select"
					>
						<option value="" disabled>
							Entidad
						</option>

						{entidades.map((entidad) => (
							<option key={entidad.id} value={entidad.id}>
								{entidad.id}
							</option>
						))}
					</select>
				</div>

				<div className="form__inputGroup">
					<label htmlFor="concepto" className="form__label">
						Concepto:
					</label>
					<select
						id="concepto"
						name="concepto"
						type="text"
						value={concepto}
						onChange={handleInputChange}
						className="form__input form__input--select"
					>
						<option value="" disabled>
							Concepto
						</option>

						<option value="COMISIÓN BANCO DE AGUA">COMISIÓN BANCO DE AGUA</option>
						<option value="ABONO A SALDO">ABONO A SALDO</option>
						<option value="COMPRA DE VOLUMEN">COMPRA DE VOLUMEN</option>
					</select>
				</div>

				<div className="form__inputGroup">
					<label htmlFor="referencia" className="form__label">
						Referencia:
					</label>
					<input
						id="referencia"
						type="text"
						className="form__input"
						placeholder="Referencia"
						autoComplete="off"
						name="referencia"
						value={referencia}
						onChange={handleInputChange}
					/>
				</div>

				<div className="form__inputGroup">
					<label htmlFor="importe" className="form__label">
						Importe:
					</label>
					<input
						id="importe"
						type="number"
						className="form__input"
						placeholder="Importe"
						autoComplete="off"
						name="importe"
						value={importe}
						onChange={handleInputChange}
					/>
				</div>

				{edicion && (
					<div className="form__inputGroup">
						<label htmlFor="filesToUpload">
							<div className="form__filesInput">
								<MdUpload className="form__buttonIcon" /> Documentos
							</div>
						</label>
						<input
							id="filesToUpload"
							type="file"
							multiple="multiple"
							className="filesInput"
							name="filesToUpload"
							value={filesToUpload.files}
							onChange={handleInputChange}
						/>

						{isMobile && (
							<div>
								<label htmlFor="cameraInput">
									<div className="form__cameraInput">
										<MdPhotoCamera className="form__buttonIcon" />
									</div>
								</label>

								<input
									id="cameraInput"
									type="file"
									multiple="multiple"
									capture="camera"
									className="cameraInput"
									name="filesToUpload"
									value={filesToUpload.files}
									onChange={handleInputChange}
								/>
							</div>
						)}
					</div>
				)}

				{ingreso.filesInFirebase.map((file) => (
					<div className="fileLink" key={file.name}>
						<a
							href={file.url}
							rel="noopener noreferrer"
							target="_blank"
							className="fileLink__fileName"
						>
							{file.name}
						</a>

						{edicion && (
							<MdDelete
								className="fileLink__icon"
								onClick={() => handleDeleteSingleFile(ingreso, file)}
							/>
						)}
					</div>
				))}

				{msgError && <div className="form__error">{msgError}</div>}

				<div className="detailForm__buttonsGrid">
					{(edicion && ingreso.id.length) > 0 && (
						<button className="form__button detailForm__deleteButton" onClick={handleDeleteIngreso}>
							<MdClose className="detailForm__icon" />
							Borrar
						</button>
					)}

					<button className="form__button detailForm__closeButton" onClick={closeForm}>
						<MdLogout className="detailForm__icon detailForm__icon--mirror" />
						Cerrar
					</button>

					{edicion && (thereAreChanges || loading) && (
						<button className="form__button detailForm__saveButton" onClick={handleSaveIngreso}>
							<MdSave className="detailForm__icon" />
							Guardar {loading && <div className="form__spinner"></div>}
						</button>
					)}
				</div>
			</div>
		</form>
	);
};
