import React from "react";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { modulos } from "../../../../helpers/constants";
import { useSelector } from "react-redux";

export const TransferenciasChart = () => {
	const { volumenBalances } = useSelector((state) => state.transferencias);

	ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

	const options = {
		indexAxis: "y",
		responsive: true,
		aspectRatio: 1,
		plugins: {
			legend: {
				display: false
			}
		}
	};

	const data = {
		labels: modulos,
		datasets: [
			{
				label: "",
				data: modulos.map((moduloLabel) => {
					const moduloAsignacion = volumenBalances.find((modulo) => modulo.id === moduloLabel);
					return moduloAsignacion.total;
				}),
				backgroundColor: "rgba(45, 55, 72, 0.7)"
			}
		]
	};

	return <Bar options={options} data={data} className="transferenciasChart" />;
};
