import { firebaseLogin } from "../firebaseConfig";

export const firebaseLoginWithEmailPassword = async (email, password) => {
	try {
		const userCredential = await firebaseLogin(email, password);
		return userCredential;
	} catch (error) {
		console.log("Error code: ", error.code);
		console.log("Error message: ", error.message);
		return error;
	}
};
